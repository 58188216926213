import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import Card from '../../../utilities/card/Card'
import CardHeader from '../../../utilities/card/CardHeader'
import CardBody from '../../../utilities/card/CardBody'
import ChartLine from '../../../utilities/chart/ChartLine'
import { apiPost } from '../../../../helpers/Api'
import ChartBar from '../../../utilities/chart/ChartBar'

import map from 'lodash/map'
import ChartPie from '../../../utilities/chart/ChartPie'
import { getFullDateDisplayByTimestampSecond } from '../../../../helpers/DateHelper'

function InstalledUserCard(props) {
  const {
    className
  } = props;

  const [totalCount, setTotalCount] = useState(0);
  const [stat, setStat] = useState([])

  const [now, setNow] = useState(null)

  const getInstalledUsers = () => {
    apiPost('/admin/hkaa_community/stat/installed_users')
      .then(({data}) => {
        let s = [
          {label: `Android (${data.android})`, 'Installed Users': data.android},
          {label: `iOS (${data.ios})`, 'Installed Users': data.ios},
        ]
        setTotalCount(data.android + data.ios)
        setStat(s)

        setNow(getFullDateDisplayByTimestampSecond(new Date().getTime()/1000));
      })
  }

  useEffect(() => {
    getInstalledUsers()
  }, [])

  return (
    <div className={`${className} active-user-card`}>
      <Card>
        <CardHeader>
          <div className="title sub-title">
            Installed Users ({now}) ({totalCount})
          </div>
        </CardHeader>
        <CardBody>
          {/* <ChartBar data={stat} xKey="label" yKey="Installed Users" /> */}
          <ChartPie data={stat} nameKey="label" dataKey="Installed Users" />
        </CardBody>
      </Card>
    </div>
  )
}

InstalledUserCard.propTypes = {

}

export default InstalledUserCard

