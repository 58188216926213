import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import SelectInput from '../form/SelectInput';

function ListHeader(props) {
  const {
    headerOptions,
  } = props;

  const [keyword, setKeyword] = useState('');
  const handleChange = event => {
    let k = event.target.value;
    setKeyword(k);
  };

  const clearKeyword = () => {
    setKeyword('');
  }

  const [filter, setFilter] = useState(headerOptions.filters ? headerOptions.filters[0] : null)

  const headerChange = () => {
    if (headerOptions.onHeaderChange) {
      headerOptions.onHeaderChange(keyword, filter)
    }
  }

  useEffect(() => {
    headerChange();
  }, [keyword, filter]);

  return (
    <div className="list-header">
      {
        headerOptions.isSearch &&
        <div className="search">
          <FontAwesomeIcon fixedWidth icon="search"></FontAwesomeIcon>
          <input className="search-input" type="text" value={keyword} placeholder="Search ..." onChange={handleChange}/>
          {
            keyword.length > 0 &&
            <FontAwesomeIcon className="clickable clear" fixedWidth icon="times" onClick={clearKeyword}></FontAwesomeIcon>
          }
        </div>
      }
      {
        (headerOptions.filters && headerOptions.filters.length > 0) &&
        <div className="filter">
          <SelectInput 
            options={headerOptions.filters}
            value={filter}
            onChange={setFilter}
          />
        </div>
      }
    </div>
  )
}

ListHeader.propTypes = {

}

export default ListHeader

