import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import Card from '../../../utilities/card/Card';
import CardHeader from '../../../utilities/card/CardHeader';
import CardBody from '../../../utilities/card/CardBody';
import BasicInput from '../../../utilities/form/BasicInput';
import TextAreaInput from '../../../utilities/form/TextAreaInput';
import { toast } from '../../../utilities/Notification/CustomToast';

import each from 'lodash/each'
import trim from 'lodash/trim'
import { langTabs, defaultLang } from '../../../utilities/form/MultiLangSelector';
import MultiLangInputs from '../../../utilities/form/MultiLangInputs';
import { apiPost } from '../../../../helpers/Api';

function PhoneBookSet(props) {
  const {
    match,
    basePath,
    history
  } = props;

  const phoneBookId = match.params.id;

  let i18nDefaultObj = {};
  each(langTabs, (lang) => {
    i18nDefaultObj[lang.key] = {
      name: '',
      description: ''
    }
  })

  const defaultPhoneBookObj = {
    name: '',
    description: '',
    phone_no: '',
    _i18n: i18nDefaultObj,
    url: ''
  }

  const [phoneBook, setPhoneBook] = useState(defaultPhoneBookObj)

  const updatePhoneBookInfo = (update) => {
    setPhoneBook({
      ...phoneBook,
      ...update
    })
  }

  const getPhoneBookDetail = () => {
    let param = {
      item_id: phoneBookId
    }
    apiPost('/admin/hkaa_community/phone_book', param)
      .then((resp) => {
        let phoneBook = resp.data;

        let i18n = {};
        each(langTabs, (lang, index) => {
          i18n[lang.key] = {
            name: phoneBook.name,
            description: phoneBook.description,
          }
          if (phoneBook._i18n[lang.key]) {
            i18n[lang.key] = {
              ...i18n[lang.key],
              ...phoneBook._i18n[lang.key],
            }
          }
        })
        
        phoneBook._i18n = {...i18nDefaultObj, ...i18n}
        
        updatePhoneBookInfo(phoneBook)
      })
  }

  const savePhoneBook = () => {
    let defaultLangContent = phoneBook._i18n[defaultLang.key];

    let i18nObj = {}
    each(langTabs, (lang, index) => {
      i18nObj[lang.key] = {
        name: trim(phoneBook._i18n[lang.key].name),
        description: trim(phoneBook._i18n[lang.key].description),
      }
    })

    let thePhoneBook = {
      name: defaultLangContent.name,
      description: defaultLangContent.description,
      phone_no: trim(phoneBook.phone_no),
      _i18n: i18nObj
    }

    if (phoneBook.item_id) {
      thePhoneBook.item_id = phoneBook.item_id
    }

    let url = '/admin/hkaa_community/phone_book/set'
    apiPost(url, thePhoneBook)
      .then((resp) => {
        history.push(`${basePath}/list`)
      })
      .catch((err) => {
        console.error(err);
      })
  }

  const checkInput = () => {
    for (let i = 0; i < langTabs.length; i++) {
      let lang = langTabs[i];
      if (!phoneBook._i18n[lang.key].name) {
        toast.warn("Please enter company name for all languages");
        return false;
      }
      if (!phoneBook._i18n[lang.key].description) {
        toast.warn("Please enter description for all languages");
        return false;
      }
    }

    if (!phoneBook.phone_no) {
      toast.warn("Please enter phone number")
      return false;
    }


    return true;
  }

  const saveNow = () => {
    if (!checkInput()) {
      return;
    }
    savePhoneBook()
  }
  
  const backNow = () => {
    history.push(`${basePath}/list`)
  }

  useEffect(() => {
    if (phoneBookId) {
      getPhoneBookDetail()
    }
  }, [])

  return (
    <div className="item-set-container">
      <div className="full-width padding-bottom-30">
        <Card>
          <CardHeader>
            <div className="title">
              {phoneBook.item_id ? 'Phone Book Detail' : 'Add New'}
            </div>
            <div className="actions">
              <button className="button default" onClick={backNow}>
                <span>Back</span>
              </button>
              <button className="button primary" onClick={saveNow}>
                <span>Save</span>
              </button>
            </div>
          </CardHeader>
        </Card>
        <div className="padding-bottom-30"></div>
        <Card>
          <CardHeader>
            <div className="title sub-title">
              Company Name
            </div>
          </CardHeader>
          <CardBody>
            <MultiLangInputs
              i18n={phoneBook._i18n}
              field="name"
              type="text"
              rows={3}
              onChange={(i18n) => updatePhoneBookInfo({_i18n: i18n})}
              label="Company Name"
            />
          </CardBody>
        </Card>
        <div className="padding-bottom-30"></div>
        <Card>
          <CardHeader>
            <div className="title sub-title">
              Description
            </div>
          </CardHeader>
          <CardBody>
            <MultiLangInputs
              i18n={phoneBook._i18n}
              field="description"
              type="textarea"
              rows={3}
              onChange={(i18n) => updatePhoneBookInfo({_i18n: i18n})}
              label="Description"
            />
          </CardBody>
        </Card>

        <div className="padding-bottom-30"></div>

        <Card>
          <CardHeader>
            <div className="title sub-title">
              Phone Number
            </div>
          </CardHeader>
          <CardBody>
            <BasicInput type="text" value={phoneBook.phone_no} label="Phone Number" isHideLabel={true} onChange={(val) => updatePhoneBookInfo({phone_no: val})} />
          </CardBody>
        </Card>
{/* 
        <div className="padding-bottom-30"></div>

        <Card>
          <CardHeader>
            <div className="title sub-title">
              URL
            </div>
          </CardHeader>
          <CardBody>
            <BasicInput type="text" value={phoneBook.url} label="URL" isHideLabel={true} onChange={(val) => updatePhoneBookInfo({url: val})} />
          </CardBody>
        </Card> */}
      </div>
    </div>
  )
}

PhoneBookSet.propTypes = {

}

export default PhoneBookSet

