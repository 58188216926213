import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import Card from '../card/Card'
import CardHeader from '../card/CardHeader'
import CardBody from '../card/CardBody'

import without from 'lodash/without'
import find from 'lodash/find'
import map from 'lodash/map'
import each from 'lodash/each'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import CheckboxInput from './CheckboxInput'
import { apiPost } from '../../../helpers/Api'
import { TargetDomain } from '../../../helpers/Constants'

export const AudienceCompany = {
  audience_type: "company",
  audience_target_id: TargetDomain
}

function TargetAudienceSelector(props) {
  const {
    value,
    onChange,
  } = props;

  const [isAllUsers, setIsAllUsers] = useState(true)
  const [conditions, setConditions] = useState([])

  const checkIfConditionIsSelected = (condition) => {
    return find(conditions, {tag_id: condition.tag_id}) ? true : false;
  }

  const onConditionClick = (condition) => {
    let array = [...conditions];

    let target = find(array, {tag_id: condition.tag_id});
    if (target) {
      array = without(array, target)
      setConditions(array)
    } else {
      array.push(condition)
      setConditions(array)
    }
  }

  const updateDisplay = () => {
    setIsAllUsers(find(value, (c) => {
      return c.audience_type === "company"
    }) ? true : false)

    let allTags = []
    each(value, (c) => {
      if (c.audience_type !== "user_tag") {
        return
      }
      let target;
      each(userTags, (tags, index) => {
        let t = find(tags, {tag_id: c.audience_target_id})
        if (t) {
          target = t
        }
      })
      if (target) {
        allTags.push(target)
      }
    })
    setConditions(allTags)
  }

  const [activeGroup, setActiveGroup] = useState(null)

  const [userTags, setUserTags] = useState({})
  const getUserTags = () => {
    apiPost('/admin/user/tags')
      .then((resp) => {
        setUserTags(resp.data)
      })
  }

  useEffect(() => {
    if (isAllUsers) {
      onChange([
        AudienceCompany
      ])
      return;
    }

    let allAudiences = map(conditions, (c) => {
      return {
        audience_type: "user_tag",
        audience_target_id: c.tag_id
      }
    })
    onChange(allAudiences)

  }, [isAllUsers, conditions])

  useEffect(() => {
    updateDisplay()
  }, [value, userTags])

  useEffect(() => {
    getUserTags()
  }, [])

  return (
    <div className="target-audience-selector">
      <Card>
        <CardHeader>
          <div className="title sub-title">
            Target Audience
          </div>
        </CardHeader>
        <CardBody>
          <CheckboxInput value={isAllUsers} name="All Users" onChange={setIsAllUsers} />
        </CardBody>
        {
          !isAllUsers &&
          <div>
            <div className="selected-conditions">
              {
                conditions.length === 0 && 
                <span className="placeholder">Add Conditions</span>
              }
              {
                conditions.map((condition, index) => (
                  <div className="condition" key={index}>
                    <div className="name">{condition.tag}</div>
                    <FontAwesomeIcon className="clickable" icon="times" fixedWidth onClick={()=>onConditionClick(condition)} />
                  </div>
                ))
              }
            </div>
            <div className="condition-selector">
              <div className="selector-wrapper group-wrapper">
                {
                  Object.keys(userTags).map((key, index) => (
                    <div className={`selector-content group clickable hover-opacity ${key === activeGroup ? 'active' : ''}`} 
                      key={index} 
                      onClick={()=>setActiveGroup(key)}>
                      {key}
                    </div>
                  ))
                }
              </div>
              <div className="selector-wrapper condition-wrapper">
                {
                  userTags[activeGroup] && 
                  userTags[activeGroup].map((condition, index) => (
                    <div className={`selector-content condition clickable hover-opacity ${checkIfConditionIsSelected(condition) ? 'active' : ''}`} key={index} onClick={()=>onConditionClick(condition)}>
                      {condition.tag}
                    </div>
                  ))
                }
              </div>
            </div>
          </div>
        }
        
      </Card>
    </div>
  )
}

TargetAudienceSelector.propTypes = {

}

export default TargetAudienceSelector

