import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

import each from 'lodash/each'
import { apiPost } from '../../../../helpers/Api'
import { searchFilter } from '../../../../helpers/SearchHelper'
import Card from '../../../utilities/card/Card'
import CardHeader from '../../../utilities/card/CardHeader'
import Tab from '../../../utilities/tab/Tab'
import List from '../../../utilities/list/List'
import ToolsRenderer from '../../../utilities/list/ToolsRenderer'
import DateRenderer from '../../../utilities/list/DateRenderer'
import { postStatusTabs, postStatus } from '../../../../helpers/PostHelper'



function EventList(props) {
  const { match, basePath, history } = props;

  const [originalData, setOriginalData] = useState([]);
  const [listKeyword, setListKeyword] = useState('');
  const [listFilter, setListFilter] = useState(null);
  const [activeTab, setActiveTab] = useState(postStatusTabs[0]);

  const updateTabs = () => {
    each(postStatusTabs, (tab, index) => {
      let count = 0;
      switch (tab.name) {
        case postStatus.all:
          count = originalData.length;
          break;
        default:
          count = originalData.filter((row) => {return row.status === tab.name}).length;
          break;
      }
      tab.count = count;
    })
  }

  const [headerOptions, setHeaderOptions] = useState({
    enable: true,
    isSearch: true,
    searchableFields: ['name', 'author.name'],
    filters: [
      // {value: 'all', label: "All Categories"},
    ],
    onHeaderChange: (keyword, filter) => {
      setListKeyword(keyword);
      setListFilter(filter);
    }
  })

  const columns = [
    { 
      headerName: "Event Name", 
      field: "name"
    },
    // { 
    //   headerName: "Categories", 
    //   field: "categories"
    // },
    // { 
    //   headerName: "Status", 
    //   field: "status" 
    // },
    { 
      headerName: "From", 
      field: "start_time",
      sort: 'desc',
      cellRenderer: 'dateRenderer'
    },
    { 
      headerName: "To", 
      field: "end_time",
      cellRenderer: 'dateRenderer'
    },
    { 
      headerName: "Author", 
      field: "author.name"
    },
    // { 
    //   headerName: "Stats", 
    //   field: "stats",
    //   pinned: "right",
    //   width: 80,
    //   minWidth: 80,
    //   maxWidth: 80,
    //   sortable: false,
    //   cellRenderer: 'toolsRenderer',
    //   cellRendererParams: {
    //     onClickCallback: {
    //       stat: (row) => {
    //         viewPostStat(row)
    //       }
    //     },
    //     types: ["stat"]
    //   }
    // },
    { 
      headerName: "Tools", 
      field: "tools", 
      pinned: "right",
      width: 100,
      minWidth: 100,
      maxWidth: 100,
      sortable: false,
      cellRenderer: 'toolsRenderer',
      cellRendererParams: {
        onClickCallback: {
          edit: (row) => {
            history.push(`${basePath}/edit/${row.event_id}`);
          },
          remove: (row) => {
            deleteEvent(row);
          }
        },
        types: ["edit", "remove"]
      }
    }
  ];

  const [gridOptions, setGridOptions] = useState({
    columnDefs: columns,
    frameworkComponents: {
      toolsRenderer: ToolsRenderer,
      dateRenderer: DateRenderer
    }
  });

  const [rowData, setRowData] = useState([...originalData]);

  const search = () => {
    let filtered = [ ...originalData ];

    if (activeTab && activeTab.key !== 'all') {
      filtered = filtered.filter((data) => {
        return data.status === activeTab.name;
      })
    }

    if (listFilter && listFilter.value !== 'all') {
      filtered = filtered.filter((data) => {
        return data.categories === listFilter.label;
      })
    }

    filtered = searchFilter(filtered, headerOptions.searchableFields, listKeyword);

    setRowData(filtered);
  }

  const getEventList = () => {
    let param = {

    }
    apiPost('/admin/events', param)
      .then((resp) => {
        let events = resp.data;

        

        setOriginalData(events);
      })
  }

  const deleteEvent = (item) => {
    let param = {
      event_id: item.event_id
    }
    apiPost('/admin/events/delete', param)
      .then((resp) => {
        history.push(match.url);
      })
      .catch((err) => {
        console.error(err);
      })
  }

  useEffect(() => {
    getEventList()
  }, [])

  useEffect(() => {
    updateTabs()
    search()
  }, [originalData])

  useEffect(() => {
    search()
  }, [activeTab, listKeyword, listFilter])


  return (
    <div className="list-container">
      <div className="header">
        <Card>
          <CardHeader>
            <div className="title">Events</div>
            <div className="actions">
              <button 
                className="button default"
                onClick={() => history.push(`${basePath}/add`)}
              >
                <a>Add New</a>
              </button>
            </div>
          </CardHeader>
        </Card>
      </div>
      <div className="body">
        <List 
          headerOptions={headerOptions}
          gridOptions={gridOptions}
          rowData={rowData}
        />
      </div>
    </div>
  )
}

EventList.propTypes = {

}

const mapStateToProps = (state) => ({
  
})

const mapDispatchToProps = {
  
}

export default connect(mapStateToProps, mapDispatchToProps)(EventList)
