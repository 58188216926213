import React, { useState, useEffect } from 'react'
import { TargetDomain } from '../../../../helpers/Constants';
import { apiPost } from '../../../../helpers/Api';
import Card from '../../../utilities/card/Card';
import CardHeader from '../../../utilities/card/CardHeader';
import CardBody from '../../../utilities/card/CardBody';
import BasicInput from '../../../utilities/form/BasicInput';
import { toast } from '../../../utilities/Notification/CustomToast';
import SelectionList from '../../../utilities/list/SelectionList';

import each from 'lodash/each'
import map from 'lodash/map'
import find from 'lodash/find'
import { Tabs, Tab } from '@material-ui/core';
import CheckboxList from '../../../utilities/form/CheckboxList';

function UserGroupSet(props) {
  const { match, basePath, history } = props;

  const userGroupId = match.params.id;

  const title = !userGroupId ? "Add New" : "Group Detail";
  const [formTab, setFormTab] = useState(0);

  const [userGroup, setUserGroup] = useState({
    company_domain: TargetDomain,
    name: '',
    members: [],
    permissions: [],
    selectedUsers: [],
    defaultSelectedUsers: [],
    defaultSelectedUserIds: []
  });

  const updateUserGroupInfo = (update) => {
    setUserGroup({
      ...userGroup,
      ...update
    })
  }

  const [userList, setUserList] = useState([])
  const perPage = 100;

  const dataSource = {
    getRows: (params) => {
      // Use startRow and endRow for sending pagination to Backend
      // params.startRow : Start Page
      // params.endRow : End Page

      const page = params.endRow / perPage;

      // console.log("sorting: ", params.sortModel)
      // console.log("filter: ", params.filterModel)
      // console.log("Params: ", params)

      let param = {
        department_code: "USER",
        per_page: perPage,
        page: page,
        order_by: params.sortModel.length > 0 ? params.sortModel[0].colId : null,
        sort_order: params.sortModel.length > 0 ? params.sortModel[0].sort : null,
        search_value: params.searchValue,
        search_fields: params.searchFields
      }

      apiPost('/admin/user', param).then((resp) => {
        const data = resp.data;

        let thisPageUsers = data.users
        const totalNumberOfRows = data.total_items;
        const numberOfPages = data.num_pages
        setUserList(prevState => {
          let prevUserList = [...prevState]
          return [...prevUserList, ...thisPageUsers]
        })
        params.successCallback(thisPageUsers, totalNumberOfRows);
      })
    }
  }


  // const getUserList = () => {
  //   let param = {
  //
  //   }
  //
  //   apiPost('/admin/user', param)
  //     .then((resp) => {
  //       let users = resp.data;
  //
  //       // TODO: if needed
  //       orderBy(users, ['name'], ['asc'])
  //
  //       setUserList(users)
  //     })
  // }
  const setDefaultUserSelection = () => {
    let mapping = []
    each(userGroup.defaultSelectedUserIds, (id) => {
      let target = find(userList, {user_id: id})
      if (target) {
        target.isSelected = 1;
        mapping.push(target)
      } else {
        mapping.push({user_id: id, isSelected: 1})
      }
    })
    // orderBy(userList, ['isSelected', 'name'], ['asc', 'asc'])
    updateUserGroupInfo({defaultSelectedUsers: mapping, selectedUsers: mapping})
  }
  useEffect(() => {
    setDefaultUserSelection()
  }, [userList])

  const [modules, setModules] = useState([])
  const getCompanyModules = () => {
    apiPost('/admin/companymodule', {company_domain: TargetDomain})
      .then((resp) => {
        let data = map(resp.data, (m) => {
          m.name = m.module.name
          return m
        })

        data.sort((a, b) => {
          return a.name.toLowerCase().localeCompare(b.name.toLowerCase())
        })

        setModules(data)
      })
  }
  const setModuleSelection = () => {
    let perms = []
    each(modules, (m) => {
      let target = find(userGroup.modules, {module_key: m.module_key})
      if (!target) {
        return
      }
      if (target.permission === 2) {
        perms.push(m)
      }
    })
    updateUserGroupInfo({permissions: perms})
  }
  useEffect(() => {
    setModuleSelection()
  }, [modules])

  const getUserGroupDetail = () => {
    let param = {
      user_group_id: userGroupId
    }
    apiPost('/admin/usergroup', param)
      .then((resp) => {
        let ug = resp.data;

        ug.defaultSelectedUserIds = map(ug.user_group_members, 'user_id')

        updateUserGroupInfo(ug);

        // getUserList()
        getCompanyModules()
      })
      .catch((err) => {
        console.error(err)
      })
  }

  const checkInput = () => {
    if (!userGroup.name) {
      toast.warn("No user group name");
      return false;
    }

    return true;
  }

  const saveUserGroup = () => {
    let allModules = {}
    each(modules, (m) => {
      let target = find(userGroup.permissions, {module_key: m.module_key})
      allModules[m.module_key] = target ? 2 : 0
    })

    let theUserGroup = {
      name: userGroup.name,
      members: JSON.stringify(map(userGroup.selectedUsers, 'user_id')),
      modules: JSON.stringify(allModules)
    }

    if (userGroup.user_group_id) {
      theUserGroup.user_group_id = userGroup.user_group_id
    }

    apiPost('/admin/usergroup/set', theUserGroup)
      .then((resp) => {
        history.push(`${basePath}/usergroup/list`)
      })
      .catch((err) => {
        console.error(err);
      })
  }

  const saveNow = () => {
    if (!checkInput()) {
      return;
    }
    saveUserGroup();
  }

  const backNow = () => {
    history.push(`${basePath}/list`)
  }

  useEffect(() => {
    if (userGroupId) {
      getUserGroupDetail();
    } else {
      // getUserList()
      getCompanyModules()
    }
  }, []);

  return (
    <div className="item-set-container">
      <div className="full-width padding-bottom-30">
        <Card>
          <CardHeader>
            <div className="title">
              {title}
            </div>
            <div className="actions">
              <button className="button default" onClick={backNow}>
                <span>Back</span>
              </button>
              <button className="button primary" onClick={saveNow}>
                <span>Save</span>
              </button>
            </div>
          </CardHeader>
        </Card>
        <div className="padding-bottom-30"></div>

        <Card>
          <Tabs 
            indicatorColor="primary"
            textColor="primary"
            value={formTab} 
            onChange={(e, newVal) => setFormTab(newVal)}
          >
            <Tab label="Information" />
            <Tab label="Members" />
            <Tab label="Permissions" />
          </Tabs>
        </Card>
        <div className="padding-bottom-30"></div>

        <div hidden={formTab !== 0}>
          <Card>
            <CardHeader>
              <div className="title sub-title">
                Group Information
              </div>
            </CardHeader>
            <CardBody>
              <BasicInput type="text" value={userGroup.name} label="Group Title" onChange={(val) => updateUserGroupInfo({name: val})} />
            </CardBody>
          </Card>
        </div>

        <div hidden={formTab !== 1}>
          <Card>
            <CardHeader>
              <div className="title sub-title">
                Members
              </div>
            </CardHeader>
            <CardBody>
              <SelectionList 
                gridOptions={{
                  columnDefs: [
                    {
                      headerName: "Name",
                      field: "name",
                      sort: 'asc',
                      headerCheckboxSelection: true,
                      headerCheckboxSelectionFilteredOnly: true,
                      checkboxSelection: true
                    },
                    {
                      headerName: "Login No.",
                      field: "user_name"
                    },
                  ],
                  rowSelection: 'multiple',
                  suppressRowClickSelection: true,
                  rowModelType: 'infinite',
                  datasource: dataSource,
                  blockLoadDebounceMillis: 300,
                  onRowSelected: (e) => {
                    setUserGroup((prevUserGroup) => {
                      let updatedSelectedUsers = [...prevUserGroup.selectedUsers];
                      let updatedDefaultSelectedUserIds = [...prevUserGroup.defaultSelectedUserIds];

                      if (e.node.selected) {
                        // Add selected user to the array
                        if (!find(updatedSelectedUsers, {user_id: e.node.data.user_id})) {
                          updatedSelectedUsers.push(e.node.data);
                        }
                      } else {
                        // Remove unselected user from the array
                        updatedSelectedUsers = updatedSelectedUsers.filter(user => user.user_id !== e.node.data.user_id);
                        // Remove user ID from defaultSelectedUserIds if it exists
                        if (updatedDefaultSelectedUserIds.includes(e.node.data.user_id)) {
                          updatedDefaultSelectedUserIds = updatedDefaultSelectedUserIds.filter(
                              id => id !== e.node.data.user_id
                          );
                        }
                      }

                      return {
                        ...prevUserGroup,
                        selectedUsers: updatedSelectedUsers,
                        defaultSelectedUserIds: updatedDefaultSelectedUserIds
                      };
                    });
                  }

                }}
                rowData={userList}
                // onChange={(users) => updateUserGroupInfo({selectedUsers: users})}
                defaultSelections={userGroup.defaultSelectedUsers}
              />
            </CardBody>
          </Card>
        </div>
        
        <div hidden={formTab !== 2}>
          <Card>
            <CardHeader>
              <div className="title sub-title">
                Permissions ({userGroup.permissions.length})
              </div>
            </CardHeader>
            <CardBody>
              <CheckboxList 
                data={modules}
                selected={userGroup.permissions}
                onChange={(val) => updateUserGroupInfo({permissions: val})}
              />
            </CardBody>
          </Card>
        </div>
      </div>
{/* 
      <div className="right padding-bottom-30">
        <Card>
          <CardHeader>
            <div className="title sub-title">
              {rightTitle}
            </div>
          </CardHeader>
          <CardBody>
            <div className="right-action">
              <button className="button primary" onClick={saveNow}>
                <a>{actionTitle}</a>
              </button>
            </div>
          </CardBody>
        </Card>
        <div className="padding-bottom-30"></div>
        <Card>
          <CardHeader>
            <div className="title sub-title">
              Group Icon
            </div>
          </CardHeader>
          <CardBody>
            icon??
          </CardBody>
        </Card>
        <div className="padding-bottom-30"></div>
        <Card>
          <CardHeader>
            <div className="title sub-title">
              User Role
            </div>
          </CardHeader>
          <CardBody>
            roles
          </CardBody>
        </Card>
      </div> */}
    </div>
  )
}

UserGroupSet.propTypes = {

}

export default UserGroupSet

