import React, { } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { Switch, Route, Redirect } from 'react-router-dom'

import PostCategorySet from './set/PostCategorySet';
import PostCategoryList from './list/PostCategoryList';


function PostCategory(props) {
  const {match} = props;

  return (
    <div className="page-container">
      <Switch>
        <Route path={`${match.url}/list`}
          render={props => <PostCategoryList {...props} basePath={match.url}/>} />
        <Route path={`${match.url}/add`}
          render={props => <PostCategorySet {...props} basePath={match.url}/>} />
        <Route path={`${match.url}/edit/:id`}
          render={props => <PostCategorySet {...props} basePath={match.url}/>} />

        <Redirect to={`${match.url}/list`} />
      </Switch>
    </div>
  )
}

PostCategory.propTypes = {

}

const mapStateToProps = (state) => ({
  
})

const mapDispatchToProps = {
  
}

export default connect(mapStateToProps, mapDispatchToProps)(PostCategory)
