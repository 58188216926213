import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import { AgGridReact } from 'ag-grid-react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import find from 'lodash/find'

function SelectionList(props) {
  const { 
    gridOptions, 
    rowData,
    onChange, 
    defaultSelections
  } = props;

  const [gridApi, setGridApi] = useState(null);

  const onQuickFilterChanged = (event) => {
      if (gridOptions.datasource && gridOptions.datasource.getRows) {
          // Wrapper function to add custom params to the received dataSource
          const createCustomDataSource = (externalDataSource, searchValue, searchFields) => {
              return {
                  getRows: function(params) {
                      // Merge customParams with the params received from AG Grid
                      const mergedParams = {
                          ...params,
                          searchValue,
                          searchFields
                      };

                      // Use the external data source's getRows function
                      externalDataSource.getRows(mergedParams);
                  }
              };
          };

          if (gridApi) {
              console.log("gridApi: ", gridApi);
              const searchValue = event.target.value;
              let searchFields = []
              gridOptions.columnDefs.forEach(col => {
                  if (col.field) {
                      searchFields.push(col.field)
                  }
              });
              gridApi.setDatasource(createCustomDataSource(gridOptions.datasource, searchValue, searchFields));
          }
      }
      else {
          gridApi.setQuickFilter(event.target.value);
      }
  }

  const setDefaultSelection = () => {
    if (gridApi === null) {
      return
    }
    gridApi.forEachNode((row) => {
      if (find(defaultSelections, row.data)) {
        row.setSelected(true)
      }
    })
  }

  useEffect(() => {
    setDefaultSelection()
  }, [defaultSelections, gridApi])

  return (
    <div className="list-wrapper">
      <div className="list-header">
        <div className="search">
          <FontAwesomeIcon fixedWidth icon="search"></FontAwesomeIcon>
          <input type="text" className="search-input" onChange={onQuickFilterChanged} placeholder="Search ..."/>
        </div>
      </div>
      <div className="ag-theme-material" style={{height: 400}}>
        <AgGridReact 
          gridOptions={{
            ...gridOptions, 
            suppressCellSelection: true,
            enableCellTextSelection: true,
            suppressMovableColumns: true,
            enableSorting: true,
            paginationPageSize: 25,
            defaultColDef: {
              resizable: true,
              autoHeight: true,
            },
            minColWidth: 200
          }}
          onFirstDataRendered={(params) => {
            params.api.sizeColumnsToFit();

            setDefaultSelection()
          }}
          onGridReady={(params) => {
            setGridApi(params.api);

            params.api.sizeColumnsToFit();

            window.addEventListener('resize', (event) => {
              setTimeout(() => {
                params.api.sizeColumnsToFit();
              })
            })
          }}
          onSelectionChanged={(params) => {
            if (onChange) {
              onChange(params.api.getSelectedRows());
            }
          }}
          rowData={rowData}
          pagination={true}>
        </AgGridReact>
      </div>
    </div>
  )
}

SelectionList.propTypes = {

}

export default SelectionList

