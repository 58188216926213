import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { attachmentTypes, getFileType, getExtension, createLocalFileObject, checkIfFileIsValidSize } from '../../../helpers/FileHelper';

import union from 'lodash/union'
import pull from 'lodash/pull'
import Sortable from 'react-sortablejs';
import { TargetHost, StaticPath, MAX_FILE_SIZE } from '../../../helpers/Constants';
import { toast } from '../../utilities/Notification/CustomToast';

function FileInput(props) {
  const {
    text,
    maxCount,
    acceptTypes,
    isFileNameOnly,
    extraTips,
    localFileObjArr,
    onLocalFileObjArrChange,
  } = props;

  let validTypes = acceptTypes;
  if (!validTypes) {
    validTypes = union(attachmentTypes.imageTypes, attachmentTypes.docTypes, attachmentTypes.pdfTypes, attachmentTypes.sheetTypes)
  }

  const [theLocalFileObjArr, setTheLocalFileObjArr] = useState(localFileObjArr)

  const onFileUpload = (e) => {
    let files = e.target.files;

    let theFile = files[0];


    if (!checkIfFileIsValidSize(theFile)) {
      toast.error("Invalid file size")
      e.target.value = null;
      return;
    }

    let type = getFileType(theFile.name)
    if (validTypes.indexOf(getExtension(theFile.name)) !== -1) {
      if (type === attachmentTypes.image) {
        getImagePreview(theFile)
      } else {
        setTheLocalFileObjArr(union(theLocalFileObjArr, [createLocalFileObject({file: theFile, name: theFile.name})]));
      }
    } else {
      toast.error("Invalid file type");
    }
    e.target.value = null;
  }

  const getImagePreview = (file) => {
    let reader = new FileReader()
    reader.onload = (e) => {
      file.imagePreview = e.target.result
      setTheLocalFileObjArr(union(theLocalFileObjArr, [createLocalFileObject({file: file, name: file.name})]));
    }
    reader.readAsDataURL(file)
  }

  const checkIfExceedLimit = () => {
    return maxCount && theLocalFileObjArr.length >= maxCount
  }

  let uploadLabelClassName = ["label-text", "hover-opacity"];
  if (checkIfExceedLimit()) {
    uploadLabelClassName.push("unclickable")
  } else {
    uploadLabelClassName.push("clickable")
  }

  const removeLocalFileObj = (fileObj) => {
    let newFileObjArr = pull(theLocalFileObjArr, fileObj);
    setTheLocalFileObjArr([...newFileObjArr])
  }

  const dragReOrder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
  
    return result;
  };

  const onDragEnd = (order, sortable, event) => {
    const fileObjs = dragReOrder(theLocalFileObjArr, event.oldIndex, event.newIndex);
    setTheLocalFileObjArr([...fileObjs])
  }

  useEffect(() => {
    if (onLocalFileObjArrChange) {
      onLocalFileObjArrChange(theLocalFileObjArr)
    }
  }, [theLocalFileObjArr])

  useEffect(() => {
    setTheLocalFileObjArr(localFileObjArr)
  }, [localFileObjArr])
  
  return (
    <div className="file-input-container">
      <div className="label-row">
        <label className={uploadLabelClassName.join(' ')}>
          <FontAwesomeIcon icon="paperclip" fixedWidth />
          {text && <span>{text}</span>}
          <input type="file" name="file" hidden onChange={(e) => onFileUpload(e)} disabled={checkIfExceedLimit()} />
        </label>
      </div>
      <div className="tip">
        Format: {validTypes.join(', ')}; Maximum Size: {MAX_FILE_SIZE}MB
      </div>
      {
        extraTips &&
        <div className="tip">
          {extraTips}
        </div>
      }

      <div className="files-wrapper">
        <Sortable
          options={{
            handle: '.sort-handle'
          }}
          onChange={onDragEnd}
        >
          {
            theLocalFileObjArr.map((fileObj, index) => (
              <div className="file-row" key={index}>
                {
                  theLocalFileObjArr.length > 1 &&
                  <span className="sort-handle">
                    <FontAwesomeIcon icon="bars" fixedWidth />
                  </span>
                }
                {
                  !isFileNameOnly && getFileType(fileObj.name) === attachmentTypes.image 
                  ?
                  <div className="image-display" key={index}>
                    <div className="image-wrapper">
                      {
                        fileObj.attachmentId &&
                        <img className="image" src={`${TargetHost}${StaticPath}${fileObj.attachmentId}`} />
                      }
                      {
                        fileObj.file &&
                        <img className="image" src={fileObj.file.imagePreview} />
                      }
                    </div>
                    <div className="remove-overlay clickable" onClick={()=>removeLocalFileObj(fileObj)}>
                      <FontAwesomeIcon icon="times" />
                    </div>
                  </div>
                  :
                  <div className="file" key={index}>
                    <span className="name">{fileObj.name}</span>
                    <FontAwesomeIcon className="clickable" icon="times" fixedWidth onClick={()=>removeLocalFileObj(fileObj)} />
                  </div>
                }
              </div>
            ))
          }
        </Sortable>
      </div>
    </div>
  )
}

FileInput.propTypes = {

}

export default FileInput

