import React from 'react'
import PropTypes from 'prop-types'

function Tab(props) {
  const { tabs, activeTab } = props;

  const switchTab = (tab) => {
    props.switchActiveTab(tab);
  }

  return (
    <div className="tabs-container">
      {
        tabs.map((tab, index) => {
          if (tab.key === 'separator') {
            return (
              <div key={index} className='vertical-separator'></div>
            )
          }
          let tabClass = ["tab", "clickable"];
          if (tab === activeTab) {
            tabClass.push("active");
          }

          return (
            <div key={index} className={tabClass.join(" ")} onClick={() => switchTab(tab)}>
              <div className="name">{tab.name}</div>
              <div className="count">{tab.count}</div>
            </div>
          )
        })
      }
    </div>
  )
}

Tab.propTypes = {

}

export default Tab

