import React, { useState } from 'react'
import PropTypes from 'prop-types'
import ToolsRenderer from '../../../utilities/list/ToolsRenderer';
import DateRenderer from '../../../utilities/list/DateRenderer';
import Card from '../../../utilities/card/Card';
import CardHeader from '../../../utilities/card/CardHeader';
import List from '../../../utilities/list/List';
import { UserIdentity } from '../list/UserList';
import { Dialog } from '@material-ui/core';
import CardBody from '../../../utilities/card/CardBody';
import BasicInput from '../../../utilities/form/BasicInput';
import CheckboxInput from '../../../utilities/form/CheckboxInput';
import DateInput from '../../../utilities/form/DateInput';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { apiPost } from '../../../../helpers/Api';
import { toast } from '../../../utilities/Notification/CustomToast';

function UserVerification(props) {
  const {
    aca_identity,
    saveIdentity
  } = props;


  const gridOptions = {
    columnDefs: [
      {
        headerName: "Type",
        field: "identityTypeDisplay"
      },
      {
        headerName: "Status",
        field: "isVerifiedDisplay",
      },
      {
        headerName: "Expiry Date",
        field: "expiry_date",
        sort: "desc",
        cellRenderer: 'dateRenderer'
      },
      {
        headerName: "Name",
        field: "name",
      },
      {
        headerName: "Octopus Card No.",
        field: "octopusCardNo",
      },
      {
        headerName: "",
        field: "identity_key",
        pinned: "right",
        width: 80,
        minWidth: 80,
        maxWidth: 80,
        sortable: false,
        cellRenderer: 'toolsRenderer',
        cellRendererParams: {
          onClickCallback: {
            edit: (row) => {
              editIdentity(row)
            }
          },
          types: ['edit'],
          checkIsHidden: (row) => {
            // only 'Trial' identity can be edited
            return row.identity_type !== UserIdentity.trial;
          }
        }
      },
    ],
    frameworkComponents: {
      toolsRenderer: ToolsRenderer,
      dateRenderer: DateRenderer
    }
  }

  const [isIdentitySetModalOpen, setIsIdentitySetModalOpen] = useState(false)
  const [editingIdentity, setEditingIdentity] = useState({})
  const editIdentity = (identity) => {
    let temp = {...identity}
    temp.expiryDate = [new Date(temp.expiry_date * 1000)]
    temp.isVerified = !!temp.is_verified
    setEditingIdentity(temp)
    setIsIdentitySetModalOpen(true)
  }
  const closeIdentitySet = () => {
    setIsIdentitySetModalOpen(false)
    setEditingIdentity({})
  }
  const updateEditingIdentify = (update) => {
    setEditingIdentity({
      ...editingIdentity,
      ...update
    })
  }
  const save = () => {
    if (editingIdentity.isVerified && !editingIdentity.expiryDate[0]) {
      toast.warn("Please select expiry date")
      return false
    } 
    if (editingIdentity.identity_type === UserIdentity.octotpus && !editingIdentity.identity_key) {
      toast.warn("Please input Octopus Card No.")
      return false
    }
    saveIdentity(editingIdentity)
  }

  return (
    <div>
      <Card>
        <CardHeader>
          <div className="title sub-title">
            Verification
          </div>
        </CardHeader>
        <div style={{height: 300}}>
          <List
            headerOptions={{enable: false}}
            gridOptions={gridOptions}
            rowData={aca_identity}
          />
        </div>
      </Card>

      <Dialog
        open={isIdentitySetModalOpen}
        onClose={closeIdentitySet}
        fullWidth={true}
        maxWidth="sm"
        scroll="paper"
        disableBackdropClick
        disableEscapeKeyDown
      >
        <Card>
          <CardHeader>
            <div className="title">Edit Identity</div>
            <div className="actions">
              <button className="button default" onClick={closeIdentitySet}>
                <span>Cancel</span>
              </button>
              <button className="button primary" onClick={save}>
                <span>Save</span>
              </button>
            </div>
          </CardHeader>
          <CardBody>
            <div className="input-container">
              <span className="input-label">
                Is Verified?
              </span>
              <div className="form-input">
                <CheckboxInput
                  value={editingIdentity.isVerified}
                  onChange={(val) => updateEditingIdentify({isVerified: val})}
                />
              </div>
            </div>
            <div className="border-bottom"></div>
            {
              editingIdentity.identity_type === UserIdentity.octotpus &&
              <BasicInput 
                type="text"
                value={editingIdentity.identity_key}
                label="Octopus Card No."
                onChange={(val) => updateEditingIdentify({identity_key: val})}
              />
            }
            {
              editingIdentity.isVerified &&
              <DateInput 
                value={editingIdentity.expiryDate}
                placeholder="Expiry Date"
                isShowLabel={true}
                isInline={true}
                onChange={(date) => updateEditingIdentify({expiryDate: date})}
              />
            }
          </CardBody>
        </Card>
      </Dialog>
    </div>
  )
}

UserVerification.propTypes = {

}

export default UserVerification

