import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import { apiPost, apiUploadFile } from '../../../../helpers/Api';
import { toast } from '../../../utilities/Notification/CustomToast';
import Card from '../../../utilities/card/Card';
import CardHeader from '../../../utilities/card/CardHeader';
import { langTabs, defaultLang } from '../../../utilities/form/MultiLangSelector';
import CardBody from '../../../utilities/card/CardBody';
import FileInput from '../../../utilities/form/FileInput';
import BasicInput from '../../../utilities/form/BasicInput';

import map from 'lodash/map';
import union from 'lodash/union';
import each from 'lodash/each';
import filter from 'lodash/filter';
import difference from 'lodash/difference';
import find from 'lodash/find';
import isEmpty from 'lodash/isEmpty';
import trim from 'lodash/trim';
import DateInput from '../../../utilities/form/DateInput';
import { attachmentTypes, uploadLocalFileObjects, createLocalFileObject } from '../../../../helpers/FileHelper';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { surveyQuestionTypes, getSurveyQuestionDefaultObject, multipleChoiceType, freeTextType, ratingType } from '../Survey';
import MultipleChoiceQuestion from './question/MultipleChoiceQuestion';
import FreeTextQuestion from './question/FreeTextQuestion';
import RatingQuestion from './question/RatingQuestion';
import MultiLangInputs from '../../../utilities/form/MultiLangInputs';
import LinkagePost from '../../../utilities/form/LinkagePost';
import { Tabs, Tab, ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails } from '@material-ui/core';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import Sortable from 'react-sortablejs/lib/Sortable';
import { checkIfTimesInCorrectOrder } from '../../../../helpers/DateHelper';
import { typeSurvey } from '../../../utilities/form/LinkageSelector';
import LinkedPostList from '../../../utilities/form/LinkedPostList';

function SurveySet(props) {
  const { match, basePath, history } = props;

  let surveyId = match.params.id;


  const [formTab, setFormTab] = useState(0);

  let i18nDefaultObj = {};
  each(langTabs, (lang) => {
    i18nDefaultObj[lang.key] = {
      title: '', 
      description: '', 
      description_new: '', 
    }
  })

  const defaultSurveyObj = {
    title: '',
    description: '',
    description_new: '',
    // defaultSelectedUserGroups: [],
    // defaultSelectedUserGroupIds: [],
    // publishOn: [new Date()],
    _i18n: i18nDefaultObj,
    startTime: [new Date()],
    endTime: [null],
    questions: [],
    localMediaObjArr: [],
    uploadedMediaSrcArr: [],
    localImageObjArr: [],
    uploadedImageSrcArr: [],
  }

  const [survey, setSurvey] = useState(defaultSurveyObj);

  const updateSurveyInfo = (update) => {
    setTimeout(() => {
      setSurvey({
        ...survey,
        ...update
      })
    }, 0)
  }

  const getSurveyDetail = () => {
    let param = {
      questionnaire_id: surveyId
    }
    apiPost('/admin/questionnaire/list', param)
      .then((resp) => {
        let survey = resp.data;

        let i18n = {};
        each(langTabs, (lang, index) => {
          i18n[lang.key] = {
            title: survey.title,
            description: survey.description,
            description_new: survey.description,
          }
          if (survey._i18n[lang.key]) {
            i18n[lang.key] = {
              ...i18n[lang.key],
              ...survey._i18n[lang.key],
              ...{description_new: survey._i18n[lang.key].description}
            }
          }
        })
        
        survey._i18n = {...i18nDefaultObj, ...i18n}

        survey.startTime = [new Date(parseFloat(survey.start_date) * 1000)]
        survey.endTime = [new Date(parseFloat(survey.expiry_date) * 1000)]

        survey.localMediaObjArr = map(survey.media_attachments, (m) => {
          return createLocalFileObject({attachmentId: m.attachment_id, name: m.filename})
        })
        
        survey.localImageObjArr = map(survey.images, (i) => {
          return createLocalFileObject({attachmentId: i, name: i})
        })

        survey.questions.sort((a, b) => {
          return a.question_index - b.question_index
        })

        survey.questions = map(survey.questions, (q, index) => {
          let qLocalObj = {
            question_id: q.question_id,
            title: q.title,
            type: find(surveyQuestionTypes, (t) => {
              return t.type == q.type
            }),
            id: index
          }

          let detailObj = JSON.parse(q.detail)

          switch (qLocalObj.type) {
            case multipleChoiceType:
              qLocalObj.options = map(detailObj.options, (o) => {
                return {name: o.option}
              })
              break;
            case freeTextType:
              qLocalObj.isMandatory = detailObj.is_mandatory === 1
              break;
            case ratingType:
              qLocalObj.maxStar = detailObj.max_star
              qLocalObj.oneStarCaption = detailObj.one_star_caption
              qLocalObj.maxStarCaption = detailObj.max_star_caption
              qLocalObj.sections = map(detailObj.options, (o) => {
                return {name: o.section}
              })
              break;
          }

          return qLocalObj
        })

        setFormTab(0)
        setExpandedQues(false)

        updateSurveyInfo({
          ...defaultSurveyObj,
          ...survey
        })
      })
  }

  // Question
  const [expandedQues, setExpandedQues] = useState(false);
  const handleExpandedChange = (panel) => (event, isExpanded) => {
    setExpandedQues((isExpanded !== false) ? panel : false)
  }

  const addQuestion = (typeKey) => {
    updateSurveyInfo({
      questions: [
        ...survey.questions,
        getSurveyQuestionDefaultObject(typeKey, survey.questions.length)
      ]
    })
  }
  const updateQuestion = (index, update) => {
    survey.questions[index] = {
      ...survey.questions[index],
      ...update
    }
    updateSurveyInfo({questions: survey.questions})
  }

  const removeQuestion = (index) => {
    const ques = survey.questions;
    ques.splice(index, 1);
    updateSurveyInfo({questions: ques});
  }

  const dragReOrder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
  
    return result;
  };

  const onDragEnd = (order, sortable, event) => {
    const ques = dragReOrder(survey.questions, event.oldIndex, event.newIndex);
    updateSurveyInfo({questions: ques});
  }


  const checkInput = () => {
    for (let i = 0; i < langTabs.length; i++) {
      let lang = langTabs[i];
      if (!survey._i18n[lang.key].title) {
        toast.warn("Please enter survey name for all languages");
        return false;
      }
    }

    if (!survey.startTime[0]) {
      toast.warn("Please select survey start time");
      return false;
    }

    if (!survey.endTime[0]) {
      toast.warn("Please select survey end time");
      return false;
    }
    
    if (!checkIfTimesInCorrectOrder(survey.startTime[0], survey.endTime[0])) {
      toast.warn("End time must be after start time")
      return false
    }

    if (survey.questions.length === 0) {
      toast.warn("Please enter at least one question")
      return false
    }

    for (let i = 0; i < survey.questions.length; i++) {
      let q = survey.questions[i];
      if (!q.title) {
        toast.warn("Please enter all questions' name");
        return false;
      }
      switch (q.type.key) {
        case multipleChoiceType.key:
          if (q.options.length === 0) {
            toast.warn(`Please add options for question #${i+1}`);
            return false;
          }
          for (let o = 0; o < q.options.length; o++) {
            if (!q.options[o].name) {
              toast.warn(`Please enter all options for question #${i+1}`)
              return false;
            }
          }
          break;
        case freeTextType.key:
          break;
        case ratingType.key:
          if (!q.maxStar) {
            toast.warn(`Please enter Max # of Stars for question #${i+1}`);
            return false;
          }
          if (!q.oneStarCaption) {
            toast.warn(`Please enter 1 Star Caption for question #${i+1}`);
            return false;
          }
          if (!q.maxStarCaption) {
            toast.warn(`Please enter Max Star Caption for question #${i+1}`);
            return false;
          }
          if (q.sections.length === 0) {
            toast.warn(`Please add sections for question #${i+1}`);
            return false;
          }
          for (let o = 0; o < q.sections.length; o++) {
            if (!q.sections[o].name) {
              toast.warn(`Please enter all sections for question #${i+1}`)
              return false;
            }
          }
          break;
      }
    }

    return true;
  }

  const uploadPic = (index, callback) => {
    uploadLocalFileObjects({
      arr: survey.localImageObjArr,
      index: index,
      uploadedIds: [],
      callbackWithIds: (ids) => {
        survey.uploadedImageSrcArr = ids
        callback()
      }
    })
  }

  const uploadFile = (index, callback) => {
    uploadLocalFileObjects({
      arr: survey.localMediaObjArr,
      index: index,
      uploadedIds: [],
      callbackWithIds: (ids) => {
        survey.uploadedMediaSrcArr = ids
        callback()
      }
    })
  }

  const saveSurvey = () => {
    let startTime = survey.startTime[0].getTime() / 1000;
    let endTime = survey.endTime[0].getTime() / 1000;

    let defaultLangContent = survey._i18n[defaultLang.key];

    let i18nObj = {}
    each(langTabs, (lang, index) => {
      i18nObj[lang.key] = {
        title: trim(survey._i18n[lang.key].title),
        description: trim(survey._i18n[lang.key].description_new),
      }
    })

    let allMedia = survey.uploadedMediaSrcArr;
    let allImages = survey.uploadedImageSrcArr;

    let allQuestions = map(survey.questions, (q, index) => {
      let qObj = {
        title: trim(q.title),
        question_index: index + 1,
        type: q.type.type
      }
      let detail;
      switch (q.type.key) {
        case multipleChoiceType.key:
          let options = map(q.options, (o) => {
            return {option: trim(o.name)}
          })
          detail = {
            options: options
          }
          break;
        case freeTextType.key:
          detail = {
            is_mandatory: q.isMandatory ? 1 : 0
          }
          break;
        case ratingType.key:
          detail = {
            max_star: q.maxStar,
            one_star_caption: trim(q.oneStarCaption),
            max_star_caption: trim(q.maxStarCaption),
            options: map(q.sections, (s) => {
              return {section: trim(s.name)}
            })
          }
          break;
      }
      qObj.detail = JSON.stringify(detail);
      return qObj;
    })

    let theQuestionnaire = {
      title: defaultLangContent.title,
      description: defaultLangContent.description_new,
      _i18n: i18nObj,
      start_date: startTime,
      expiry_date: endTime,
      media: allMedia,
      images: allImages,
      questions: allQuestions,
      revisable: 0
    }

    let url = '/admin/questionnaire/set'
    if (survey.questionnaire_id) {
      theQuestionnaire.questionnaire_id = survey.questionnaire_id;
    }


    apiPost(url, theQuestionnaire)
      .then((resp) => {
        surveyId = resp.data.questionnaire_id;
        getSurveyDetail();
      })
  }

  const saveNow = () => {
    if (!checkInput()) {
      return;
    }
    uploadFile(0, () => uploadPic(0, () => saveSurvey()))
  }

  const backNow = () => {
    history.push(`${basePath}/list`)
  }

  const createPostCallback = () => {
    getSurveyDetail()
  }

  useEffect(() => {
    if (surveyId) {
      getSurveyDetail()
    }
  }, [])

  return (
    <div className="item-set-container">
      <div className="full-width padding-bottom-30">
        <Card>
          <CardHeader>
            <div className="title">
              {survey.questionnaire_id ? 'Survey Detail' : 'Add New'}
            </div>
            <div className="actions">
              {
                survey.questionnaire_id &&
                <LinkagePost 
                  targetType={typeSurvey.value}
                  targetId={survey.questionnaire_id}
                  createPostCallback={createPostCallback}
                />
              }
              <button className="button default" onClick={backNow}>
                <span>Back</span>
              </button>
              <button className="button primary" onClick={saveNow}>
                <span>Save</span>
              </button>
            </div>
          </CardHeader>
        </Card>
        <div className="padding-bottom-30"></div>

        <Card>
          <Tabs 
            indicatorColor="primary"
            textColor="primary"
            value={formTab} 
            onChange={(e, newVal) => setFormTab(newVal)}
          >
            <Tab label="Information" />
            <Tab label="Questions" />
            {
              survey.questionnaire_id &&
              <Tab label="Linkage" />
            }
          </Tabs>
        </Card>
        <div className="padding-bottom-30"></div>
      

        <div hidden={formTab !== 0}>
          {
            (survey.author && survey.author.name) &&
            <>
              <Card>
                <CardBody>
                  <BasicInput type="text" value={survey.author.name} label="Author" isDisabled noBorder />
                </CardBody>
              </Card>
              <div className="padding-bottom-30"></div>
            </>
          }
          <Card>
            <CardHeader>
              <div className="title sub-title">
                Survey Name
              </div>
            </CardHeader>
            <CardBody>
              <MultiLangInputs
                i18n={survey._i18n}
                field="title"
                type="textarea"
                rows="4"
                onChange={(i18n) => updateSurveyInfo({_i18n: i18n})}
                label="Survey Name"
              />
            </CardBody>
          </Card>
          {/* <div className="padding-bottom-30"></div>
          <Card>
            <CardHeader>
              <div className="title sub-title">
                Description
              </div>
            </CardHeader>
            <CardBody>
              <MultiLangInputs
                i18n={survey._i18n}
                field="description"
                newField="description_new"
                type="html"
                onChange={(i18n) => updateSurveyInfo({_i18n: i18n})}
                label="Description"
              />
            </CardBody>
          </Card> */}
          {/* <div className="padding-bottom-30"></div>
          <Card>
            <CardHeader>
              <div className="title sub-title">
                Attachments
              </div>
            </CardHeader>
            <CardBody>
              <FileInput   
                localFileObjArr={survey.localMediaObjArr}
                onLocalFileObjArrChange={(arr) => updateSurveyInfo({localMediaObjArr: arr})}
                maxCount={5}
                acceptTypes={attachmentTypes.pdfTypes}
                text="Upload"
                isFileNameOnly={true}
              />
            </CardBody>
          </Card> */}

          {/* <div className="padding-bottom-30"></div>
          <Card>
            <CardHeader>
              <div className="title sub-title">
                Survey Poster (Max. 1)
              </div>
            </CardHeader>
            <CardBody>
              <FileInput   
                localFileObjArr={survey.localImageObjArr}
                onLocalFileObjArrChange={(arr) => updateSurveyInfo({localImageObjArr: arr})}
                maxCount={1}
                acceptTypes={attachmentTypes.imageTypes}
                text="Upload Image"
                isFileNameOnly={false}
                extraTips="Recommended ratio: 3:2; Recommended dimension: 1200x800 px"
              />
            </CardBody>
          </Card> */}
          <div className="padding-bottom-30"></div>
          <Card>
            <CardHeader>
              <div className="title sub-title">
                Survey Information
              </div>
            </CardHeader>
            <CardBody>
              <DateInput 
                value={survey.startTime}
                placeholder="Start Time"
                onChange={(date) => updateSurveyInfo({startTime: date})}
                isShowLabel={true}

              />
              <div className="border-bottom"></div>
              <DateInput 
                value={survey.endTime}
                placeholder="End Time"
                onChange={(date) => updateSurveyInfo({endTime: date})}
                isShowLabel={true}
              />
            </CardBody>
          </Card>
        </div>
        
        <div hidden={formTab !== 1}>
          <div className="questions-tab">
            <div className="questions-left">
              <Sortable
                options={{
                  handle: '.sort-handle'
                }}
                onChange={onDragEnd}
              >
                {
                  survey.questions.map((question, index) => (
                    <ExpansionPanel
                      key={index}
                      className="question-panel"
                      square
                      expanded={expandedQues === question.id}
                      onChange={handleExpandedChange(question.id)}
                    >
                      <ExpansionPanelSummary>
                        <div className="header">
                          <div className="title sub-title">
                            <span className="sort-handle">
                              <FontAwesomeIcon icon="bars" fixedWidth />
                            </span>
                            #{index+1}: {question.title} ({question.type.name})
                          </div>
                          <div className="question-control">
                            <FontAwesomeIcon className="clickable hover-opacity" icon="times" fixedWidth onClick={() => removeQuestion(index)} />
                          </div>
                        </div>
                      </ExpansionPanelSummary>

                      <ExpansionPanelDetails>
                        <div className="body">
                          <BasicInput 
                            type="text" 
                            value={question.title} 
                            label="Title" 
                            onChange={(val) => {
                              updateQuestion(index, {
                                title: val
                              })
                            }} 
                          />
                          {
                            question.type === multipleChoiceType &&
                            <MultipleChoiceQuestion 
                              question={question} 
                              onChange={(val) => {
                                updateQuestion(index, val)
                              }}
                            />
                          }
                          {
                            question.type === freeTextType &&
                            <FreeTextQuestion 
                              question={question} 
                              onChange={(val) => {
                                updateQuestion(index, val)
                              }}
                            />
                          }
                          {
                            question.type === ratingType &&
                            <RatingQuestion 
                              question={question} 
                              onChange={(val) => {
                                updateQuestion(index, val)
                              }}
                            />
                          }
                        </div>
                      </ExpansionPanelDetails>
                    </ExpansionPanel>
                  ))
                }
              </Sortable>
            </div>

            <div className="questions-right">
              <Card className="sticky-top">
                <CardHeader>
                  <div className="title sub-title">
                    Question Type
                  </div>
                </CardHeader>
                <div className="question-selector">
                  {
                    surveyQuestionTypes.map((type, index) => (
                      <div className="question-type hover-opacity clickable" 
                        onClick={() => addQuestion(type.key)}
                        key={type.key}>
                        <div className="type">{type.name}</div>
                        <FontAwesomeIcon icon="plus" fixedWidth />
                      </div>
                    ))
                  }
                </div>
              </Card>
            </div>
          </div>
        </div>

        <div hidden={formTab !== 2}>
          <LinkedPostList 
            posts={survey.linked_posts || []}
            history={history}
          />
        </div>

      </div>

    </div>
  )
}

SurveySet.propTypes = {

}

export default SurveySet

