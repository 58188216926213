import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import ToolsRenderer from '../../../utilities/list/ToolsRenderer';
import { searchFilter } from '../../../../helpers/SearchHelper';
import Card from '../../../utilities/card/Card';
import CardHeader from '../../../utilities/card/CardHeader';
import List from '../../../utilities/list/List';
import { apiExportFile, apiPost } from '../../../../helpers/Api';
import { getExportFileNameDate } from '../../../../helpers/DateHelper';
import each from 'lodash/each';
import map from 'lodash/map';
import { langCht } from '../../../utilities/form/MultiLangSelector';

function CompanyList(props) {
  const { 
    match, 
    basePath,
    history
  } = props;
  
  const [originalData, setOriginalData] = useState([]);
  const [listKeyword, setListKeyword] = useState('');
  const [listFilter, setListFilter] = useState(null);

  const [headerOptions, setHeaderOptions] = useState({
    enable: true,
    isSearch: true,
    searchableFields: ['tag', 'tag_tc'],
    filters: [

    ],
    onHeaderChange: (keyword, filter) => {
      setListKeyword(keyword);
      setListFilter(filter);
    }
  })

  const columns = [
    {
      headerName: "Company Name (EN)",
      field: "tag",
      sort: 'asc',
    },
    {
      headerName: "Company Name (TC)",
      field: "tag_tc",
    },
    {
      headerName: "Tools",
      field: "category_id",
      pinned: "right",
      width: 100,
      minWidth: 100,
      maxWidth: 100,
      sortable: false,
      cellRenderer: 'toolsRenderer',
      cellRendererParams: {
        onClickCallback: {
          edit: (row) => {
            editCompany(row);
          },
          remove: (row) => {
            deleteCompany(row);
          }
        },
        types: ["edit", "remove"]
      }
    },
  ]

  const [gridOptions, setGridOptions] = useState({
    columnDefs: columns,
    frameworkComponents: {
      toolsRenderer: ToolsRenderer,
    }
  });

  const [rowData, setRowData] = useState([...originalData]);

  const search = () => {
    let filtered = originalData;


    if (listKeyword) {
      filtered = searchFilter(filtered, headerOptions.searchableFields, listKeyword);
    }

    setRowData(filtered);
  }

  const getCompanyList = () => {
    let param = {

    }
    apiPost('/admin/hkaa_community/account_tags/company', param)
      .then((resp) => {
        let company = resp.data;

        let parsed = [];

        map(company, (c) => {
          if (c.tag_id !== "ffffffff-ffff-ffff-ffff-fffffffffff0") {
            c.tag_tc = c._i18n[langCht.key] ? c._i18n[langCht.key].tag : c.tag
            parsed.push(c)
          }
        })

        setOriginalData(parsed);
      })
  }
  
  const deleteCompany = (company) => {
    let param = {
      tag_id: company.tag_id
    }
    apiPost('/admin/hkaa_community/account_tags/company/delete', param)
      .then((resp) => {
        history.push(match.url);
      })
      .catch((err) => {
        console.error(err);
      })
  }

  const editCompany = (company) => {
    history.push(`${basePath}/edit/${company.tag_id}`);
  }


  useEffect(() => {
    getCompanyList();
  }, []);

  useEffect(() => {
    search();
  }, [originalData]);

  useEffect(() => {
    search()
  }, [listKeyword, listFilter]);

  return (
    <div className="list-container">
      <div className="header">
        <Card>
          <CardHeader>
            <div className="title">Company</div>
            <div className="actions">
              {/* <button className="button info" onClick={exportCompany}>
                <a>Export</a>
              </button> */}
              <button 
                className="button default"
                onClick={() => history.push(`${basePath}/add`)}
              >
                <a>Add New</a>
              </button>
            </div>
          </CardHeader>
        </Card>
      </div>
      <div className="body">
        <List 
          headerOptions={headerOptions}
          gridOptions={gridOptions}
          rowData={rowData}
        />
      </div>
    </div>
  )
}

CompanyList.propTypes = {

}

export default CompanyList

