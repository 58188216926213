import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import Card from '../../../utilities/card/Card'
import CardHeader from '../../../utilities/card/CardHeader'
import CardBody from '../../../utilities/card/CardBody'
import ChartLine from '../../../utilities/chart/ChartLine'

import subHours from 'date-fns/subHours'
import { apiPost } from '../../../../helpers/Api'
import map from 'lodash/map'
import { getDateByFormat } from '../../../../helpers/DateHelper'

const numOfHours = 24

function OnlineUserCard(props) {
  const {
    className
  } = props;

  const [stat, setStat] = useState([])

  const getOnlineUsers = () => {
    let now = new Date().setSeconds(0, 0);
    let hoursAgo = subHours(new Date(), numOfHours).setMinutes(0, 0, 0)

    let param = {
      timestamp: [
        hoursAgo / 1000,
        now / 1000
      ],
      interval: 3600
    }
    apiPost('/admin/hkaa_community/stat/online_users', param)
      .then(({data}) => {
        let s = map(data, (count, timestamp) => {
          return {
            time: getDateByFormat(timestamp, 'HH:mm'),
            'Online Users': count
          }
        })

        setStat(s)
      })
  }

  useEffect(() => {
    getOnlineUsers()
  }, [])

  return (
    <div className={`${className} online-user-card`}>
      <Card>
        <CardHeader>
          <div className="title sub-title">
            Online Users (Past 24 hours)
          </div>
        </CardHeader>
        <CardBody>
          <ChartLine data={stat} xKey="time" yKey="Online Users" />
        </CardBody>
      </Card>
    </div>
  )
}

OnlineUserCard.propTypes = {

}

export default OnlineUserCard

