import React from 'react'
import PropTypes from 'prop-types'
import { Switch, Route, Redirect } from 'react-router-dom'
import SurveyList from './list/SurveyList';
import SurveySet from './set/SurveySet';

import "./Survey.scss";

export const multipleChoiceType = {key: "multiple_choice", name: "Multiple Choice", type: 1}
export const freeTextType = {key: "free_text", name: "Free Text", type: 2}
export const ratingType = {key: "rating", name: "Rating", type: 4}

export const surveyQuestionTypes = [
  multipleChoiceType,
  freeTextType,
  ratingType,
]

export const defaultMultipleChoiceObj = {
  type: multipleChoiceType,
  title: '',
  options: []
}

export const defaultFreeTextObj = {
  type: freeTextType,
  title: '',
  isMandatory: false
}

export const defaultRatingObj = {
  type: ratingType,
  title: '',
  maxStar: 5,
  oneStarCaption: '',
  maxStarCaption: '',
  sections: []
}

export const getSurveyQuestionDefaultObject = (type, id) => {
  let ques;
  switch (type) {
    case multipleChoiceType.key:
      ques = defaultMultipleChoiceObj;
      break;
    case freeTextType.key:
      ques = defaultFreeTextObj;
      break;
    case ratingType.key:
      ques = defaultRatingObj;
      break;
  }
  return {
    ...{id: id},
    ...ques
  }
}

function Survey(props) {
  const { match } = props;

  return (
    <div className="page-container">
      <Switch>
        <Route path={`${match.url}/list`}
          render={props => <SurveyList {...props} basePath={match.url} />}
        />
        <Route path={`${match.url}/add`}
          render={props => <SurveySet {...props} basePath={match.url} />}
        />
        <Route path={`${match.url}/edit/:id`}
          render={props => <SurveySet {...props} basePath={match.url} />}
        />
        <Redirect to={`${match.url}/list`} />
      </Switch>
    </div>
  )
}

Survey.propTypes = {

}

export default Survey

