import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'


import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';

function ConfirmModal(props) {
  const {
    open,
    confirmCallback,
    closeCallback,
    title,
    message,
    isHideCancel
  } = props;

  return (
    <Dialog 
      open={open} 
      onClose={closeCallback} 
      disableBackdropClick 
      disableEscapeKeyDown
    >
      {
        title &&
        <DialogTitle>
          {title}
        </DialogTitle>
      }
      {
        message &&
        <DialogContent>
          {message}
        </DialogContent>
      }
      <DialogActions>
        {
          (!isHideCancel) &&
          <button className="button default" onClick={closeCallback}>
            <span>Cancel</span>
          </button>
        }
        <button className="button primary" onClick={confirmCallback}>
          <span>OK</span>
        </button>
      </DialogActions>
    </Dialog>
  )
}

ConfirmModal.propTypes = {

}

const mapStateToProps = (state) => ({
  open: state.confirm.isDisplay,
  title: state.confirm.title,
  message: state.confirm.message,
  closeCallback: state.confirm.closeCallback,
  confirmCallback: state.confirm.confirmCallback,
  isHideCancel: state.confirm.isHideCancel
})

const mapDispatchToProps = {
  
}


export default connect(mapStateToProps, mapDispatchToProps)(ConfirmModal)
