import React from 'react'
import PropTypes from 'prop-types'
import { Switch, Route, Redirect } from 'react-router-dom'
import CompanyList from './CompanyList';
import CompanySet from './CompanySet';

function SettingCompany(props) {
  const { 
    match 
  } = props;

  return (
    <div className="page-container">
      <Switch>
        <Route path={`${match.url}/list`}
          render={props => <CompanyList {...props} basePath={match.url} />}
        />
        <Route path={`${match.url}/add`}
          render={props => <CompanySet {...props} basePath={match.url} />}
        />
        <Route path={`${match.url}/edit/:id`}
          render={props => <CompanySet {...props} basePath={match.url} />}
        />
        <Redirect to={`${match.url}/list`} />
      </Switch>
    </div>
  )
}

SettingCompany.propTypes = {

}

export default SettingCompany

