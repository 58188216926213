import React, { useState } from 'react'

import { connect } from 'react-redux'

import store from '../../redux/store'
import { changeLanguage } from '../../redux/actions'

import "./PortalTopNav.scss"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBell } from '@fortawesome/free-regular-svg-icons'

import Popover from '@material-ui/core/Popover';

import { faAngleDown } from '@fortawesome/free-solid-svg-icons'
import { Button } from '@material-ui/core'
import Dialog from '@material-ui/core/Dialog';
import { langTabs } from '../utilities/form/MultiLangSelector'
import Card from '../utilities/card/Card'
import CardHeader from '../utilities/card/CardHeader'
import { CRONJOB_STATUS } from '../../helpers/CronJobHelper'
import { apiExportFile } from '../../helpers/Api'
import { logoutConfirm } from '../../helpers/AccountHelper'

function PortalTopNav(props) {
  const { user, cronjobs } = props;


  const onLogout = (e) => {
    e.preventDefault();
    logoutConfirm();
  }

  const [userMenuAnchorEl, setUserMenuAnchorEl] = useState(null);
  const handleUserMenuClick = event => {
    setUserMenuAnchorEl(event.currentTarget);
  };

  const handleUserMenuClose = () => {
    setUserMenuAnchorEl(null);
  };

  const [noticeMenuAnchorEl, setNoticeMenuAnchorEl] = useState(null);
  const handleNoticeMenuClick = event => {
    setNoticeMenuAnchorEl(event.currentTarget);
  };

  const handleNoticeMenuClose = () => {
    setNoticeMenuAnchorEl(null);
  };

  const [isLangModalOpen, setIsLangModalOpen] = useState(false)

  const onCronjobClick = (cronjobObj) => {
    if (!cronjobObj.isFinished) {
      return
    }
    if (cronjobObj.job.result) {
      let result = JSON.parse(cronjobObj.job.result)
      if (result.attachment_id) {
        apiExportFile(`/static/${result.attachment_id}`, {filename: cronjobObj.fileName})
      }
    }
  }

  return (
    <div className="topnav-container">
      <div className="left">
        {/* <div className="nav-toggle" onClick={handleSideNavToggle}>
          [---]
        </div> */}
        <div className="logo-wrapper">
          <div className="logo background-image"></div>
        </div>
      </div>

      <div className="right">
        <div className="notification">
          <Button className="" aria-describedby="notice-menu" onClick={handleNoticeMenuClick}>
            <div className="notice-button">
              <FontAwesomeIcon className="notice-down" icon={faBell} fixedWidth />
            </div>
          </Button>
          <Popover
            id="notice-menu"
            anchorEl={noticeMenuAnchorEl}
            keepMounted
            open={Boolean(noticeMenuAnchorEl)}
            onClose={handleNoticeMenuClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
          >
            <Card>
              <CardHeader>
                <div className="title sub-title">
                  Notification Center
                </div>
              </CardHeader>
              <div className="notification-container">
                {
                  (cronjobs && cronjobs.length > 0) 
                  ?
                  cronjobs.map((cronjob, index) => {
                    if (!cronjob.isDisplay) {
                      return null
                    }
                    return (
                      <div 
                        className={`notification-wrapper hover-opacity ${cronjob.job.status === CRONJOB_STATUS.FINISHED ? 'clickable' : ''}`}
                        key={cronjob.jobId} 
                        onClick={()=>onCronjobClick(cronjob)}>
                        <div className="name">
                          {cronjob.jobName ? cronjob.jobName : cronjob.job.task_name}
                        </div>
                        <div className="date">
                          {cronjob.job.createDateDisplay}
                        </div>
                        <div className="status">
                          {
                            (cronjob.job.status === CRONJOB_STATUS.IN_QUEUE) &&
                            <span>
                              Processing...
                            </span>
                          }
                          {
                            (cronjob.job.status === CRONJOB_STATUS.ERROR) &&
                            <span>
                              Error
                            </span>
                          }
                          {
                            (cronjob.job.status === CRONJOB_STATUS.RUNNING) &&
                            <span>
                              {cronjob.job.progress_description}
                            </span>
                          }
                          {
                            (cronjob.job.status === CRONJOB_STATUS.FINISHED) &&
                            <span>
                              {cronjob.job.progress_description}
                            </span>
                          }
                        </div>
                      </div>
                    )
                  })
                  :
                  <div className="empty-placeholder">
                    No notifications
                  </div>
                }
              </div>
            </Card>
          </Popover>
        </div>

        <div className="option">
          <Button className="option-button" aria-describedby="user-menu" onClick={handleUserMenuClick}>
            <div className="user">
              {/* <div className="image-wrapper user-icon-wrapper">
                <div className="image user-icon"></div>
              </div> */}
              <div className="name">{user.name}</div>
            </div>
            <FontAwesomeIcon className="option-down" icon={faAngleDown} fixedWidth />
          </Button>
          <Popover
            id="user-menu"
            anchorEl={userMenuAnchorEl}
            keepMounted
            open={Boolean(userMenuAnchorEl)}
            onClose={handleUserMenuClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
          >
            <div className="dropdown-menu-container">
              {/* <div className="item clickable hover-opacity" onClick={() => {setIsLangModalOpen(true); handleUserMenuClose()}}>
                Language
              </div> */}
              <div className="item clickable hover-opacity" onClick={onLogout}>
                Logout
              </div>
            </div>
          </Popover>
        </div>
      </div>
      <Dialog
        open={isLangModalOpen}
        onClose={() => setIsLangModalOpen(false)}>
        <div className="lang-select-modal">
          {
            langTabs.map((lang, index) => (
              <div className="lang clickable hover-opacity" key={lang.key} 
                onClick={() => {
                  store.dispatch(changeLanguage({language: lang.key}))
                  setIsLangModalOpen(false)
                }}>
                {lang.name}
              </div>
            ))
          }
        </div>
      </Dialog>
    </div>
  )
}

PortalTopNav.propTypes = {

}

const mapStateToProps = (state) => ({
  user: state.account.user,
  cronjobs: state.cronjob.jobs
})

const mapDispatchToProps = {
  
}

export default connect(mapStateToProps, mapDispatchToProps)(PortalTopNav)

