import React from 'react'
import PropTypes from 'prop-types'

import { Switch, Route, Redirect } from 'react-router-dom'
import UserGroupList from './list/UserGroupList'
import UserGroupSet from './set/UserGroupSet'

function UserGroup(props) {
  const { match } = props
  
  return (
    <div className="page-container">
      <Switch>
        <Route path={`${match.url}/list`} 
          render={props => <UserGroupList {...props} basePath={match.url} />}/>
        <Route path={`${match.url}/add`} 
          render={props => <UserGroupSet {...props} basePath={match.url} />}/>
        <Route path={`${match.url}/edit/:id`} 
          render={props => <UserGroupSet {...props} basePath={match.url} />}/>
        <Redirect to={`${match.url}/list`}/>
      </Switch>
    </div>
  )
}

UserGroup.propTypes = {

}

export default UserGroup

