export const searchFilter = (data, fields, keyword) => {
  let filtered = data;

  if (keyword) {
    let keywords = keyword.split(" ");
    let searchingFields = fields;

    for (let i = 0; i < keywords.length; i++) {
      let temp = [];
      let curKeyword = keywords[i];

      for (let j = 0; j < filtered.length; j++) {
        let record = filtered[j];
        if (!record) {
          continue;
        }

        for (let k = 0; k < searchingFields.length; k++) {
          let curField = searchingFields[k];
          let components = curField.split(".");
          let target = record[components.shift()];
          while (components.length && target) {
            target = target[components.shift()];
          }
          if (target && target.toLowerCase().indexOf(curKeyword.toLowerCase()) !== -1) {
            temp.push(record);
            break;
          }
        }
      }

      filtered = temp;
    }
  }

  return filtered;
}