import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import SimpleTable from '../table/SimpleTable';
import Card from '../card/Card';
import CardHeader from '../card/CardHeader';
import CardBody from '../card/CardBody';

function LinkedPostList(props) {
  const {
    posts,
    history
  } = props;

  return (
    <Card>
      <CardHeader>
        <div className="title sub-title">
          Linked Posts
        </div>
      </CardHeader>
      <CardBody>
        {
          posts.length === 0 
          ?
          <div>
            No linked posts
          </div>
          :
          <SimpleTable 
            headers={["", "Date", "Title", "Caption"]} 
            data={posts} 
            keys={['action', 'display_start', 'title', 'caption']} 
            dateCols={['display_start']}
            history={history} 
          />
        }
      </CardBody>
    </Card>
  )
}

LinkedPostList.propTypes = {

}

export default LinkedPostList

