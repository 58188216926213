import React, { useState } from 'react';
import ReactDOM from 'react-dom';

import './index.scss';
import App from './components/App';
import * as serviceWorker from './serviceWorker';

import { HashRouter } from 'react-router-dom';

import { Provider } from 'react-redux';
import store from './redux/store'

import "./helpers/FontAwesomeImport"
import "./helpers/ReactModalStyleImport"


import 'core-js';

import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

function Root() {

  return (
    <HashRouter>
      <Provider store={store}>
        <App />
      </Provider>
    </HashRouter>
  )
}

ReactDOM.render(
  Root()
, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
