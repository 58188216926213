import React from 'react'
import PropTypes from 'prop-types'
import { langTabs } from './MultiLangSelector';
import TextAreaInput from './TextAreaInput';
import HtmlInput from './HtmlInput';
import BasicInput from './BasicInput';
import FileInput from './FileInput';
import ImageSelector from './ImageSelector';
import { POST_SAMPLE_IMAGES } from '../../../helpers/Constants';
import { attachmentTypes, createLocalFileObject } from '../../../helpers/FileHelper';
import union from 'lodash/union';

function MultiLangInputs(props) {
  const {
    i18n,
    field,
    newField,
    type,
    label,
    onChange,
    rows,
    limit,
  } = props;

  const updateI18nObj = (update) => {
    // console.log(i18n, update)
    onChange({
      ...i18n,
      ...update
    })
  }

  return (
    <div className="multi-lang-input-container">
      {
        langTabs.map((lang, index) => (
          <div className="lang-input" key={lang.key}>
            {
              type === "text" &&
              <BasicInput
                value={i18n[lang.key][field]}
                placeholder={label}
                limit={limit}
                label={lang.name}
                isVertical={true}
                onChange={(val) => {
                  updateI18nObj({
                    [lang.key]: {
                      ...i18n[lang.key],
                      [field]: val
                    }
                  })
                }}
              />
            }
            {
              type === "textarea" &&
              <TextAreaInput
                value={i18n[lang.key][field]}
                placeholder={label}
                rows={rows}
                limit={limit}
                label={lang.name}
                onChange={(val) => {
                  updateI18nObj({
                    [lang.key]: {
                      ...i18n[lang.key],
                      [field]: val
                    }
                  })
                }}
              />
            }
            {
              type === "html" &&
              <HtmlInput 
                value={i18n[lang.key][field]}
                langKey={lang.key}
                placeholder={label}
                label={lang.name}
                id={`${field}-${lang.key}`}
                onChange={(val) => {
                  updateI18nObj({
                    [lang.key]: {
                      ...i18n[lang.key],
                      [newField]: val
                    }
                  })
                }}
              />
            }
            {
              type === "file" &&
              <>
                <span className="input-label">
                  {lang.name}
                </span>
                <FileInput  
                  localFileObjArr={i18n[lang.key][field]}
                  onLocalFileObjArrChange={(arr) => {
                    updateI18nObj({
                      [lang.key]: {
                        ...i18n[lang.key],
                        [field]: arr
                      }
                    })
                  }}
                  maxCount={5}
                  acceptTypes={[...attachmentTypes.pdfTypes, ...attachmentTypes.imageTypes]}
                  text="Upload"
                  isFileNameOnly={true}
                />
              </>
            }
            {
              type === "feature_image" &&
              <>
                <span className="input-label">
                  {lang.name}
                </span>
                <ImageSelector 
                  text="Select Sample Image"
                  selections={POST_SAMPLE_IMAGES}
                  isEnabled={i18n[lang.key][field].length < 5}
                  onImageSelected={(image) => {
                    let newArr = union(i18n[lang.key][field], [createLocalFileObject({file: image, name: image.name})])
                    updateI18nObj({
                      [lang.key]: {
                        ...i18n[lang.key],
                        [field]: newArr
                      }
                    })
                  }}
                />
                <FileInput  
                  localFileObjArr={i18n[lang.key][field]}
                  onLocalFileObjArrChange={(arr) => {
                    updateI18nObj({
                      [lang.key]: {
                        ...i18n[lang.key],
                        [field]: arr
                      }
                    })
                  }}
                  maxCount={5}
                  acceptTypes={attachmentTypes.imageTypes}
                  text="Upload Image"
                  isFileNameOnly={false}
                  extraTips="Recommended ratio: 3:2; Recommended dimension: 1200x800 px"
                />
              </>
            }
          </div>
        ))
      }
    </div>
  )
}

MultiLangInputs.propTypes = {

}

export default MultiLangInputs

