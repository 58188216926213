import React from 'react'
import PropTypes from 'prop-types'

import { connect } from 'react-redux'

import { Switch, Route, Redirect } from 'react-router-dom'

import EventList from './list/EventList'
import EventSet from './set/EventSet'

function Event(props) {
  const { match } = props;
  
  return (
    <div className="page-container">
      <Switch>
        <Route path={`${match.url}/list`} 
          render={props => <EventList {...props} basePath={match.url} />}/>
        <Route path={`${match.url}/add`} 
          render={props => <EventSet {...props} basePath={match.url} />}/>
        <Route path={`${match.url}/edit/:id`} 
          render={props => <EventSet {...props} basePath={match.url} />}/>
        <Redirect to={`${match.url}/list`}/>
      </Switch>
    </div>
  )
}

Event.propTypes = {

}

const mapStateToProps = (state) => ({
  
})

const mapDispatchToProps = {
  
}

export default connect(mapStateToProps, mapDispatchToProps)(Event)
