import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import Card from '../card/Card';
import CardHeader from '../card/CardHeader';
import CardBody from '../card/CardBody';
import SelectInput from './SelectInput';
import RadioList from './RadioList';

import find from 'lodash/find'
import { apiPost } from '../../../helpers/Api';
import { Dialog, DialogContent, DialogActions, Button, Popover, Fab } from '@material-ui/core';
import SelectionList from '../list/SelectionList';
import DateRenderer from '../list/DateRenderer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/free-regular-svg-icons';

export const typePost = {
  value: 'post',
  label: 'Post',
}

export const typeEvent = {
  value: 'event',
  label: 'Event',
}

export const typeSurvey = {
  value: 'questionnaire',
  label: 'Survey',
}

export const typePromotion = {
  value: 'promotion',
  label: 'Coupon',
}

function LinkageSelector(props) {
  const {
    value,
    types,
    onChange,
    isDisabled,
  } = props;

  const [type, setType] = useState(null)
  useEffect(() => {
    getList()
  }, [type])

  const [list, setList] = useState([])
  const getList = () => {
    setSelected(null)

    let url = '';
    switch (type) {
      case typeEvent:
        url = '/admin/events';
        break;
      case typeSurvey:
        url = '/admin/questionnaire/list';
        break;
      case typePromotion:
        url = '/admin/promotion';
        break;
    }

    if (!url) {
      return
    }

    apiPost(url)
      .then((resp) => {
        let data = resp.data;
        data.forEach((d) => {
          d.name = d.name || d.title
          d.start_time = d.start_time || d.start_date
          d.end_time = d.end_time || d.expiry_date
        })
        setList(data)
      })
      .catch((err) => {
        setList([])
      })
  }

  const [selected, setSelected] = useState(null)

  // useEffect(() => {
  //   onChange(
  //     selected ? {
  //       target_type: type.value,
  //       target_id: selected[`${type.value}_id`],
  //       targetObject: selected
  //     } : null)
  // }, [selected])
  
  const mapSelectedObj = () => {
    if (!value) {
      return
    }
    if (value.target_id) {
      let target = find(list, (data) => {
        return data[`${value.target_type}_id`] === value.target_id
      })
      if (target) {
        setSelected(target)
      }
    }
  }

  useEffect(() => {
    mapSelectedObj()
  }, [list])

  useEffect(() => {
    if (!value) {
      return
    }
    if (value.target_type) {
      setType(find(types, {value: value.target_type}))
    }
    mapSelectedObj()
  }, [value])

  const [isSelectorModalOpen, setIsSelectorModalOpen] = useState(false);
  const openSelectorModal = () => {
    setIsSelectorModalOpen(true)
  }
  const closeSelectorModal = () => {
    setIsSelectorModalOpen(false)
  }
  const saveSelector = () => {
    onChange(
      selected ? {
        target_type: type.value,
        target_id: selected[`${type.value}_id`]
      } : null)
    closeSelectorModal();
  }

  // popover
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <div className="linkage-selector">
      <Card>
        <CardHeader>
          <div className="title sub-title">
            Linkage
          </div>
          <div className="actions">
            <button aria-describedby={id} className="button default circle" onClick={handleClick}>
              <FontAwesomeIcon icon={faQuestionCircle} />
            </button>
            <Popover
              id={id}
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'center',
                horizontal: 'left',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
            >
              <Card>
                <CardHeader>
                  <div className="title sub-title">
                    Post Linkage?
                  </div>
                </CardHeader>
                <CardBody>
                  <p>
                    A Post can be linked with other information.
                  </p>
                  <p>
                    <u>Event</u>
                    <br></br>
                    User can <b>view Event Detail</b> via the Post.
                  </p>
                  <p>
                    <u>Survey</u>
                    <br></br>
                    User can <b>complete the Survey</b> via the Post.
                  </p>
                  <p>
                    <u>Promotion</u>
                    <br></br>
                    User can <b>redeem the Promotion</b> via the Post.
                  </p>
                </CardBody>
              </Card>
            </Popover>
          </div>
        </CardHeader>
        <CardBody>
          {
            selected &&
            <div className="linkage-preview">
              <div className="linked-type">
                {type.label}
              </div>
              <div className="linked-name">
                {selected.name}
              </div>
              <div className="linked-label">
                From
              </div>
              <div className="linked-date">
                <DateRenderer value={selected.start_time} />
              </div>
              <div className="linked-label">
                To
              </div>
              <div className="linked-date">
                <DateRenderer value={selected.end_time} />
              </div>
            </div>
          }
          {
            !isDisabled &&
            <button className="button info linkage-select" onClick={openSelectorModal}>
              <span>Select Linkage</span>
            </button>
          }
        </CardBody>
      </Card>

      <Dialog
        open={isSelectorModalOpen}
        onClose={closeSelectorModal}
        fullWidth={true}
        maxWidth="lg"
        scroll="paper"
      >
        <CardHeader>
          <div className="title">
            Select Linkage
          </div>
          <div className="actions">
            {
              !isDisabled &&
              <div className="text-align-right hover-opacity clickable" onClick={() => {setSelected(null); setType(null)}}>
                Clear
              </div>
            }
          </div>
        </CardHeader>
        <DialogContent dividers>
          {
            types.length > 1 &&
            <div>
              <SelectInput 
                options={types}
                value={type}
                onChange={setType}
                isDisabled={isDisabled}
              />
              <div className="border-bottom"></div>
            </div>
          }
          {/* <RadioList 
            data={list}
            selected={selected}
            onChange={setSelected}
            isDisabled={isDisabled}
            isSearch={!isDisabled}
          /> */}
          <SelectionList 
            gridOptions={{
              columnDefs: [
                {
                  headerName: "Name",
                  field: "name",
                  checkboxSelection: true
                },
                {
                  headerName: "From",
                  field: "start_time",
                  sort: 'desc',
                  cellRenderer: 'dateRenderer'
                },
                {
                  headerName: "To",
                  field: "end_time",
                  cellRenderer: 'dateRenderer'
                }
              ],
              frameworkComponents: {
                dateRenderer: DateRenderer
              },
              rowSelection: 'single'
            }}
            rowData={list}
            onChange={(items) => setSelected(items[0])}
            defaultSelections={[selected]}
          />
        </DialogContent>
        <DialogActions>
          <button className="button default" onClick={closeSelectorModal}>
            <span>Cancel</span>
          </button>
          <button className="button primary" onClick={saveSelector}>
            <span>Select</span>
          </button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

LinkageSelector.propTypes = {

}

export default LinkageSelector

