import React from 'react'
import PropTypes from 'prop-types'
import Card from '../../../utilities/card/Card';
import CardHeader from '../../../utilities/card/CardHeader';
import CardBody from '../../../utilities/card/CardBody';
import ToolsRenderer from '../../../utilities/list/ToolsRenderer';
import { apiPost } from '../../../../helpers/Api';
import List from '../../../utilities/list/List';
import DateRenderer from '../../../utilities/list/DateRenderer';

function UserDevice(props) {
  const { devices, deleteUserDevice } = props;

  const gridOptions = {
    columnDefs: [
      {
        headerName: "Device Token",
        field: "device_token"
      },
      {
        headerName: "Version",
        field: "app_version",
      },
      {
        headerName: "Name",
        field: "device_name",
      },
      {
        headerName: "Reg. Date",
        field: "registration_date",
        sort: "desc",
        cellRenderer: 'dateRenderer'
      },
      {
        headerName: "Type",
        field: "device_type",
      },
      {
        headerName: "",
        field: "device_id",
        pinned: "right",
        width: 80,
        minWidth: 80,
        maxWidth: 80,
        sortable: false,
        cellRenderer: 'toolsRenderer',
        cellRendererParams: {
          onClickCallback: {
            remove: (row) => {
              deleteUserDevice(row);
            },
          },
          types: ["remove"]
        }
      },
    ],
    frameworkComponents: {
      toolsRenderer: ToolsRenderer,
      dateRenderer: DateRenderer
    }
  }

  return (
    <div>
      <Card>
        <CardHeader>
          <div className="title sub-title">
            Device
          </div>
        </CardHeader>
        <div style={{height: 300}}>
          <List
            headerOptions={{enable: false}}
            gridOptions={gridOptions}
            rowData={devices}
          />
        </div>
      </Card>
    </div>
  )
}

UserDevice.propTypes = {

}

export default UserDevice

