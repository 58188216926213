import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import BasicInput from '../../../../utilities/form/BasicInput';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Sortable from 'react-sortablejs/lib/Sortable';

function MultipleChoiceQuestion(props) {
  const {
    question,
    onChange,
  } = props;

  const callOnChange = () => {
    onChange({...question, ...{options: question.options}})
  }

  const updateQuestionOption = (index, value) => {
    question.options[index] = {
      ...question.options[index],
      ...{name: value}
    }
    callOnChange()
  }

  const addOption = () => {
    question.options.push({
      name: ''
    })
    callOnChange()
  }

  const removeOption = (index) => {
    question.options.splice(index, 1);
    callOnChange()
  }

  const dragReOrder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
  
    return result;
  };

  const onDragEnd = (order, sortable, event) => {
    question.options = dragReOrder(question.options, event.oldIndex, event.newIndex);
    callOnChange()
  }

  return (
    <div className="multiple-choice-question">
      <div className="option-add text-align-right">
        <span className="clickable hover-opacity" onClick={addOption}>
          Add New Option
          <FontAwesomeIcon icon="plus" fixedWidth />
        </span>
      </div>
      <div className="border-bottom"></div>
      <Sortable
        options={{
          hanlde: '.sort-handle'
        }}
        onChange={onDragEnd}
      >
        {
          question.options.map((option, index) => (
            <div className="option-wrapper" key={index}>
              <div className="sort-handle">
                <FontAwesomeIcon icon="bars" fixedWidth />
              </div>
              <div className="option-input">
                <BasicInput 
                  key={index}
                  type="text" 
                  value={option.name}
                  label={`Option ${index+1}`}
                  onChange={(val) => {
                    updateQuestionOption(index, val)
                  }}
                />
              </div>
              <FontAwesomeIcon className="clickable" icon="times" fixedWidth  onClick={() => removeOption(index)} />
            </div>
          ))
        }
      </Sortable>
    </div>
  )
}

MultipleChoiceQuestion.propTypes = {

}

export default MultipleChoiceQuestion

