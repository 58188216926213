import React from 'react'
import PropTypes from 'prop-types'

import { connect } from 'react-redux'

import { Switch, Route, Redirect } from 'react-router-dom'
import SettingPost from './post/SettingPost';
import SettingCompany from './company/SettingCompany';
import SettingPhoneBook from './phoneBook/SettingPhoneBook';
import SettingJargonBuster from './jargonBuster/SettingJargonBuster';
import SettingAccPage from './accPage/SettingAccPage';
import SettingTnc from './tnc/SettingTnc';
import { checkIfModuleIsEnabledByKey } from '../../../helpers/AccountHelper';
import { SettingRouteList } from '../Routes';

function Setting(props) {
  const { match, user } = props;

  let defaultPage;
  for (let i = 0; i < SettingRouteList.length; i++) {
    if (checkIfModuleIsEnabledByKey(user, SettingRouteList[i].moduleKey)) {
      defaultPage = SettingRouteList[i].path
      break
    }
  }

  return (
    <div className="page-container">
      <Switch>
        <Route path={`${match.url}/post`}
          render={props => <SettingPost {...props} basePath={match.url} />}
        />
        <Route path={`${match.url}/company`}
          render={props => <SettingCompany {...props} basePath={match.url} />}
        />
        <Route path={`${match.url}/phone_book`}
          render={props => <SettingPhoneBook {...props} basePath={match.url} />}
        />
        <Route path={`${match.url}/jargon_buster`}
          render={props => <SettingJargonBuster {...props} basePath={match.url} />}
        />
        <Route path={`${match.url}/acc_page`}
          render={props => <SettingAccPage {...props} basePath={match.url} />}
        />
        <Route path={`${match.url}/tnc`}
          render={props => <SettingTnc {...props} basePath={match.url} />}
        />
        <Redirect to={defaultPage} />
      </Switch>
    </div>
  )
}

Setting.propTypes = {

}

const mapStateToProps = (state) => ({
  user: state.account.user
})

const mapDispatchToProps = {
  
}


export default connect(mapStateToProps, mapDispatchToProps)(Setting)

