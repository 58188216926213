import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import { Link } from 'react-router-dom'
import each from 'lodash/each'
import { apiPost } from '../../../../helpers/Api'
import { searchFilter } from '../../../../helpers/SearchHelper'
import Card from '../../../utilities/card/Card'
import CardHeader from '../../../utilities/card/CardHeader'
import Tab from '../../../utilities/tab/Tab'
import List from '../../../utilities/list/List'
import ToolsRenderer from '../../../utilities/list/ToolsRenderer'
import DateRenderer from '../../../utilities/list/DateRenderer'
import { postStatusTabs, postStatus } from '../../../../helpers/PostHelper'
import { Dialog } from '@material-ui/core'
import PromotionStat from '../stat/PromotionStat'

function PromotionList(props) {
  const { match, basePath, history } = props;

  const [originalData, setOriginalData] = useState([]);
  const [listKeyword, setListKeyword] = useState('');
  const [listFilter, setListFilter] = useState(null);
  const [activeTab, setActiveTab] = useState(postStatusTabs[0]);

  const [headerOptions, setHeaderOptions] = useState({
    enable: true,
    isSearch: true,
    searchableFields: ['name', 'author.name'],
    filters: [
      // {value: 'all', label: "All Categories"},
    ],
    onHeaderChange: (keyword, filter) => {
      setListKeyword(keyword);
      setListFilter(filter);
    }
  })


  const columns = [
    { 
      headerName: "Name", 
      field: "name"
    },
    // { 
    //   headerName: "Categories", 
    //   field: "categories"
    // },
    // { 
    //   headerName: "Status", 
    //   field: "status" 
    // },
    { 
      headerName: "From", 
      field: "start_time",
      sort: 'desc',
      cellRenderer: 'dateRenderer'
    },
    { 
      headerName: "To", 
      field: "end_time",
      cellRenderer: 'dateRenderer'
    },
    { 
      headerName: "Author", 
      field: "author.name"
    },
    { 
      headerName: "Stats", 
      field: "stats",
      pinned: "right",
      width: 80,
      minWidth: 80,
      maxWidth: 80,
      sortable: false,
      cellRenderer: 'toolsRenderer',
      cellRendererParams: {
        onClickCallback: {
          stat: (row) => {
            viewPromotionStat(row)
          }
        },
        types: ["stat"]
      }
    },
    { 
      headerName: "Tools", 
      field: "tools", 
      pinned: "right",
      width: 100,
      minWidth: 100,
      maxWidth: 100,
      sortable: false,
      cellRenderer: 'toolsRenderer',
      cellRendererParams: {
        onClickCallback: {
          edit: (row) => {
            history.push(`${basePath}/edit/${row.promotion_id}`);
          },
          remove: (row) => {
            deletePromotion(row);
          }
        },
        types: ["edit", "remove"]
      }
    }
  ];

  const [gridOptions, setGridOptions] = useState({
    columnDefs: columns,
    frameworkComponents: {
      toolsRenderer: ToolsRenderer,
      dateRenderer: DateRenderer
    }
  });

  const [rowData, setRowData] = useState([...originalData]);

  const search = () => {
    let filtered = [ ...originalData ];

    if (activeTab && activeTab.key !== 'all') {
      filtered = filtered.filter((data) => {
        return data.status === activeTab.name;
      })
    }

    if (listFilter && listFilter.value !== 'all') {
      filtered = filtered.filter((data) => {
        return data.categories === listFilter.label;
      })
    }

    filtered = searchFilter(filtered, headerOptions.searchableFields, listKeyword);

    setRowData(filtered);
  }

  const getPromotionList = () => {
    let param = {

    }
    apiPost('/admin/promotion', param)
      .then((resp) => {
        let posts = resp.data;

        setOriginalData(posts);
      })
  }

  const deletePromotion = (item) => {
    let param = {
      promotion_id: item.promotion_id
    }
    apiPost('/admin/promotion/delete', param)
      .then((resp) => {
        history.push(match.url);
      })
      .catch((err) => {
        console.error(err);
      })
  }

  const [isPromotionStatModalOpen, setIsPromotionStatModalOpen] = useState(false);
  const [promotionStat, setPromotionStat] = useState({})
  const [promotionStatObj, setPromotionStatObj] = useState({})
  const viewPromotionStat = (promotion) => {
    // TODO:
    setPromotionStatObj(promotion);
    let param = {
      promotion_id: promotion.promotion_id
    }
    apiPost('/admin/promotion/stats', param)
      .then((resp) => {
        setPromotionStat(resp.data);
        setIsPromotionStatModalOpen(true);
      })
  }
  const closePromotionStat = () => {
    setPromotionStat({});
    setIsPromotionStatModalOpen(false);
  }

  useEffect(() => {
    getPromotionList()
  }, [])

  useEffect(() => {
    // updateTabs()
    search()
  }, [originalData])

  useEffect(() => {
    search()
  }, [activeTab, listKeyword, listFilter])

  return (
    <div className="list-container">
      <div className="header">
        <Card>
          <CardHeader>
            <div className="title">Coupon</div>
            <div className="actions">
              <button 
                className="button default"
                onClick={() => history.push(`${basePath}/add`)}
              >
                <a>Add New</a>
              </button>
            </div>
            {/* <Tab tabs={postStatusTabs} activeTab={activeTab} switchActiveTab={setActiveTab} /> */}
          </CardHeader>
        </Card>
      </div>
      <div className="body">
        <List 
          headerOptions={headerOptions}
          gridOptions={gridOptions}
          rowData={rowData}
        />
      </div>
      <Dialog
        open={isPromotionStatModalOpen}
        onClose={closePromotionStat}
        fullWidth={true}
        maxWidth="lg"
        scroll="paper"
      >
        <PromotionStat promotion={promotionStatObj} stat={promotionStat} handleClose={closePromotionStat} />
      </Dialog>
    </div>
  )
}

PromotionList.propTypes = {

}

export default PromotionList

