import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import ToolsRenderer from '../../../utilities/list/ToolsRenderer';
import { searchFilter } from '../../../../helpers/SearchHelper';
import Card from '../../../utilities/card/Card';
import CardHeader from '../../../utilities/card/CardHeader';
import List from '../../../utilities/list/List';
import { apiPost, apiExportFile } from '../../../../helpers/Api';
import { getExportFileNameDate } from '../../../../helpers/DateHelper';

function JargonBusterList(props) {
  const { 
    match, 
    basePath,
    history
  } = props;
  
  const [originalData, setOriginalData] = useState([]);
  const [listKeyword, setListKeyword] = useState('');
  const [listFilter, setListFilter] = useState(null);

  const [headerOptions, setHeaderOptions] = useState({
    enable: true,
    isSearch: true,
    searchableFields: ['name', 'description'],
    filters: [

    ],
    onHeaderChange: (keyword, filter) => {
      setListKeyword(keyword);
      setListFilter(filter);
    }
  })

  const columns = [
    {
      headerName: "Name",
      field: "name",
      sort: 'asc',
    },
    {
      headerName: "Description",
      field: "description",
    },
    // {
    //   headerName: "Phone Number",
    //   field: "phone_no",
    // },
    {
      headerName: "Tools",
      field: "category_id",
      pinned: "right",
      width: 100,
      minWidth: 100,
      maxWidth: 100,
      sortable: false,
      cellRenderer: 'toolsRenderer',
      cellRendererParams: {
        onClickCallback: {
          edit: (row) => {
            editJargonBuster(row);
          },
          remove: (row) => {
            deleteJargonBuster(row);
          }
        },
        types: ["edit", "remove"]
      }
    },
  ]

  const [gridOptions, setGridOptions] = useState({
    columnDefs: columns,
    frameworkComponents: {
      toolsRenderer: ToolsRenderer,
    }
  });

  const [rowData, setRowData] = useState([...originalData]);

  const search = () => {
    let filtered = originalData;


    if (listKeyword) {
      filtered = searchFilter(filtered, headerOptions.searchableFields, listKeyword);
    }

    setRowData(filtered);
  }

  const getJargonBusterList = () => {
    let param = {

    }
    apiPost('/admin/hkaa_community/jargon_buster', param)
      .then((resp) => {
        let categories = resp.data;

        setOriginalData(categories);
      })
  }

  const deleteJargonBuster = (item) => {
    let param = {
      item_id: item.item_id
    }
    apiPost('/admin/hkaa_community/jargon_buster/delete', param)
      .then((resp) => {
        history.push(match.url);
      })
      .catch((err) => {
        console.error(err);
      })
  }

  const editJargonBuster = (jargonBuster) => {
    history.push(`${basePath}/edit/${jargonBuster.item_id}`);
  }

  const exportJargonBuster = () => {
    apiExportFile(
      '/admin/hkaa_community/jargon_buster/export', 
      {
        jobName: 'Export jargon buster', 
        isDisplay: true, 
        filename: `jargon_buster_export_${getExportFileNameDate()}`
      }
    )
  }

  useEffect(() => {
    getJargonBusterList();
  }, []);

  useEffect(() => {
    search();
  }, [originalData]);

  useEffect(() => {
    search()
  }, [listKeyword, listFilter]);

  return (
    <div className="list-container">
      <div className="header">
        <Card>
          <CardHeader>
            <div className="title">Jargon Buster</div>
            <div className="actions">
              <button className="button info" onClick={exportJargonBuster}>
                <a>Export</a>
              </button>
              <button 
                className="button default"
                onClick={() => history.push(`${basePath}/add`)}
              >
                <a>Add New</a>
              </button>
            </div>
          </CardHeader>
        </Card>
      </div>
      <div className="body">
        <List 
          headerOptions={headerOptions}
          gridOptions={gridOptions}
          rowData={rowData}
        />
      </div>
    </div>
  )
}

JargonBusterList.propTypes = {

}

export default JargonBusterList

