import React, { useState, useEffect } from 'react'

import { connect } from 'react-redux'
import MultiLangSelector, { langTabs, defaultLang } from '../../../utilities/form/MultiLangSelector';
import Card from '../../../utilities/card/Card';
import CardHeader from '../../../utilities/card/CardHeader';
import CardBody from '../../../utilities/card/CardBody';
import CheckboxInput from '../../../utilities/form/CheckboxInput';
import { apiPost } from '../../../../helpers/Api';

import { toast } from '../../../utilities/Notification/CustomToast';


import map from 'lodash/map';
import moment from 'moment';
import each from 'lodash/each';
import find from 'lodash/find';
import trim from 'lodash/trim'
import { createLocalFileObject, uploadLocalFileObjects } from '../../../../helpers/FileHelper';
import DateInput from '../../../utilities/form/DateInput';
import TargetAudienceSelector, { AudienceCompany } from '../../../utilities/form/TargetAudienceSelector';
import RadioList from '../../../utilities/form/RadioList';
import MultiLangInputs from '../../../utilities/form/MultiLangInputs';
import LinkageSelector, { typeEvent, typeSurvey, typePromotion } from '../../../utilities/form/LinkageSelector';
import PostPreview from '../preview/PostPreview';
import { Dialog } from '@material-ui/core';
import { postFieldLimit, postStatusDraft, postStatusOptions, postStatusScheduled, postStatusTabs, PostAdvertisementColors, getPostTextColorByBgColor } from '../../../../helpers/PostHelper';
import {
  getFullDateDisplayByTimestampSecond,
  checkIfTimesInCorrectOrder,
  checkIfTimesIsEqual
} from '../../../../helpers/DateHelper';

function PostSet(props) {
  const { 
    match, 
    basePath, 
    history,
    preselectedLinkage,
    onSaveCallback,
  } = props;

  let postId = match ? match.params.id : null;

  let i18nDefaultObj = {};
  each(langTabs, (lang) => {
    i18nDefaultObj[lang.key] = {
      title: '', 
      caption: '', 
      html_content: '', 
      html_content_new: '', 
      localImageObjArr: [],
      uploadedImageSrcArr: [],
      localMediaObjArr: [],
      uploadedMediaSrcArr: [],
      button_content: '',
      button_url: ''
    }
  })

  const defaultPostObj = {
    title: '',
    caption: '',
    html_content: '',
    html_content_new: '',
    isNotify: '',
    categories: [],
    defaultCategories: [],
    defaultCategoryIds: [],
    selectedUserGroups: [],
    defaultSelectedUserGroups: [],
    defaultSelectedUserGroupIds: [],
    isPublishOnNow: true,
    publishOn: [null],
    isNeverExpire: true,
    expireOn: [null],
    _i18n: i18nDefaultObj,
    isSharable: true,
    isLikable: true,
    status: postStatusOptions[0],
    isDraft: true,
    localMediaObjArr: [],
    uploadedMediaSrcArr: [],
    localImageObjArr: [],
    uploadedImageSrcArr: [],
    linkage: preselectedLinkage ? preselectedLinkage : null,
    originalStatus: postStatusDraft,
    audience: [AudienceCompany],
    audience_new: [],
    isShowInCalendar: false,
    calendarStart: [null],
    calendarEnd: [null],
    isShowPostButton: false,
    button_content: '',
    button_url: '',
    isAdvertisement: false,
    advertisementStart: [null],
    advertisementEnd: [null],
    advertisementBgColor: null,
    isPinToTop: false,
    // isAlwaysPin: false,
    pinToTopStart: [null],
    pinToTopEnd: [null],
    pinToTopCategory: '',
    // isPublicApiCall: false,
  };

  const [post, setPost] = useState(defaultPostObj);

  const updatePostInfo = (update) => {
    // console.log(post, update)
    setPost({
      ...post,
      ...update
    })
  }

  const getPostDetail = () => {
    let param = {
      post_id: postId
    }
    apiPost('/admin/post', param)
      .then((resp) => {
        let post = resp.data;

        let i18n = {};
        each(langTabs, (lang, index) => {
          i18n[lang.key] = {
            title: post.title,
            caption: post.caption,
            html_content: post.html_content,
            html_content_new: post.html_content,
            localMediaObjArr: map(post.media, (m) => {
              return createLocalFileObject({attachmentId: m.attachment_id, name: m.filename})
            }),
            uploadedMediaSrcArr: [],
            localImageObjArr: map(post.images, (i) => {
              return createLocalFileObject({attachmentId: i, name: i})
            }),
            uploadedImageSrcArr: [],
            button_content: post.button_content || '',
            button_url: post.button_url || ''
          }
          let curLangObj = post._i18n[lang.key];
          if (curLangObj) {
            i18n[lang.key] = {
              ...i18n[lang.key],
              ...curLangObj,
              ...{
                html_content_new: curLangObj.html_content,
                button_content: curLangObj.button_content || '',
                button_url: curLangObj.button_url || '',
              },
            }

            if (curLangObj.media_attachments) {
              i18n[lang.key] = {
                ...i18n[lang.key],
                ...{
                  localMediaObjArr: map(curLangObj.media_attachments, (m) => {
                    return createLocalFileObject({attachmentId: m.attachment_id, name: m.filename})
                  }),
                }
              }
            }

            if (curLangObj.images) {
              i18n[lang.key] = {
                ...i18n[lang.key],
                ...{
                  localImageObjArr: map(curLangObj.images, (i) => {
                    return createLocalFileObject({attachmentId: i, name: i})
                  }),
                }
              }
            }
          }
        })
        
        post._i18n = {...i18nDefaultObj, ...i18n}

        post.isPublishOnNow = false;
        post.publishOn = [new Date(parseFloat(post.display_start) * 1000)]
        if (post.display_end) {
          post.isNeverExpire = false;
          post.expireOn = [new Date(parseFloat(post.display_end) * 1000)]
        } else {
          post.isNeverExpire = true;
        }

        post.defaultCategoryIds = post.category_ids;

        post.isNotify = !!post.is_notify;
        post.isSharable = !!post.is_sharable;
        post.isLikable = !!post.is_likable;
        // post.isPublicApiCall = !!post.is_public_api_call;
        post.isPinToTop = !!post.is_pin_to_top;
        // post.isAlwaysPin = post.pin_to_top_start && (post.pin_to_top_end == null) && post.isPublicApiCall

        if (post.isPinToTop) {
          post.pinToTopStart = [new Date(parseFloat(post.pin_to_top_start) * 1000)]
          post.pinToTopEnd = [new Date(parseFloat(post.pin_to_top_end) * 1000)]
        }

        post.localMediaObjArr = map(post.media_attachments, (m) => {
          return createLocalFileObject({attachmentId: m.attachment_id, name: m.filename})
        })
        
        post.localImageObjArr = map(post.images, (i) => {
          return createLocalFileObject({attachmentId: i, name: i})
        })

        post.isDraft = post.status === postStatusDraft.enum
        post.isPublished = post.status !== postStatusDraft.enum && post.status !== postStatusScheduled.enum;
        post.originalStatus = find(postStatusTabs, (s) => {
          return s.enum === post.status
        })
        post.status = post.status === postStatusDraft.enum ? postStatusOptions[0] : postStatusOptions[1]

        // post.status = find(postStatusTabs, (s) => {
        //   return s.enum === post.status
        // })

        post.linkage = post.linkage ? post.linkage[0] : null

        post.audience_new = post.audience

        post.isShowInCalendar = !!post.is_show_in_calendar;
        if (post.isShowInCalendar) {
          post.calendarStart = [new Date(parseFloat(post.calendar_start) * 1000)]
          post.calendarEnd = [new Date(parseFloat(post.calendar_end) * 1000)]
        }

        post.isAdvertisement = !!post.is_advertisement;
        post.advertisementBgColor = post.advertisement_bg_color;
        if (post.isAdvertisement) {
          post.advertisementStart = [new Date(parseFloat(post.advertisement_start) * 1000)]
          post.advertisementEnd = [new Date(parseFloat(post.advertisement_end) * 1000)]
        }

        post.isShowPostButton = post.button_content && post.button_url;
        post.button_content = post.button_content || '';
        post.button_url = post.button_url || '';

        updatePostInfo({
          ...defaultPostObj,
          ...post
        })

        getPostCategory()
        getUserGroupList()
      })
  }

  const [categoryList, setCategoryList] = useState([])
  const getPostCategory = () => {
    let param = {

    }
    apiPost('/admin/post/category', param)
    .then((resp) => {
      let categories = resp.data;

      categories.sort((a, b) => {
        return a.name.toLowerCase().localeCompare(b.name.toLowerCase())
      })

      setCategoryList(categories);
    })
  }
  const setDefaultCategorySelection = () => {
    let mapping = [];
    each(post.defaultCategoryIds, (id) => {
      let target = find(categoryList, {category_id: id})
      if (target) {
        mapping.push(target)
      }
    })
    updatePostInfo({categories: mapping[0]})
  }
  useEffect(() => {
    setDefaultCategorySelection()
  }, [categoryList])

  const [userGroupList, setUserGroupList] = useState([])
  const getUserGroupList = () => {
    let param = {

    }

    apiPost('/admin/manageable_news_channels', param)
      .then((resp) => {
        let ugs = resp.data;

        // TODO: if needed

        setUserGroupList(ugs)
      })
  }
  const setDefaultUserGroupSelection = () => {
    let mapping = [];
    each(post.defaultSelectedUserGroupIds, (id) => {
      let target = find(userGroupList, {chat_id: id})
      if (target) {
        mapping.push(target)
      }
    })
    updatePostInfo({defaultSelectedUserGroups: mapping, selectedUserGroups: mapping})
  }
  useEffect(() => {
    setDefaultUserGroupSelection()
  }, [userGroupList])

  const checkInput = async (isDraft) => {
    // Prepare publish on date
    if (post.isPublishOnNow) {
      post.publishOn = [new Date()]
    }

    // if it is draft, only need basic title in order to save draft
    // if (post.status.value === postStatusDraft.enum) {
    if (isDraft) {
      for (let i = 0; i < langTabs.length; i++) {
        let lang = langTabs[i];
        if (!post._i18n[lang.key].title) {
          toast.warn("Please enter title for all languages");
          return false;
        }
      }
      return true;
    }

    // not a draft
    for (let i = 0; i < langTabs.length; i++) {
      let lang = langTabs[i];
      let curLangObj = post._i18n[lang.key]
      if (!curLangObj.title) {
        toast.warn("Please enter title for all languages");
        return false;
      }
      if (!curLangObj.caption) {
        toast.warn("Please enter caption for all languages");
        return false;
      }
      if (!curLangObj.html_content_new) {
        toast.warn("Please enter content for all languages");
        return false;
      }
      if (curLangObj.title.length > postFieldLimit.title) {
        toast.warn("Invalid title (Exceeds length limit)")
        return false;
      }
      if (curLangObj.caption.length > postFieldLimit.caption) {
        toast.warn("Invalid caption (Exceeds length limit)")
        return false;
      }
      if (curLangObj.localImageObjArr.length === 0) {
        toast.warn("Please upload at least one featured image for all languages")
        return false;
      }
      if (post.isShowPostButton) {
        if (!curLangObj.button_content && !curLangObj.button_url) {
          toast.warn("Please enter button content & URL for all languages")
          return false
        }
      }
    }


    if (post.audience_new.length === 0) {
      toast.warn("Please select target audiences");
      return false;
    }

    if (!post.isPublishOnNow && !post.publishOn[0]) {
      toast.warn("Please select publish date");
      return false;
    }

    if (!post.isNeverExpire && !post.expireOn[0]) {
      toast.warn("Please select expire date");
      return false;
    }

    if (!post.isNeverExpire && !checkIfTimesInCorrectOrder(post.publishOn[0], post.expireOn[0])) {
      toast.warn("Expire date must be after publish date")
      return false;
    }

    if (!post.categories) {
      toast.warn("Please select category");
      return false;
    }

    if (post.isShowInCalendar) {
      if (!post.calendarStart[0]) {
        toast.warn("Please select calendar start date");
        return false;
      }
      if (!post.calendarEnd[0]) {
        toast.warn("Please select calendar end date");
        return false;
      }
      if (!checkIfTimesInCorrectOrder(post.calendarStart[0], post.calendarEnd[0])) {
        toast.warn("Calendar end date must be after calendar start date")
        return false
      }

      if (!post.isNeverExpire && !checkIfTimesIsEqual(post.calendarEnd[0], post.expireOn[0]) &&!checkIfTimesInCorrectOrder(post.calendarEnd[0], post.expireOn[0])) {
        toast.warn("Show Calendar end date must be before Post expiry date")
        return false;
      }
    }

    if (post.isAdvertisement) {
      if (!post.advertisementStart[0]) {
        toast.warn("Please select advertisement start date");
        return false;
      }
      if (!post.advertisementEnd[0]) {
        toast.warn("Please select advertisement end date");
        return false;
      }
      if (!checkIfTimesInCorrectOrder(post.advertisementStart[0], post.advertisementEnd[0])) {
        toast.warn("Advertisement end date must be after advertisement start date")
        return false
      }
      if (!post.advertisementBgColor) {
        toast.warn("Please select advertisement background color");
        return false;
      }

      if (!post.isNeverExpire && !checkIfTimesIsEqual(post.advertisementEnd[0], post.expireOn[0]) && !checkIfTimesInCorrectOrder(post.advertisementEnd[0], post.expireOn[0])) {
        toast.warn("Advertisement end date must be before Post expiry date")
        return false;
      }
    }

    if (post.isPinToTop) {
      if (!post.pinToTopStart[0]) {
        toast.warn("Please select pin to top start date");
        return false;
      }

      if (!post.pinToTopEnd[0]) {
        toast.warn("Please select pin to top end date");
        return false;
      }

      if (!checkIfTimesInCorrectOrder(post.pinToTopStart[0], post.pinToTopEnd[0])) {
        toast.warn("Pin to top end date must be after pin to top start date")
        return false;
      }

      if (!post.isNeverExpire && !checkIfTimesIsEqual(post.pinToTopEnd[0], post.expireOn[0]) && !checkIfTimesInCorrectOrder(post.pinToTopEnd[0], post.expireOn[0])) {
        toast.warn("Pin to top end date must be before Post expiry date")
        return false;
      }


      let check_pin_to_top_overlap_param = {
        post_id: post.post_id,
        category_id: post.categories.category_id,
        pin_to_top_start: post.pinToTopStart[0].getTime() / 1000,
        pin_to_top_end: post.pinToTopEnd[0].getTime() / 1000
      }

      let post_list = null

      await apiPost('/admin/post/pin-to-top/check-overlap', check_pin_to_top_overlap_param)
          .then(resp => {
            post_list = resp.data
          })
          .catch(err => {
            toast.error(err.message)
            return false
          })


      if (post_list && post_list.length > 0) {
        // display warning message including the title, pin to top start date, pin to top end date of each post in post list
          let post_list_warning_message = post_list.map(post => {
            let pin_to_top_end_str = post.pin_to_top_end ? moment(post.pin_to_top_end * 1000).format("YYYY-MM-DD") : "Forever";
            return `${post.title} (${moment(post.pin_to_top_start * 1000).format("YYYY-MM-DD")} to ${pin_to_top_end_str})`;
          }).join('<br />');

          const full_message = `Your Pin To Top period overlaps with the following posts:<br />${post_list_warning_message}`;

          toast.warn(<div dangerouslySetInnerHTML={{ __html: full_message }} />);

        return false
      }
    }

    return true;
  }

  const uploadPic = (obj, index, callback) => {    
    uploadLocalFileObjects({
      arr: obj.localImageObjArr,
      index: index,
      uploadedIds: [],
      callbackWithIds: (ids) => {
        obj.uploadedImageSrcArr = ids
        callback()
      }
    })
  }

  const uploadLangPic = (index, callback) => {
    if (index === langTabs.length) {
      callback();
      return
    }
    uploadPic(
      post._i18n[langTabs[index].key],
      0,
      () => {
        uploadLangPic(++index, callback)
      }
    )
  }

  const uploadMedia = (obj, index, callback) => {
    uploadLocalFileObjects({
      arr: obj.localMediaObjArr,
      index: index,
      uploadedIds: [],
      callbackWithIds: (ids) => {
        obj.uploadedMediaSrcArr = ids
        callback()
      }
    })

  }

  const uploadLangMedia = (index, callback) => {
    if (index === langTabs.length) {
      callback();
      return
    }
    uploadMedia(
      post._i18n[langTabs[index].key],
      0,
      () => {
        uploadLangMedia(++index, callback)
      }
    )
  }

  const savePost = (isPublishNow, isNotify) => {
    let displayStart = post.publishOn[0].getTime() / 1000;
    let displayEnd = post.isNeverExpire ? null : post.expireOn[0].getTime() / 1000;

    let allMedia = [];
    let allImages = [];

    let i18nObj = {}
    each(langTabs, (lang, index) => {
      let curLangObj = post._i18n[lang.key]
      i18nObj[lang.key] = {
        title: trim(curLangObj.title),
        caption: trim(curLangObj.caption),
        html_content: trim(curLangObj.html_content_new),
        media: curLangObj.uploadedMediaSrcArr,
        images: curLangObj.uploadedImageSrcArr,
        button_content: post.isShowPostButton ? curLangObj.button_content : '',
        button_url: post.isShowPostButton ? curLangObj.button_url : ''
      }
      allMedia = [...allMedia, ...curLangObj.uploadedMediaSrcArr]
      allImages = [...allImages, ...curLangObj.uploadedImageSrcArr]

    })

    let defaultLangContent = i18nObj[defaultLang.key]

    let categoryChatIds = post.categories ? [post.categories.category_id] : []

    let newStatus;
    newStatus = isPublishNow ? postStatusScheduled.enum : postStatusDraft.enum

    let linkedObj = post.linkage ? [post.linkage] : null;
    if (linkedObj) {
      delete linkedObj.targetObject;
    }

    let isShowInCalendar = post.isShowInCalendar ? 1 : 0;
    let calendarStart = null
    let calendarEnd = null
    if (isShowInCalendar) {
      calendarStart = post.calendarStart[0].getTime() / 1000;
      calendarEnd = post.calendarEnd[0].getTime() / 1000;
    }

    let isAdvertisement = post.isAdvertisement ? 1 : 0;
    let advertisementStart = null
    let advertisementEnd = null
    if (isAdvertisement) {
      advertisementStart = post.advertisementStart[0].getTime() / 1000;
      advertisementEnd = post.advertisementEnd[0].getTime() / 1000;
    }

    let isPinToTop = post.isPinToTop ? 1 : 0;
    // let isAlwaysPin = post.isAlwaysPin ? 1 : 0;
    let pinToTopStart = null
    let pinToTopEnd = null
    if (isPinToTop) {
      pinToTopStart = post.pinToTopStart[0].getTime() / 1000;
      pinToTopEnd = post.pinToTopEnd[0].getTime() / 1000;
    }

    let thePost = {
      title: defaultLangContent.title,
      caption: defaultLangContent.caption,
      html_content: defaultLangContent.html_content_new,
      button_content: defaultLangContent.button_content,
      button_url: defaultLangContent.button_url,
      _i18n: i18nObj,
      display_start: displayStart,
      display_end: displayEnd,
      media: allMedia,
      images: allImages,
      category_ids: categoryChatIds,
      is_sharable: post.isSharable ? 1 : 0,
      is_notify: isNotify ? 1 : 0,
      is_likable: post.isLikable ? 1 : 0,
      status: newStatus,
      linkage: linkedObj,
      audience: post.audience_new,
      is_show_in_calendar: isShowInCalendar,
      calendar_start: calendarStart,
      calendar_end: calendarEnd,
      is_advertisement: isAdvertisement,
      advertisement_start: advertisementStart,
      advertisement_end: advertisementEnd,
      advertisement_bg_color: post.advertisementBgColor,
      advertisement_text_color: getPostTextColorByBgColor(post.advertisementBgColor),
      is_pin_to_top: isPinToTop,
      pin_to_top_start: pinToTopStart,
      pin_to_top_end: pinToTopEnd,
    }

    let url = '/admin/post/create'
    if (post.post_id) {
      thePost.post_id = post.post_id;
      url = '/admin/post/update'
    }

    apiPost(url, thePost)
      .then((resp) => {
        if (onSaveCallback) {
          onSaveCallback(resp.data.post_id)
          return
        }
        if (resp.data.status === postStatusDraft.enum) {
          postId = resp.data.post_id;
          getPostDetail();
          return;
        }
        history.push(`${basePath}/list`)
      })
  }

  const saveNow = (isDraft, isNotify) => {
    // console.log(post)

    if (!checkInput(isDraft)) {
      return;
    }

    uploadLangMedia(
      0,
      () => {
        uploadLangPic(
          0,
          () => {
            savePost(!isDraft, isNotify)
          }
        )
      }
    )

    // uploadMedia(0, () => uploadPic(0, () => savePost(!isDraft, isNotify)))
  }

  useEffect(() => {
    if (postId) {
      getPostDetail()
    } else {
      getPostCategory()
    }
  }, [])

  // PREVIEW
  const saveDraftNow = () => {
    saveNow(true)
  }
  const [isPostPreviewModalOpen, setIsPostPreviewModalOpen] = useState(false);
  const [isPublishing, setIsPublishing] = useState(false);
  const viewPostPreview = () => {
    setIsPostPreviewModalOpen(true);
  }
  const closePostPreview = () => {
    setIsPublishing(false);
    setIsPostPreviewModalOpen(false);
  }

  const previewPost = () => {
    viewPostPreview();
  }

  const publishNow = async () => {
    if (!await checkInput(false)) {
      return;
    }
    setIsPublishing(true);
    viewPostPreview();
  }
  const confirmPostPublish = (isNotify) => {
    setIsPostPreviewModalOpen(false);
    saveNow(false, isNotify)
  }

  const renderPinPeriod = (post, updatePostInfo) => (
      <>
          <div className="border-bottom"></div>
          <DateInput
              style={{padding: '0px 10px' }}
              value={post.pinToTopStart}
              placeholder="Start Time"
              onChange={(date) => updatePostInfo({ pinToTopStart: date })}
              isShowLabel={true}
          />
          <div className="border-bottom"></div>
          <DateInput
              style={{ padding: '0 10px' }}
              value={post.pinToTopEnd}
              placeholder="End Time"
              onChange={(date) => updatePostInfo({ pinToTopEnd: date })}
              isShowLabel={true}
          />
      </>
  );

  return (
    <div className="item-set-container post-set-container">
      <div className="full-width padding-bottom-30">
        <Card>
          <CardHeader>
            <div className="title">
              {post.post_id ? 'Post Detail' : 'Add New'}
            </div>
            {
                post.update_date &&
                <div>
                  Last updated at: {getFullDateDisplayByTimestampSecond(post.update_date)}
                </div>
            }
          </CardHeader>
        </Card>
        <div className="padding-bottom-30"></div>
        <Card>
          <CardHeader>
            <div className="title sub-title">
              Save & Publish
            </div>
          </CardHeader>
          <CardBody>
            {
                (post.author && post.author.name) &&
                <div className="right-info">
                  <div className="label">
                    Author
                  </div>
                  <div className="info">
                    <div className="text-align-right">
                      {post.author.name}
                    </div>
                  </div>
                </div>
            }
            <div className="right-info">
              <div className="label">
                Status
              </div>
              <div className="info" style={{width: 150}}>
                {/* {
                    !post.isPublished 
                    ?
                    <SelectInput
                      options={postStatusOptions}
                      value={post.status}
                      onChange={(status) => updatePostInfo({status: status})}
                    />
                    : */}
                <div className="text-align-right">
                  {post.originalStatus.name}
                </div>
                {/* } */}

              </div>
            </div>
            <div className="right-info">
              <div className="label">
                Post Date
              </div>
              <div className="info">
                <CheckboxInput
                    value={post.isPublishOnNow}
                    name="Now"
                    onChange={(val) => updatePostInfo({isPublishOnNow: val})}
                />
              </div>
            </div>
            {
                !post.isPublishOnNow &&
                <div className="right-info">
                  <div className="info full-width text-align-right">
                    <DateInput
                        value={post.publishOn}
                        placeholder="Post Date"
                        onChange={(date) => updatePostInfo({publishOn: date})}
                        isAlignRight={true}
                        isHoverBlue={true}
                        minDate={!post.post_id ? new Date() : null}
                    />
                  </div>
                </div>
            }
            <div className="right-info">
              <div className="label">
                Expiry Date
              </div>
              <div className="info">
                <CheckboxInput
                    value={post.isNeverExpire}
                    name="Never"
                    onChange={(val) => updatePostInfo({isNeverExpire: val})}
                />
              </div>
            </div>
            {
                !post.isNeverExpire &&
                <div className="right-info">
                  <div className="info full-width text-align-right">
                    <DateInput
                        value={post.expireOn}
                        placeholder="Expiry Date"
                        onChange={(date) => updatePostInfo({expireOn: date})}
                        isAlignRight={true}
                        isHoverBlue={true}
                        minDate={!post.post_id ? new Date() : null}
                    />
                  </div>
                </div>
            }
            <div className="border-bottom"></div>
            <div className="right-action">
              <button className="button default" onClick={previewPost}>
                <span>Preview</span>
              </button>
              <button className="button info" onClick={saveDraftNow}>
                {
                  post.isPublished
                      ?
                      <span>Recall</span>
                      :
                      <span>Save Draft</span>
                }
              </button>
              <button className="button primary" onClick={publishNow}>
                {
                    post.isPublishOnNow &&
                    <span>Publish</span>
                }
                {
                    !post.isPublishOnNow &&
                    <>
                      {
                          post.isPublished &&
                          <span>Save</span>
                      }
                      {
                          !post.isPublished &&
                          <span>Schduele</span>
                      }
                    </>
                }
              </button>
            </div>
          </CardBody>
        </Card>

        {/* <Card>
          <MultiLangSelector activeLang={activeLang} switchLang={setActiveLang} />
        </Card>*/}
        <div className="padding-bottom-30"></div>

        <Card>
          <CardHeader>
            <div className="title sub-title">
              Feature Image (Max. 5)
            </div>
          </CardHeader>
          <CardBody>
            <MultiLangInputs
                i18n={post._i18n}
                field="localImageObjArr"
                type="feature_image"
                onChange={(i18n) => updatePostInfo({_i18n: i18n})}
                label="Feature Image"
            />
          </CardBody>
        </Card>

        <div className="padding-bottom-30"></div>
        <Card>
          <CardHeader>
            <div className="title sub-title">
              Title
            </div>
          </CardHeader>
          <CardBody>
            <MultiLangInputs
                i18n={post._i18n}
                field="title"
                type="textarea"
                rows="2"
                onChange={(i18n) => updatePostInfo({_i18n: i18n})}
                label="Title"
                limit={postFieldLimit.title}
            />
          </CardBody>
        </Card>
        <div className="padding-bottom-30"></div>
        <Card>
          <CardHeader>
            <div className="title sub-title">
              Caption
            </div>
          </CardHeader>
          <CardBody>
            <MultiLangInputs
                i18n={post._i18n}
                field="caption"
                type="textarea"
                rows="4"
                onChange={(i18n) => updatePostInfo({_i18n: i18n})}
                label="Caption"
                limit={postFieldLimit.caption}
            />
          </CardBody>
        </Card>
        <div className="padding-bottom-30"></div>
        <Card>
          <CardHeader>
            <div className="title sub-title">
              Content
            </div>
          </CardHeader>
          <CardBody>
            <MultiLangInputs
                i18n={post._i18n}
                field="html_content"
                newField="html_content_new"
                type="html"
                onChange={(i18n) => updatePostInfo({_i18n: i18n})}
                label="Content"
            />
          </CardBody>
        </Card>
        <div className="padding-bottom-30"></div>
        <Card>
          <CardHeader>
            <div className="title sub-title">
              Attachments (Max. 5)
            </div>
          </CardHeader>
          <CardBody>
            <MultiLangInputs
                i18n={post._i18n}
                field="localMediaObjArr"
                type="file"
                onChange={(i18n) => updatePostInfo({_i18n: i18n})}
                label="Attachments"
            />
          </CardBody>
        </Card>
        <div className="padding-bottom-30"></div>

        <Card>
          <CardHeader>
            <div className="title sub-title">
              Post Button
            </div>
          </CardHeader>
          <CardBody>
            <div className="checkbox-config">
              <div className="config-name">
                Display Post Button
              </div>
              <div className="checkbox">
                <CheckboxInput value={post.isShowPostButton}
                               onChange={(isShowPostButton) => updatePostInfo({isShowPostButton: isShowPostButton})}/>
              </div>
            </div>
            {
                post.isShowPostButton &&
                <div className="post-button-input-wrapper">
                  <div className="border-bottom"></div>
                  <div className="label">
                    Button Content
                  </div>
                  <MultiLangInputs
                      i18n={post._i18n}
                      field="button_content"
                      type="text"
                      onChange={(i18n) => updatePostInfo({_i18n: i18n})}
                      label="Button Content"
                  />
                  <div className="border-bottom"></div>
                  <div className="label">
                    Button URL
                  </div>
                  <MultiLangInputs
                      i18n={post._i18n}
                      field="button_url"
                      type="text"
                      onChange={(i18n) => updatePostInfo({_i18n: i18n})}
                      label="Button URL"
                  />
                </div>
            }
          </CardBody>
        </Card>

        <div className="padding-bottom-30"></div>

        <TargetAudienceSelector
            value={post.audience}
            onChange={(val) => updatePostInfo({audience_new: val})}
        />
        <div className="padding-bottom-30"></div>
        <Card>
          <CardHeader>
            <div className="title sub-title">
              Interactions
            </div>
          </CardHeader>
          <CardBody>
            <div className="checkbox-config">
              <div className="config-name">
                Allow Like
              </div>
              <div className="checkbox">
                <CheckboxInput value={post.isLikable} onChange={(isLikable) => updatePostInfo({isLikable: isLikable})}/>
              </div>
            </div>
            <div className="checkbox-config">
              <div className="config-name">
                Allow Share
              </div>
              <div className="checkbox">
                <CheckboxInput value={post.isSharable}
                               onChange={(isSharable) => updatePostInfo({isSharable: isSharable})}/>
              </div>
            </div>
          </CardBody>
        </Card>
        <div className="padding-bottom-30"></div>
        <Card>
          <CardHeader>
            <div className="title sub-title">
              Calendar Display
            </div>
          </CardHeader>
          <CardBody>
            <div className="checkbox-config">
              <div className="config-name">
                Display in calendar
              </div>
              <div className="checkbox">
                <CheckboxInput value={post.isShowInCalendar}
                               onChange={(isShowInCalendar) => updatePostInfo({isShowInCalendar: isShowInCalendar})}/>
              </div>
            </div>
            {
                post.isShowInCalendar &&
                <>
                  <div className="border-bottom"></div>
                  <DateInput
                      style={{padding: '0 10px'}}
                      value={post.calendarStart}
                      placeholder="Start Time"
                      onChange={(date) => updatePostInfo({calendarStart: date})}
                      isShowLabel={true}
                  />
                  <div className="border-bottom"></div>
                  <DateInput
                      style={{padding: '0 10px'}}
                      value={post.calendarEnd}
                      placeholder="End Time"
                      onChange={(date) => updatePostInfo({calendarEnd: date})}
                      isShowLabel={true}
                  />
                </>
            }
          </CardBody>
        </Card>
        <div className="padding-bottom-30"></div>
        <Card>
          <CardHeader>
            <div className="title sub-title">
              Advertisement
            </div>
          </CardHeader>
          <CardBody>
            <div className="checkbox-config">
              <div className="config-name">
                Is Advertisement?
              </div>
              <div className="checkbox">
                <CheckboxInput value={post.isAdvertisement}
                               onChange={(isAdvertisement) => updatePostInfo({isAdvertisement: isAdvertisement})}/>
              </div>
            </div>
            {
                post.isAdvertisement &&
                <>
                  <div className="border-bottom"></div>
                  <DateInput
                      style={{padding: '0 10px'}}
                      value={post.advertisementStart}
                      placeholder="Start Time"
                      onChange={(date) => updatePostInfo({advertisementStart: date})}
                      isShowLabel={true}
                  />
                  <div className="border-bottom"></div>
                  <DateInput
                      style={{padding: '0 10px'}}
                      value={post.advertisementEnd}
                      placeholder="End Time"
                      onChange={(date) => updatePostInfo({advertisementEnd: date})}
                      isShowLabel={true}
                  />
                  <div className="border-bottom"></div>
                  <div className="input-container">
                  <span className="input-label">
                    Background Color
                  </span>
                    <div>
                      <RadioList
                          data={PostAdvertisementColors}
                          selected={post.advertisementBgColor}
                          onChange={(items) => {
                            updatePostInfo({advertisementBgColor: items})
                          }}
                          isColor
                      />
                    </div>
                  </div>
                </>
            }
          </CardBody>
        </Card>

        <div className="padding-bottom-30"></div>
        <Card>
          <CardHeader>
            <div className="title sub-title">
              Categories
            </div>
          </CardHeader>
          <CardBody>
            <RadioList
                data={categoryList}
                selected={post.categories}
                onChange={(items) => {
                  updatePostInfo({categories: items})
                }}
            />
          </CardBody>
        </Card>

        <div className="padding-bottom-30"></div>

        <Card>
          <CardHeader>
            <div className="title sub-title">
              Pin To Top
            </div>
          </CardHeader>
          <CardBody>
            <div className="checkbox-config">
              <div className="config-name">
                Is Pin To Top?
              </div>
              <div className="checkbox">
                <CheckboxInput value={post.isPinToTop}
                               onChange={(isPinToTop) => updatePostInfo({isPinToTop: isPinToTop})}/>
              </div>
            </div>
            {post.isPinToTop && renderPinPeriod(post, updatePostInfo)}
          </CardBody>
        </Card>

        <div className="padding-bottom-30"></div>
        <LinkageSelector
            value={post.linkage}
            isDisabled={preselectedLinkage}
            onChange={(linkage) => {
              updatePostInfo({linkage: linkage})
            }}
            types={[typeEvent, typeSurvey, typePromotion]}
        />
      </div>

      <Dialog
          open={isPostPreviewModalOpen}
        onClose={closePostPreview}
        fullWidth={true}
        maxWidth="lg"
      >
        <PostPreview post={post} isPublishing={isPublishing} handleClose={closePostPreview} confirmPostPublish={confirmPostPublish} />
      </Dialog>
    </div>
  )
}

PostSet.propTypes = {

}

const mapStateToProps = (state) => ({
  
})

const mapDispatchToProps = {
  
}

export default connect(mapStateToProps, mapDispatchToProps)(PostSet)
