import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import Card from '../../utilities/card/Card'
import CardHeader from '../../utilities/card/CardHeader'
import RangeIntervalFilter from './RangeIntervalFilter'
import CardBody from '../../utilities/card/CardBody'
import { apiPost } from '../../../helpers/Api'
import ChartLine from '../../utilities/chart/ChartLine'
import map from 'lodash/map'
import each from 'lodash/each'
import { getSimpleDateDisplayByTimestampSecond, getChartMonthDateDisplayByTimestampSecond, getDateByFormatAndDateObj, getExportFileNameDisplay } from '../../../helpers/DateHelper'
import { exportJsonArrToExcel } from '../../../helpers/ExcelExportHelper'
import union from 'lodash/union'
import SelectInput from '../../utilities/form/SelectInput'
import find from 'lodash/find'

function WebviewLogTab(props) {
  
  const [currentParam, setCurrentParam] = useState(null)

  const [stat, setStat] = useState([])
  const [allUrl, setAllUrl] = useState([])
  const [curUrl, setCurUrl] = useState(null)
  

  const getWebviewStat = (param) => {
    setCurrentParam(param)

    apiPost('/admin/hkaa_community/stat/webview_log', param)
      .then(({data}) => {
        let urls = [];

        each(data, (d) => {
          d.date = getSimpleDateDisplayByTimestampSecond(d.start_time)
          if (param.time_interval === 'month') {
            d.date = getChartMonthDateDisplayByTimestampSecond(d.start_time)
          }

          each(d, (value, key) => {
            if (
              key !== 'start_time' && 
              key !== 'end_time' && 
              key !== 'date' &&
              !find(urls, {label: key})
            ) {
              urls = union(urls, [{
                label: key,
                value: key
              }]) 
            }
          })

          // d['No. of search'] = d.no_of_search
        })

        setStat(data)
        setAllUrl(urls)
      })
  }

  const exportWebviewStat = (param) => {
    if (!stat || stat.length === 0) {
      return;
    } 
    let data = []

    if (curUrl) {
      let row = {}
      row["URL"] = curUrl.label
      each(stat, (interval) => {
        row[interval.date] = interval[curUrl.label] || 0
      })
      data.push(row)
    } else {
      each(allUrl, (url) => {
        let row = {}
        row["URL"] = url.label
        each(stat, (interval) => {
          row[interval.date] = interval[url.label] || 0
        })
        data.push(row)
      })
    }

    exportJsonArrToExcel(
      {
        "URL Click Rate": data
      },
      `stat_url_clickrate_${getExportFileNameDisplay()}.xlsx`,
      "xlsx"
    )
  }

  const [curUrlStat, setCurUrlStat] = useState([])
  const parseCurUrlStat = () => {
    let chart = []
    each(stat, (monthStat) => {
      chart.push({
        date: monthStat.date,
        'Click Rate': monthStat[curUrl.label] || 0
      })
    })
    setCurUrlStat(chart)
  }


  useEffect(() => {
    parseCurUrlStat()
  },[curUrl])


  return (
    <div>
      <Card>
        <CardHeader>
          <div className="title sub-title">
            URL Click Rate
          </div>
        </CardHeader>
        <RangeIntervalFilter 
          submitParams={(param) => getWebviewStat(param)}
          exportParams={(param) => exportWebviewStat(param)}
          isFullYear
        />
        <CardBody>
          {
            currentParam
            ?
            <>
              {
                allUrl.length > 0
                ?
                <>
                  <SelectInput 
                    options={allUrl}
                    value={curUrl}
                    onChange={setCurUrl}
                  />

                  {
                    curUrl 
                    ?
                    <ChartLine 
                      data={curUrlStat}
                      xKey='date'
                      yKey='Click Rate'
                      chartTitle={`URL Click Rate (${curUrl.label})`}
                      isShowLegend
                      isAllowSave
                    />
                    :
                    <div className="center-info-msg">
                      Please select URL
                    </div>
                  }

                </>
                :
                <div className="center-info-msg">
                  No data
                </div>
              }
            </>
            :
            <div className="center-info-msg">
              Please select Time Range and Interval, and click Get Statistics.
            </div>
          }
        </CardBody>
      </Card>
    </div>
  )
}

WebviewLogTab.propTypes = {

}

export default WebviewLogTab

