import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import Card from '../../../utilities/card/Card';
import CardHeader from '../../../utilities/card/CardHeader';
import CardBody from '../../../utilities/card/CardBody';
import BasicInput from '../../../utilities/form/BasicInput';
import TextAreaInput from '../../../utilities/form/TextAreaInput';
import { toast } from '../../../utilities/Notification/CustomToast';

import each from 'lodash/each'
import trim from 'lodash/trim'
import { langTabs, defaultLang } from '../../../utilities/form/MultiLangSelector';
import MultiLangInputs from '../../../utilities/form/MultiLangInputs';
import { apiPost } from '../../../../helpers/Api';


function JargonBusterSet(props) {
  const {
    match,
    basePath,
    history
  } = props;

  const jargonBusterId = match.params.id;

  let i18nDefaultObj = {};
  each(langTabs, (lang) => {
    i18nDefaultObj[lang.key] = {
      name: '',
      description: ''
    }
  })

  const defaultJargonBusterObj = {
    name: '',
    description: '',
    _i18n: i18nDefaultObj,
  }

  const [jargonBuster, setJargonBuster] = useState(defaultJargonBusterObj)

  const updateJargonBusterInfo = (update) => {
    setJargonBuster({
      ...jargonBuster,
      ...update
    })
  }

  const getJargonBusterDetail = () => {
    let param = {
      item_id: jargonBusterId
    }
    apiPost('/admin/hkaa_community/jargon_buster', param)
      .then((resp) => {
        let jargonBuster = resp.data;

        let i18n = {};
        each(langTabs, (lang, index) => {
          i18n[lang.key] = {
            name: jargonBuster.name,
            description: jargonBuster.description,
          }
          if (jargonBuster._i18n[lang.key]) {
            i18n[lang.key] = {
              ...i18n[lang.key],
              ...jargonBuster._i18n[lang.key],
            }
          }
        })
        
        jargonBuster._i18n = {...i18nDefaultObj, ...i18n}
        
        updateJargonBusterInfo(jargonBuster)
      })
  }

  const saveJargonBuster = () => {
    let defaultLangContent = jargonBuster._i18n[defaultLang.key];

    let i18nObj = {}
    each(langTabs, (lang, index) => {
      i18nObj[lang.key] = {
        name: trim(jargonBuster._i18n[lang.key].name),
        description: trim(jargonBuster._i18n[lang.key].description),
      }
    })

    let theJargonBuster = {
      name: defaultLangContent.name,
      description: defaultLangContent.description,
      _i18n: i18nObj
    }

    if (jargonBuster.item_id) {
      theJargonBuster.item_id = jargonBuster.item_id
    }

    let url = '/admin/hkaa_community/jargon_buster/set'
    apiPost(url, theJargonBuster)
      .then((resp) => {
        history.push(`${basePath}/list`)
      })
      .catch((err) => {
        console.error(err);
      })
  }

  const checkInput = () => {
    for (let i = 0; i < langTabs.length; i++) {
      let lang = langTabs[i];
      if (!jargonBuster._i18n[lang.key].name) {
        toast.warn("Please enter company name for all languages");
        return false;
      }
      if (!jargonBuster._i18n[lang.key].description) {
        toast.warn("Please enter description for all languages");
        return false;
      }
    }


    return true;
  }

  const saveNow = () => {
    if (!checkInput()) {
      return;
    }
    saveJargonBuster()
  }
  
  const backNow = () => {
    history.push(`${basePath}/list`)
  }

  useEffect(() => {
    if (jargonBusterId) {
      getJargonBusterDetail()
    }
  }, [])

  return (
    <div className="item-set-container">
      <div className="full-width padding-bottom-30">
        <Card>
          <CardHeader>
            <div className="title">
              {jargonBuster.item_id ? 'Jargon Buster Detail' : 'Add New'}
            </div>
            <div className="actions">
              <button className="button default" onClick={backNow}>
                <span>Back</span>
              </button>
              <button className="button primary" onClick={saveNow}>
                <span>Save</span>
              </button>
            </div>
          </CardHeader>
        </Card>
        <div className="padding-bottom-30"></div>
        <Card>
          <CardHeader>
            <div className="title sub-title">
              Name
            </div>
          </CardHeader>
          <CardBody>
            <MultiLangInputs
              i18n={jargonBuster._i18n}
              field="name"
              type="text"
              rows={3}
              onChange={(i18n) => updateJargonBusterInfo({_i18n: i18n})}
              label="Name"
            />
          </CardBody>
        </Card>
        <div className="padding-bottom-30"></div>
        <Card>
          <CardHeader>
            <div className="title sub-title">
              Description
            </div>
          </CardHeader>
          <CardBody>
            <MultiLangInputs
              i18n={jargonBuster._i18n}
              field="description"
              type="textarea"
              rows={3}
              onChange={(i18n) => updateJargonBusterInfo({_i18n: i18n})}
              label="Description"
            />
          </CardBody>
        </Card>
      </div>
    </div>
  )
}

JargonBusterSet.propTypes = {

}

export default JargonBusterSet

