import React from 'react'
import PropTypes from 'prop-types'
import BasicInput from '../../../../utilities/form/BasicInput'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Sortable from 'react-sortablejs/lib/Sortable';

function RatingQuestion(props) {
  const {
    question,
    onChange,
  } = props;

  const onRatingDetailChange = (update) => {
    onChange({...question, ...update})
  }

  const callOnChange = () => {
    onRatingDetailChange({sections: question.sections})
  }

  const updateQuestionSection = (index, value) => {
    question.sections[index] = {
      ...question.sections[index],
      ...{name: value}
    }
    callOnChange()
  }

  const addSection = () => {
    question.sections.push({
      name: ''
    })
    callOnChange()
  }

  const removeSection = (index) => {
    question.sections.splice(index, 1);
    callOnChange()
  }

  const dragReOrder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
  
    return result;
  };

  const onDragEnd = (order, sortable, event) => {
    question.sections = dragReOrder(question.sections, event.oldIndex, event.newIndex);
    callOnChange()
  }

  return (
    <div className="rating-question">
      <BasicInput
        type="number"
        value={question.maxStar}
        label="Max # of Stars"
        onChange={(val) => {
          onRatingDetailChange({maxStar: val})
        }}
      />
      <BasicInput
        type="text"
        value={question.oneStarCaption}
        label="1 Star Caption"
        onChange={(val) => {
          onRatingDetailChange({oneStarCaption: val})
        }}
      />
      <BasicInput
        type="text"
        value={question.maxStarCaption}
        label="Max Star Caption"
        onChange={(val) => {
          onRatingDetailChange({maxStarCaption: val})
        }}
      />
      <div className="option-add text-align-right">
        <span className="clickable hover-opacity" onClick={addSection}>
          Add New Section
          <FontAwesomeIcon icon="plus" fixedWidth />
        </span>
      </div>
      <div className="border-bottom"></div>

      <Sortable
        options={{
          hanlde: '.sort-handle'
        }}
        onChange={onDragEnd}
      >
        {
          question.sections.map((section, index) => (
            <div className="option-wrapper" key={index}>
              <div className="sort-handle">
                <FontAwesomeIcon icon="bars" fixedWidth />
              </div>
              <div className="option-input">
                <BasicInput 
                  key={index}
                  type="text" 
                  value={section.name}
                  label={`Section ${index+1}`}
                  onChange={(val) => {
                    updateQuestionSection(index, val)
                  }}
                />
              </div>
              <FontAwesomeIcon className="clickable" icon="times" fixedWidth  onClick={() => removeSection(index)} />
            </div>
          ))
        }
      </Sortable>
    </div>
  )
}

RatingQuestion.propTypes = {

}

export default RatingQuestion

