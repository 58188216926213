import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import Card from '../../utilities/card/Card';
import CardHeader from '../../utilities/card/CardHeader';
import CardBody from '../../utilities/card/CardBody';
import SimpleTable from '../../utilities/table/SimpleTable';
import { apiPost, apiExportFile } from '../../../helpers/Api';
import each from 'lodash/each';
import groupBy from 'lodash/groupBy';
import sumBy from 'lodash/sumBy';
import map from 'lodash/map';
import filter from 'lodash/filter';
import { postStatusTrash, postStatusDraft, postStatusEnum, PostStatItems, PostStatItemFull } from '../../../helpers/PostHelper';
import List from '../../utilities/list/List';
import tail from 'lodash/tail';
import { subYears, startOfMonth, endOfMonth, addMonths } from 'date-fns';
import { getTimestampSecRangeForApi, getSimpleDateDisplayByDateObj, getDateByFormatAndDateObj, getDateByFormat, getExportFileNameDate, checkIfTimesInCorrectOrder, getExportFileNameDisplay } from '../../../helpers/DateHelper';
import Tab from '../../utilities/tab/Tab';
import union from 'lodash/union';
import SelectInput from '../../utilities/form/SelectInput';
import ChartLine from '../../utilities/chart/ChartLine';
import CheckboxList from '../../utilities/form/CheckboxList';
import ChartBar from '../../utilities/chart/ChartBar';
import DateRangeInput from '../../utilities/form/DateRangeInput'
import { exportJsonArrToExcel } from '../../../helpers/ExcelExportHelper';
import RangeIntervalFilter from './RangeIntervalFilter';

const PostStatTabKey = {
  summary: 'summary',
  summaryCategory: 'summaryCategory',
  category: 'category',
  data: 'data'
}

const PostStatTabs = [
  // {
  //   key: PostStatTabKey.summary,
  //   name: 'Summary'
  // },
  {
    key: PostStatTabKey.summaryCategory,
    name: 'Summary (By Category)'
  },
  {
    key: PostStatTabKey.category,
    name: 'Monthly (By Category)'
  },
  {
    key: PostStatTabKey.data,
    name: 'Monthly (By Statictics)'
  }
]


function PostStatTab(props) {
  const {
    history
  } = props;

  const rangeStart = subYears(startOfMonth(addMonths(new Date(), 1)), 1)
  const rangeEnd = endOfMonth(new Date())
  const [range, setRange] = useState([rangeStart, rangeEnd])

  const getDateLabelArr = () => {
    const allDataLabels = []
    let curPointer = range[0];
    while (checkIfTimesInCorrectOrder(curPointer, range[1])) {
      allDataLabels.push(getDateByFormatAndDateObj(curPointer, 'MMM yyyy'))
      curPointer = addMonths(curPointer, 1)
    }
    return allDataLabels;
  }
  
  const [stat, setStat] = useState([])

  const [activeTab, settActiveTab] = useState(PostStatTabs[0])

  const [fullStat, setFullStat] = useState([])
  const [fullCatStat, setFullCatStat] = useState({})
  const [fullDataStat, setFullDataStat] = useState({})

  const CATEGORY_ALL = {value: 'all', label: "All Categories"};
  const [allCategory, setAllCategory] = useState([])
  const [curCategory, setCurCategory] = useState(CATEGORY_ALL)

  const [allStat, setAllStat] = useState(PostStatItemFull)
  const [curStat, setCurStat] = useState(PostStatItemFull[0])

  const [isDisplayChart, setIsDisplayChart] = useState(true)

  const getPostCategoryInfo = (post, field) => {
    let category = 'No Category'
    
    if (post.categories.length > 0) {
      return post.categories[0][field]
    }

    return category
  }

  const getGeneralPostStat = (param) => {
    apiPost('/admin/post/stats', {
      display_start: [param.start_time, param.end_time]
    })
      .then(({data}) => {
        let activePosts = []
        each(data, (post) => {
          if (post.status !== postStatusTrash.enum && post.status !== postStatusDraft.enum) {
            post.statusDisplay = postStatusEnum[post.status]
            post.categoryName = getPostCategoryInfo(post, 'name')
            post.categoryId = getPostCategoryInfo(post, 'category_id')

            post.dateLabel = getDateByFormat(post.display_start, 'MMM yyyy')
            activePosts.push(post)
          }
        })

        setFullStat(activePosts);

        // summary

        let groupedPosts = groupBy(activePosts, 'categoryName')

        let parsedStat = [];
        each(groupedPosts, (posts, categoryName) => {
          let catData = {}
          catData.name = categoryName;
          catData['Post'] = posts.length;
          each(PostStatItems, (item) => {
            catData[item.name] = sumBy(posts, item.key)
          })
          parsedStat.push(catData)
        })

        parsedStat.sort((a, b) => {
          return a.name.toLowerCase().localeCompare(b.name.toLowerCase())
        })

        let allCategoryArr = map(parsedStat, (group) => {
          return {value: group.name, label: group.name}
        });

        // set category list
        setAllCategory(union(
          [CATEGORY_ALL], 
          allCategoryArr
        ));
        setStat(parsedStat)

        // prepare monthly stats by cat
        let allCatStat = {}

        let months = getDateLabelArr();

        const getMonthlyStatObjByPosts = (posts, catLabel) => {
          let postsByMonth = groupBy(posts, 'dateLabel')
          let allMonthlyStat = {}

          each(months, (monthLabel) => {
            let postsOfMonth = postsByMonth[monthLabel]

            let statObj = {
              date: monthLabel,
              categoryName: catLabel,
              'New post': postsOfMonth ? postsOfMonth.length : 0
            }

            each(PostStatItems, (item) => {
              statObj[item.name] = sumBy(postsOfMonth, item.key) || 0
            })

            allMonthlyStat[monthLabel] = statObj

          })
          return allMonthlyStat
        }

        // all cat
        allCatStat[CATEGORY_ALL.label] = getMonthlyStatObjByPosts(activePosts, CATEGORY_ALL.label);

        // individual cat
        let groupedByCategory = groupBy(activePosts, 'categoryName')
        each(allCategoryArr, (cat) => {
          let postOfCategory = groupedByCategory[cat.label]

          allCatStat[cat.label] = getMonthlyStatObjByPosts(postOfCategory, cat.label)
        })
        setFullCatStat(allCatStat)


        // prepare monthly stat by data
        let allDataStat = {}
        let groupedByMonth = groupBy(activePosts, 'dateLabel')

        each(months, (monthLabel) => {
          let postOfMonth = groupedByMonth[monthLabel]

          let statOfMonth = {
            date: monthLabel
          }

          // group by category name
          let groupedByCategory = groupBy(postOfMonth, 'categoryName')
          each(allCategoryArr, (category) => {
            let postOfCat = groupedByCategory[category.label]

            let catStat = {
              'New post': postOfCat ? postOfCat.length : 0
            }
            each(PostStatItems, (item) => {
              catStat[item.label] = sumBy(postOfCat, item.value)
            })

            statOfMonth[category.label] = catStat
          })
          allDataStat[monthLabel] = statOfMonth
        })
        console.log(allDataStat)
        setFullDataStat(allDataStat)
      })
  }
  const [catChartData, setCatChartData] = useState([])

  const getCategoryChartData = () => {
    let months = getDateLabelArr();

    let filtered = fullStat;

    // filter category
    if (curCategory.value !== 'all') {
      filtered = filter(filtered, {categoryName: curCategory.value})
    }

    // group by month
    let groupedByMonth = groupBy(filtered, 'dateLabel')

    let parsedChartData = []

    each(months, (monthLabel) => {
      // sum all stat for each month
      let postOfMonth = groupedByMonth[monthLabel]

      let statOfMonth = {
        date: monthLabel,
        'New post': 0,
      }

      each(PostStatItems, (item) => {
        statOfMonth[item.name] = 0
      })

      if (postOfMonth && postOfMonth.length > 0) {
        statOfMonth['New post'] = postOfMonth.length;

        each(PostStatItems, (item) => {
          statOfMonth[item.name] = sumBy(postOfMonth, item.key)
        })
      }
      
      parsedChartData.push(statOfMonth)
    })
    
    setCatChartData(parsedChartData)
  }

  const [statChartData, setStatChartData] = useState([])
  const getStatChartData = () => {
    let months = getDateLabelArr();

    let filtered = fullStat;

    // group by month
    let groupedByMonth = groupBy(filtered, 'dateLabel')

    let parsedChartData = []

    each(months, (monthLabel) => {
      let postOfMonth = groupedByMonth[monthLabel]

      let statOfMonth = {
        date: monthLabel
      }

      // group by category name
      let groupedByCategory = groupBy(postOfMonth, 'categoryName')

      each(allCategory, (category) => {
        let postOfCat = groupedByCategory[category.label]
        statOfMonth[category.label] = 0;

        if (postOfCat) {
          if (curStat.value === 'post') {
            statOfMonth[category.label] = postOfCat.length;
          } else {
            statOfMonth[category.label] = sumBy(postOfCat, curStat.value)
          }
        }
      })

      parsedChartData.push(statOfMonth)
    })

    setStatChartData(parsedChartData)
  }


  const exportPostStat = () => {

    let sheets = {}
    let months = getDateLabelArr();

    // by cat
    const getDataOfSheet = (catStat) => {
      let data = []
      each(months, (monthLabel) => {
        let row = {}
        row["Date"] = monthLabel
        row["New post"] = catStat[monthLabel]["New post"]
        each(PostStatItems, (item) => {
          row[item.label] = catStat[monthLabel][item.label]
        })
        data.push(row)
      })
      return data
    }

    sheets[CATEGORY_ALL.label] = getDataOfSheet(fullCatStat[CATEGORY_ALL.label])
    each(allCategory, (cat) => {
      sheets[cat.label] = getDataOfSheet(fullCatStat[cat.label])
    })

    // by data
    const getDataOfSheetByDataKey = (dataStat, key) => {
      let data = []
      each(months, (monthLabel) => {
        let row = {}
        row["Date"] = monthLabel
        map(tail(allCategory), (c) => {
          row[c.label] = dataStat[monthLabel][c.label][key]
        })
        data.push(row)
      })
      return data
    }

    sheets['Monthly (New post)'] = getDataOfSheetByDataKey(fullDataStat, 'New post')
    each(PostStatItems, (item) => {
      sheets[`Monthly (${item.label})`] = getDataOfSheetByDataKey(fullDataStat, item.label)
    })

    exportJsonArrToExcel(
      sheets,
      `stat_post_${getExportFileNameDisplay()}.xlsx`,
      "xlsx"
    )
  }



  const exportViewingInfo = () => {

    exportPostStat()
    return;
  }

  useEffect(() => {
    getCategoryChartData()
  }, [curCategory])

  useEffect(() => {
    getStatChartData()
  }, [allCategory, curStat])

  useEffect(() => {
    getCategoryChartData()
    getStatChartData()
  }, [fullStat])

  return (
    <div>
      <Card>
        <CardHeader>
          <div className="title sub-title">
            Post
          </div>
          <div className="actions">
            {/* <DateRangeInput 
              value={range}
              onChange={setRange}
              className="border"
            /> */}
            <button className="button info" onClick={() => history.push('/portal/post/dashboard')}>
              <a>Post Dashboard</a>
            </button>
          </div>
        </CardHeader>
        <RangeIntervalFilter 
          submitParams={(param) => getGeneralPostStat(param)}
          isFullYear
          isDisableInterval
          isDisableExport
        />
        {
          fullStat.length > 0
          ?
          <>
            <CardHeader>
              <div>
                <Tab tabs={PostStatTabs} activeTab={activeTab} switchActiveTab={settActiveTab} />
              </div>
              <div className="actions">
                <button className={`button ${isDisplayChart ? 'primary' : 'default'}`}
                  onClick={() => setIsDisplayChart(true)}
                >
                  <a>Chart</a>
                </button>
                <button className={`button ${!isDisplayChart ? 'primary' : 'default'}`} 
                  onClick={() => setIsDisplayChart(false)}
                >
                  <a>Table</a>
                </button>
                <button className="button info" onClick={exportViewingInfo}>
                  <a>Export</a>
                </button>
              </div>
            </CardHeader>
            
            <CardBody>
              {
                activeTab.key === PostStatTabKey.summaryCategory &&
                <div>
                  {
                    isDisplayChart 
                    ?
                    <ChartBar 
                      data={stat}
                      xKey='name'
                      yKeys={union(
                        ['Post'], 
                        map(PostStatItems, 'label')
                      )}
                      isShowLegend
                      isAllowSave
                      chartTitle={`Post Statistics Summary ${getDateByFormatAndDateObj(range[0], 'MMM yyyy')} - ${getDateByFormatAndDateObj(range[1], 'MMM yyyy')}`}
                    />
                    :
                    <>
                      <div className="text-center text-header">
                        {`Post Statistics Summary ${getDateByFormatAndDateObj(range[0], 'MMM yyyy')} - ${getDateByFormatAndDateObj(range[1], 'MMM yyyy')}`}
                      </div>
                      <div style={{height: 500}}>
                        <List 
                          rowData={stat}
                          headerOptions={{enable: false}}
                          gridOptions={{
                            suppressPaginationPanel: true,
                            minColWidth: 10,
                            columnDefs: union(
                              [
                                {
                                  headerName: 'Name', 
                                  field: 'name', 
                                  pinned: 'left', 
                                  // minWidth: 200
                                },
                                {
                                  headerName: 'Post', 
                                  field: 'Post', 
                                  // width: 80, 
                                  // minWidth: 80, 
                                  // maxWidth: 80
                                }
                              ],
                              map(PostStatItems, (item) => {
                                return {
                                  headerName: item.label,
                                  field: item.label,
                                  // width: item.width,
                                  // minWidth: item.width,
                                  // maxWidth: item.width
                                }
                              })
                            )
                          }}
                          isAllowSave
                          sheetName={`Summary (By Category)`}
                          fileName={`stat_post_summary_${getExportFileNameDisplay()}.xlsx`}
                        />
                      </div>
                    </>
                  }
                </div>
              }

              {
                activeTab.key === PostStatTabKey.category &&
                <div>
                  <div className="flex-align-center">
                    <div className="full-width">
                      Category
                      <SelectInput options={allCategory} value={curCategory} onChange={setCurCategory} />
                    </div>
                  </div>
                  <br></br>
                  {
                    isDisplayChart
                    ?
                    <ChartLine 
                      data={catChartData}
                      xKey='date'
                      yKeys={map(PostStatItemFull, 'name')}
                      isShowLegend={true}
                      isAllowSave
                      chartTitle={`Post Statistics (${curCategory.label}) ${getDateByFormatAndDateObj(range[0], 'MMM yyyy')} - ${getDateByFormatAndDateObj(range[1], 'MMM yyyy')}`}
                    />
                    :
                    <>
                      <div className="text-center text-header">
                        {`Post Statistics (${curCategory.label}) ${getDateByFormatAndDateObj(range[0], 'MMM yyyy')} - ${getDateByFormatAndDateObj(range[1], 'MMM yyyy')}`}
                      </div>
                      <div style={{height: 500}}>
                        <List 
                          rowData={catChartData}
                          headerOptions={{enable: false}}
                          gridOptions={{
                            suppressPaginationPanel: true,
                            minColWidth: 10,
                            columnDefs: union(
                              [{headerName: 'Date', field: 'date', pinned: 'left', sortable: false},
                              {headerName: 'New post', field: 'New post'}],
                              map(PostStatItems, (item) => {
                                return {
                                  headerName: item.label,
                                  field: item.label,
                                }
                              })
                            )
                          }}
                          isAllowSave
                          sheetName={`Monthly (${curCategory.label})`}
                          fileName={`stat_post_monthly_category_${getExportFileNameDisplay()}.xlsx`}
                        />
                      </div>
                    </>
                  }

                  
                </div>
              }
              
              {
                activeTab.key === PostStatTabKey.data &&
                <div>
                  <div className="flex-align-center">
                    <div className="full-width">
                      Statistics (No. of)
                      <SelectInput options={allStat} value={curStat} onChange={setCurStat} />
                    </div>
                  </div>
                  <br></br>
                  {
                    isDisplayChart
                    ?
                    <ChartLine 
                      data={statChartData}
                      xKey='date'
                      yKeys={map(tail(allCategory), 'label')}
                      isShowLegend={true}
                      isAllowSave
                      chartTitle={`Post Statistics (${curStat.label}) ${getDateByFormatAndDateObj(range[0], 'MMM yyyy')} - ${getDateByFormatAndDateObj(range[1], 'MMM yyyy')}`}
                    />
                    :
                    <>
                      <div className="text-center text-header">
                        {`Post Statistics (${curStat.label}) ${getDateByFormatAndDateObj(range[0], 'MMM yyyy')} - ${getDateByFormatAndDateObj(range[1], 'MMM yyyy')}`}
                      </div>
                      <div style={{height: 500}}>
                        <List 
                          rowData={statChartData}
                          headerOptions={{enable: false}}
                          gridOptions={{
                            suppressPaginationPanel: true,
                            columnDefs: union(
                              [{headerName: 'Date', field: 'date', pinned: 'left', sortable: false}],
                              map(tail(allCategory), (c) => {
                                return {
                                  headerName: c.label,
                                  field: c.label,
                                }
                              })
                            )
                          }}
                          isAllowSave
                          sheetName={`Monthly (${curStat.label})`}
                          fileName={`stat_post_monthly_category_${getExportFileNameDisplay()}.xlsx`}
                        />
                      </div>
                    </>
                  }
                  

                </div>
              }
            </CardBody>
          </>
          :
          <div className="center-info-msg">
            Please select Time Range, and click Get Statistics.
          </div>
        }
      </Card>
    </div>
  )
}

PostStatTab.propTypes = {

}

export default PostStatTab

