import React from 'react'
import PropTypes from 'prop-types'
import { TargetHost, StaticPath } from '../../../helpers/Constants';

function ImageRenderer(props) {
  const { value, isIcon } = props;

  let imgSrc = '';

  if (value && value.toLowerCase() !== 'none') {
    imgSrc = `${TargetHost}${StaticPath}${value}`
  }

  let className = ["image-wrapper"];
  if (isIcon) {
    className.push("icon")
  }

  if (!imgSrc) {
    return null
  }

  return (
    <div>
      <div className={className.join(' ')}>
        <div className="image" style={{backgroundImage: `url(${imgSrc})`}} />
      </div>
    </div>
  )
}

ImageRenderer.propTypes = {

}

export default ImageRenderer

