import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

import ToolsRenderer from '../../../utilities/list/ToolsRenderer';
import { searchFilter } from '../../../../helpers/SearchHelper';
import { apiPost } from '../../../../helpers/Api';
import Card from '../../../utilities/card/Card';
import CardHeader from '../../../utilities/card/CardHeader';
import List from '../../../utilities/list/List';
import DateRenderer from '../../../utilities/list/DateRenderer';


function UserGroupList(props) {
  const {match, basePath, history} = props;
  let rows = [];

  const [originalData, setOriginalData] = useState([]);
  const [listKeyword, setListKeyword] = useState('');
  const [listFilter, setListFilter] = useState(null);
  
  const [headerOptions, setHeaderOptions] = useState({
    enable: true,
    isSearch: true,
    searchableFields: ['name'],
    onHeaderChange: (keyword, filter) => {
      setListKeyword(keyword);
      setListFilter(filter);
    }
  })

  const columns = [
    {
      headerName: 'Group Title',
      field: 'name',
      // sort: 'asc'
    },
    // {
    //   headerName: 'Members',
    //   field: 'member',
    // },
    {
      headerName: 'Created Date',
      field: 'create_date',
      cellRenderer: 'dateRenderer',
      sort: 'desc'
    },
    {
      headerName: 'Tools',
      field: 'user_group_id',
      pinned: "right",
      width: 100,
      minWidth: 100,
      maxWidth: 100,
      sortable: false,
      cellRenderer: 'toolsRenderer',
      cellRendererParams: {
        onClickCallback: {
          edit: (row) => {
            history.push(`${basePath}/edit/${row.user_group_id}`);
          },
          remove: (row) => {
            deleteUserGroup(row);
          }
        },
        types: ["edit", "remove"]
      }
    }
  ]

  const [gridOptions, setGridOptions] = useState({
    columnDefs: columns,
    frameworkComponents: {
      toolsRenderer: ToolsRenderer,
      dateRenderer: DateRenderer
    }
  });

  const [rowData, setRowData] = useState([...originalData]);

  const search = () => {
    let filtered = originalData;


    if (listKeyword) {
      filtered = searchFilter(filtered, headerOptions.searchableFields, listKeyword);
    }

    setRowData(filtered);
  }

  const getUserGroupList = () => {
    let param = {

    }

    apiPost('/admin/usergroup', param)
      .then((resp) => {
        let ugs = resp.data;

        // TODO: if needed

        setOriginalData(ugs)
      })
  }

  const deleteUserGroup = (usergroup) => {
    let param = {
      user_group_id: usergroup.user_group_id
    }
    apiPost('admin/usergroup/delete', param)
      .then((resp) => {
        history.push(match.url);
      })
      .catch((err) => {
        console.error(err)
      })
  }

  useEffect(() => {
    getUserGroupList();
  }, []);

  useEffect(() => {
    search()
  }, [originalData])


  useEffect(() => {
    search()
  }, [listKeyword, listFilter])

  return (
    <div className="list-container">
      <div className="header">
        <Card>
          <CardHeader>
            <div className="title">User Group</div>
            <div className="actions">
              <button 
                className="button default"
                onClick={() => history.push(`${basePath}/add`)}
              >
                <a>Add New</a>
              </button>
            </div>
          </CardHeader>
        </Card>
      </div>
      <div className="body">
        <List
          headerOptions={headerOptions}
          gridOptions={gridOptions}
          rowData={rowData}
        />
      </div>
    </div>
  )
}

UserGroupList.propTypes = {

}

export default UserGroupList

