import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import CircularProgress from '@material-ui/core/CircularProgress';

import "./LoadingOverlay.scss"


function LoadingOverlay(props) {
  const {
    isLoadingApi
  } = props;

  let className = ["loading-overlay"];
  if (isLoadingApi) {
    className.push("open");
  }
  
  return (
    <div className={className.join(" ")}>
      <CircularProgress size={100} />
    </div>
  )
}

LoadingOverlay.propTypes = {

}

const mapStateToProps = (state) => ({
  isLoadingApi: state.loading.isLoadingApi
})

const mapDispatchToProps = {
  
}


export default connect(mapStateToProps, mapDispatchToProps)(LoadingOverlay)

