import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { apiPost } from '../../../../helpers/Api';
import Card from '../../../utilities/card/Card';
import CardHeader from '../../../utilities/card/CardHeader';
import CardBody from '../../../utilities/card/CardBody';
import BasicInput from '../../../utilities/form/BasicInput';
import { toast } from '../../../utilities/Notification/CustomToast';

function AdminSet(props) {
  const { match, basePath, history } = props;

  let id = match.params.id;

  const defaultAdminObj = {
    user_name: '',
    name: '',
    department_name: ''
  }

  const [admin, setAdmin] = useState(defaultAdminObj)

  const updateAdminInfo = (update) => {
    setAdmin({
      ...admin,
      ...update
    })
  }

  const getAdminDetail = () => {
    let param = {
      user_id: id
    }
    apiPost('', param)
      .then((resp) => {
        let admin = resp.data;


        updateAdminInfo({
          ...defaultAdminObj,
          ...admin
        })
      })
  }

  const checkInput = () => {
    if (!admin.user_name) {
      toast.warn("Please enter User Name")
      return false;
    }

    return true
  }

  const previewUser = () => {
    if (!checkInput()) {
      return;
    }

    let param = {
      user_name: admin.user_name
    }
    apiPost('/admin/hkaa_community/ad_user/query', param)
      .then((resp) => {
        let data = resp.data;
        if (!data.is_success) {
          toast.error("User not found. Please enter valid User Name.")
        }

        updateAdminInfo({...defaultAdminObj, ...param, ...data})
      })
  }

  const saveAdmin = () => {
    apiPost('/admin/hkaa_community/ad_user/set', {
      user_name: admin.user_name
    })
      .then((resp) => {
        let data = resp.data;
        if (!data.is_success) {
          switch (data.error) {
            case 'existed':
              toast.error("User exists already.")
              break;
            case 'not found':
              toast.error("User not found. Please enter valid User Name.")
              break;
            default:
              toast.error("Failed to create user")
              break;
          }
          return;
        }

        backNow()
      })
  }

  const backNow = () => {
    history.push(`${basePath}/list`)
  }

  const saveNow = () => {
    if(!checkInput()) {
      return;
    }
    saveAdmin()
  }

  useEffect(() => {
    if (id) {
      getAdminDetail()
    }
  }, [])

  return (
    <div className="item-set-container">
      <div className="padding-bottom-30 full-width">
        <Card>
          <CardHeader>
            <div className="title">
              {admin.user_id ? 'Admin Detail' : 'Add New'}
            </div>
            <div className="actions">
              <button className="button default" onClick={backNow}>
                <span>Back</span>
              </button>
              <button className="button primary" onClick={saveNow} disabled={!admin.is_success}>
                <span>Save</span>
              </button>
            </div>
          </CardHeader>
        </Card>
        <div className="padding-bottom-30"></div>

        <Card>
          <CardHeader>
            <div className="title sub-title">
              Please enter AD user name to preview user information.
            </div>
            <div className="actions">
              <button className="button primary" onClick={previewUser}>
                <span>Preview</span>
              </button>
            </div>
          </CardHeader>
          <CardBody>
            <BasicInput type="text" value={admin.user_name} label="User Name" onChange={(val) => updateAdminInfo({user_name: val})} />
          </CardBody>
        </Card>
        <div className="padding-bottom-30"></div>

        <Card>
          <CardHeader>
            <div className="title sub-title">
              User Information
            </div>
          </CardHeader>
          <CardBody>
            <BasicInput type="text" value={admin.name} label="Name" isDisabled={true} />
            <BasicInput type="text" value={admin.department_name} label="Department Name" isDisabled={true} />
          </CardBody>
        </Card>
      </div>
    </div>
  )
}

AdminSet.propTypes = {

}

export default AdminSet

