import React from 'react'
import PropTypes from 'prop-types'
import { Switch, Route, Redirect } from 'react-router-dom'
import PhoneBookList from './PhoneBookList';
import PhoneBookSet from './PhoneBookSet';

function SettingPhoneBook(props) {
  const { 
    match
  } = props;

  return (
    <div className="page-container">
      <Switch>
        <Route path={`${match.url}/list`}
          render={props => <PhoneBookList {...props} basePath={match.url} />}
        />
        <Route path={`${match.url}/add`}
          render={props => <PhoneBookSet {...props} basePath={match.url} />}
        />
        <Route path={`${match.url}/edit/:id`}
          render={props => <PhoneBookSet {...props} basePath={match.url} />}
        />
        <Redirect to={`${match.url}/list`} />
      </Switch>
    </div>
  )
}

SettingPhoneBook.propTypes = {

}

export default SettingPhoneBook

