import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import { Pie } from 'react-chartjs-2'
import map from 'lodash/map';
import each from 'lodash/each';
import { getChartColorByIndex, ChartColors, ChartBaseOptions } from './ChartUtilities';
import { saveFileByDataUrl } from '../../../helpers/FileHelper';

export const PIE_COLORS = [
  '#407BFF',
  '#79CBAC'
]

function ChartPie(props) {
  const {
    data,
    nameKey,
    dataKey,
    chartTitle,
    isAllowSave
  } = props;

  let chartRef = React.createRef()

  const [chartJsData, setChartJsData] = useState({})

  const prepareChartJsData = () => {
    let allLabels = map(data, nameKey)

    let datasets = []

    datasets.push({
      data: map(data, dataKey),
      backgroundColor: ChartColors
    })

    setChartJsData({
      labels: allLabels,
      datasets: datasets
    })
  }

  useEffect(() => {
    prepareChartJsData()
  }, [data])

  const saveChart = () => {
    saveFileByDataUrl(
      chartRef.current.chartInstance.toBase64Image(),
      'chart.png'
    )
  }

  return (
    <div>
      {
        isAllowSave &&
        <div className="tip text-align-right text-underline">
          <span 
            className="clickable"
            onClick={saveChart}>
            Save Chart
          </span>
        </div>
      }
      <Pie 
        ref={chartRef}
        data={chartJsData} 
        options={{
          legend: {
            position: "bottom"
          },
          title: {
            ...ChartBaseOptions.title,
            display: !!chartTitle,
            text: chartTitle
          }
        }}
      />
    </div>
  )
}

ChartPie.propTypes = {

}

export default ChartPie

