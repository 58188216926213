import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import Card from '../../../utilities/card/Card';
import CardHeader from '../../../utilities/card/CardHeader';
import CardBody from '../../../utilities/card/CardBody';
import BasicInput from '../../../utilities/form/BasicInput';
import TextAreaInput from '../../../utilities/form/TextAreaInput';
import { toast } from '../../../utilities/Notification/CustomToast';
import each from 'lodash/each';
import { langTabs, defaultLang } from '../../../utilities/form/MultiLangSelector';
import trim from 'lodash/trim';
import MultiLangInputs from '../../../utilities/form/MultiLangInputs';
import { apiPost } from '../../../../helpers/Api';

function CompanySet(props) {
  const {
    match,
    basePath,
    history
  } = props;

  const companyId = match.params.id;

  let i18nDefaultObj = {};
  each(langTabs, (lang) => {
    i18nDefaultObj[lang.key] = {
      tag: ''
    }
  })

  const defaultCompanyObj = {
    tag: '',
    _i18n: i18nDefaultObj
  }

  const [company, setCompany] = useState(defaultCompanyObj)

  const updateCompanyInfo = (update) => {
    setCompany({
      ...company,
      ...update
    })
  }

  const getCompanyDetail = () => {
    let param = {
      tag_id: companyId
    }
    apiPost('/admin/hkaa_community/account_tags/company', param)
      .then((resp) => {
        let company = resp.data;

        let i18n = {};
        each(langTabs, (lang, index) => {
          i18n[lang.key] = {
            name: company.tag
          }
          if (company._i18n[lang.key]) {
            i18n[lang.key] = {
              ...i18n[lang.key],
              ...company._i18n[lang.key]
            }
          }
        })

        company._i18n = {...i18nDefaultObj, ...i18n}

        updateCompanyInfo(company)
      })
  }

  const saveCompany = () => {
    let defaultLangContent = company._i18n[defaultLang.key];

    let i18nObj = {}
    each(langTabs, (lang, index) => {
      i18nObj[lang.key] = {
        tag: trim(company._i18n[lang.key].tag)
      }
    })

    let theCompany = {
      tag: defaultLangContent.tag,
      _i18n: i18nObj
    }

    if (company.tag_id) {
      theCompany.tag_id = company.tag_id
    }

    let url = '/admin/hkaa_community/account_tags/company/set'
    apiPost(url, theCompany)
      .then((resp) => {
        history.push(`${basePath}/list`)
      })
      .catch((err) => {
        console.error(err);
      })
  }

  const checkInput = () => {
    for (let i = 0; i < langTabs.length; i++) {
      let lang = langTabs[i];
      if (!company._i18n[lang.key].tag) {
        toast.warn("Please enter company name for all languages");
        return false;
      }
    }

    return true;
  }

  const saveNow = () => {
    if (!checkInput()) {
      return;
    }
    saveCompany()
  }

  const backNow = () => {
    history.push(`${basePath}/list`)
  }

  useEffect(() => {
    if (companyId) {
      getCompanyDetail()
    }
  }, [])

  return (
    <div className="item-set-container">
      <div className="full-width padding-bottom-30">
        <Card>
          <CardHeader>
            <div className="title">
              {company.tag_id ? 'Company Detail' : 'Add New'}
            </div>
            <div className="actions">
              <button className="button default" onClick={backNow}>
                <span>Back</span>
              </button>
              <button className="button primary" onClick={saveNow}>
                <span>Save</span>
              </button>
            </div>
          </CardHeader>
        </Card>
        <div className="padding-bottom-30"></div>
        <Card>
          <CardHeader>
            <div className="title sub-title">
              Company Name
            </div>
          </CardHeader>
          <CardBody>
            <MultiLangInputs
              i18n={company._i18n}
              field="tag"
              type="text"
              rows={3}
              onChange={(i18n) => updateCompanyInfo({_i18n: i18n})}
              label="Company Name"
            />
          </CardBody>
        </Card>
      </div>

    </div>
  )
}

CompanySet.propTypes = {

}

export default CompanySet

