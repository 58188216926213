const initialState = {
  isLoggedIn: false,
  user: {
    name: '',
    title: '',
    company: ''
  }
}

export default (state = initialState, { type, payload }) => {
  switch (type) {

  case "updateIsLoggedIn":
    return { ...state, ...payload }

  case "updateUser":
    return { ...state, ...payload }

  default:
    return state
  }
}
