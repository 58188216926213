import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Dialog, DialogContent, DialogActions } from '@material-ui/core';
import CardHeader from '../card/CardHeader';
import { faImages } from '@fortawesome/free-regular-svg-icons';
import { getBlobFromUrl } from '../../../helpers/FileHelper';

function ImageSelector(props) {
  const {
    text,
    selections,
    onImageSelected,
    isEnabled
  } = props;

  let selectLabelClassName = ["label-text", "hover-opacity"];
  if (isEnabled) {
    selectLabelClassName.push("clickable")
  } else {
    selectLabelClassName.push("unclickable")
  }

  const onSelectLabelClick = () => {
    if (!isEnabled) {
      return;
    }
    setIsSelectorModalOpen(true)
    setSelectedImage(null)
  }

  const [isSelectorModalOpen, setIsSelectorModalOpen] = useState(false)
  const closeSelectorModal = () => {
    setIsSelectorModalOpen(false)
  }
  const saveSelector = () => {
    getBlobFromUrl({
      url: selectedImage, 
      callback: (blob) => {
        onImageSelected(blob)
      }
    })
    closeSelectorModal()
  }

  const [selectedImage, setSelectedImage] = useState(null)
  const onImageSelect = (image) => {
    setSelectedImage(image)
  }

  return (
    <div className="image-selector-container">
      <div className="label-row">
        <label className={selectLabelClassName.join(' ')} onClick={onSelectLabelClick}>
          <FontAwesomeIcon icon={faImages} fixedWidth />
          {text && <span>{text}</span>}
        </label>
      </div>

      <Dialog
        open={isSelectorModalOpen}
        onClose={closeSelectorModal}
        fullWidth={true}
        maxWidth="md"
        className="image-selector"
      >
        <CardHeader>
          <div className="title">
            {text}
          </div>
        </CardHeader>
        <DialogContent dividers>
          <div className="image-selection-wrapper">
            {
              selections.map((image, index) => {
                let selectionClass = ['image-selection', 'clickable', 'hover-opacity'];
                if (selectedImage == image) {
                  selectionClass.push('selected')
                }
                return (
                  <div className={selectionClass.join(' ')} onClick={() => onImageSelect(image)} key={index}>
                    <div className="image-display">
                      <div className="image-wrapper">
                        <img className="image" src={image} />
                      </div>
                    </div>
                  </div>
                )
              })
            }
          </div>
        </DialogContent>
        <DialogActions>
          <button className="button default" onClick={closeSelectorModal}>
            <span>Cancel</span>
          </button>
          <button className="button primary" onClick={saveSelector}>
            <span>Select</span>
          </button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

ImageSelector.propTypes = {

}

export default ImageSelector

