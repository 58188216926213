import React from 'react'
import PropTypes from 'prop-types'
import { Switch, Route, Redirect } from 'react-router-dom'
import JargonBusterList from './JargonBusterList';
import JargonBusterSet from './JargonBusterSet';

function SettingJargonBuster(props) {
  const { 
    match
  } = props;

  return (
    <div className="page-container">
      <Switch>
        <Route path={`${match.url}/list`}
          render={props => <JargonBusterList {...props} basePath={match.url} />}
        />
        <Route path={`${match.url}/add`}
          render={props => <JargonBusterSet {...props} basePath={match.url} />}
        />
        <Route path={`${match.url}/edit/:id`}
          render={props => <JargonBusterSet {...props} basePath={match.url} />}
        />
        <Redirect to={`${match.url}/list`} />
      </Switch>
    </div>
  )
}

SettingJargonBuster.propTypes = {

}

export default SettingJargonBuster

