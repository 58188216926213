import { toast } from "react-toastify"
import { apiPost } from "./Api";
import { getFullDateDisplayByTimestampSecond } from "./DateHelper";
import { updateCronjobList } from "../redux/actions";
import store from "../redux/store";

const trackCronJobTime = 10;
export const CRONJOB_STATUS = {
  ERROR: -1,
  DISABLED: 0,
  IN_QUEUE: 1,
  RUNNING: 2,
  FINISHED: 9
};


let currentSessionCronJob = []
let currentSessionFinishedCronJobId = []
let trackCronJobInterval = null

const updateCronjobRedux = () => {
  // console.log(currentSessionCronJob)
  store.dispatch(updateCronjobList({jobs: currentSessionCronJob}))
}

const apiCheckCronJobStatus = function (job, index) {
  apiPost('/admin/cronjob', {job_id: job.jobId, isBackground: true})
    .then((resp) => {
      let j = resp.data;
      j.createDateDisplay = getFullDateDisplayByTimestampSecond(j.create_date)
      job.job = j;

      if (j.status === CRONJOB_STATUS.FINISHED || j.status === CRONJOB_STATUS.ERROR) {
        setCronJobFinished(j, job, index)
      }

      if (job.statusUpdateCallback) {
        job.statusUpdateCallback(j)
      }

      updateCronjobRedux();

    })
};

export const checkCronJobsStatus = function () {
  for (let index in currentSessionCronJob) {
      let job = currentSessionCronJob[index];
      // Check unfinished tracking cron job one by one
      if (!job.isFinished) {
          apiCheckCronJobStatus(job, index);
      }
  }
};

export const setCronJobFinished = function (data, job, index) {
  let currentJob = currentSessionCronJob[index];
  currentJob.isFinished = true;
  currentSessionFinishedCronJobId.push(job.job_id);

  if (currentJob.successCallback) {
    currentJob.successCallback(data, data.status !== CRONJOB_STATUS.ERROR);
  } else {
    if (data.status === CRONJOB_STATUS.FINISHED) {
      toast.info('Your job is finished. Please check Notification Center.')
    } else if (data.status === CRONJOB_STATUS.ERROR) {
      toast.error('Error occurred during the job.')
    }
  }

  // Check if all current session job is finished, clear Interval
  if (currentSessionCronJob.length === currentSessionFinishedCronJobId.length) {
    clearInterval(trackCronJobInterval);
  }
};

export const startTrackingCronJob = ({
  jobId,
  successCallback,
  statusUpdateCallback,
  jobStartCallback,
  isDisplay,
  jobName,
  fileName,
}) => {
  if (!jobId) {
    return;
  }

  currentSessionCronJob.unshift({
    jobId: jobId,
    isFinished: false,
    successCallback: successCallback,
    statusUpdateCallback: statusUpdateCallback,
    job: {},
    isDisplay: isDisplay,
    jobName: jobName,
    fileName: fileName
  });

  updateCronjobRedux();

  if (jobStartCallback) {
      jobStartCallback();
  }
  // Clear Interval and start again
  clearInterval(trackCronJobInterval);
  checkCronJobsStatus();
  trackCronJobInterval = setInterval(checkCronJobsStatus, trackCronJobTime * 1000);
}

export const addNewCronjobTrack = ({
  jobId,
  successCallback,
  statusUpdateCallback,
  jobStartCallback,
  isDisplay,
  jobName,
  fileName,
}) => {
  if (!successCallback) {
    toast.warn('Your request is running in the background, and will notify you when the job finished.')
  }
  startTrackingCronJob({jobId: jobId, successCallback: successCallback, statusUpdateCallback: statusUpdateCallback, jobStartCallback: jobStartCallback, isDisplay: isDisplay, jobName: jobName, fileName: fileName})
}