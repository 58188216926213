import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

function BasicInput(props) {
  const {
    type,
    value,
    onChange,
    label,
    isDisabled,
    isHideLabel,
    rows,
    isVertical,
    noBorder,
  } = props;

  const [inputValue, setInputValue] = useState(value !== null ? value : '');
  const onInputChange = (event) => {
    setInputValue(event.target.value);
  }

  useEffect(() => {
    if (onChange) {
      onChange(inputValue);
    }
  }, [inputValue]);

  useEffect(() => {
    setInputValue(value);
  }, [value]);

  return (
    <div>
      <div className={`input-container ${isVertical ? 'vertical' : ''}`}>
        {
          !isHideLabel &&
          <span className="input-label">
            {label}
          </span>
        }
        {
          rows 
          ?
          <textarea className="form-input" value={inputValue} placeholder={label} onChange={onInputChange} disabled={isDisabled} rows={rows} />
          :
          <input className="form-input" type={type} value={inputValue} placeholder={label} onChange={onInputChange} disabled={isDisabled}/>
        }
      </div>
      {
        !noBorder &&
        <div className="border-bottom"></div>
      }
    </div>
  )
}

BasicInput.propTypes = {

}

export default BasicInput

