import React, { useState, useEffect } from 'react'

import { connect } from 'react-redux'
import { langTabs, defaultLang } from '../../../utilities/form/MultiLangSelector';
import Card from '../../../utilities/card/Card';
import CardHeader from '../../../utilities/card/CardHeader';
import CardBody from '../../../utilities/card/CardBody';
import CheckboxInput from '../../../utilities/form/CheckboxInput';
import { apiPost } from '../../../../helpers/Api';
import { toast } from '../../../utilities/Notification/CustomToast';

import map from 'lodash/map';
import each from 'lodash/each';
import find from 'lodash/find';
import trim from 'lodash/trim'
import { createLocalFileObject, uploadLocalFileObjects } from '../../../../helpers/FileHelper';
import TargetAudienceSelector, { AudienceCompany } from '../../../utilities/form/TargetAudienceSelector';
import RadioList from '../../../utilities/form/RadioList';
import MultiLangInputs from '../../../utilities/form/MultiLangInputs';
import { postFieldLimit, postStatusDraft, postStatusOptions } from '../../../../helpers/PostHelper';
import { getFullDateDisplayByTimestampSecond, checkIfTimesInCorrectOrder } from '../../../../helpers/DateHelper';
import TextAreaInput from "../../../utilities/form/TextAreaInput";

function PostTemplateSet(props) {
  const { 
    match, 
    basePath, 
    history,
    onSaveCallback,
  } = props;

  let postTemplateId = match ? match.params.id : null;
  const existingPostTemplateObj = props.location.state && props.location.state.post_template

  let i18nDefaultObj = {};
  each(langTabs, (lang) => {
    i18nDefaultObj[lang.key] = {
      title: '', 
      caption: '', 
      html_content: '', 
      html_content_new: '', 
      localImageObjArr: [],
      uploadedImageSrcArr: [],
      localMediaObjArr: [],
      uploadedMediaSrcArr: [],
      button_content: '',
      button_url: ''
    }
  })

  const defaultPostTemplateObj = {
    postTemplateName: '',
    title: '',
    caption: '',
    html_content: '',
    html_content_new: '',
    isNotify: '',
    categories: [],
    defaultCategories: [],
    defaultCategoryIds: [],
    selectedUserGroups: [],
    defaultSelectedUserGroups: [],
    defaultSelectedUserGroupIds: [],
    isPublishOnNow: true,
    publishOn: [null],
    isNeverExpire: true,
    expireOn: [null],
    _i18n: i18nDefaultObj,
    isSharable: true,
    isLikable: true,
    status: postStatusOptions[0],
    isDraft: true,
    localMediaObjArr: [],
    uploadedMediaSrcArr: [],
    localImageObjArr: [],
    uploadedImageSrcArr: [],
    originalStatus: postStatusDraft,
    audience: [AudienceCompany],
    audience_new: [],
  };

  const [post_template, setPostTemplate] = useState(defaultPostTemplateObj);
  const [isTemplateIdEditable, setIsTemplateIdEditable] = useState(true)

  const updatePostInfo = (update) => {
    // console.log(post, update)
    setPostTemplate({
      ...post_template,
      ...update
    })
  }

  const getPostTemplateDetail = () => {
    let param = {
      post_template_id: postTemplateId
    }
    apiPost('/admin/post_template', param)
      .then((resp) => {
        let post_template = resp.data;

        let i18n = {};
        each(langTabs, (lang, index) => {
          i18n[lang.key] = {
            title: post_template.title,
            caption: post_template.caption,
            html_content: post_template.html_content,
            html_content_new: post_template.html_content,
            localMediaObjArr: map(post_template.media, (m) => {
              return createLocalFileObject({attachmentId: m.attachment_id, name: m.filename})
            }),
            uploadedMediaSrcArr: [],
            localImageObjArr: map(post_template.images, (i) => {
              return createLocalFileObject({attachmentId: i, name: i})
            }),
            uploadedImageSrcArr: [],
          }
          let curLangObj = post_template._i18n[lang.key];
          if (curLangObj) {
            i18n[lang.key] = {
              ...i18n[lang.key],
              ...curLangObj,
              ...{
                html_content_new: curLangObj.html_content,
              },
            }

            if (curLangObj.media_attachments) {
              i18n[lang.key] = {
                ...i18n[lang.key],
                ...{
                  localMediaObjArr: map(curLangObj.media_attachments, (m) => {
                    return createLocalFileObject({attachmentId: m.attachment_id, name: m.filename})
                  }),
                }
              }
            }

            if (curLangObj.images) {
              i18n[lang.key] = {
                ...i18n[lang.key],
                ...{
                  localImageObjArr: map(curLangObj.images, (i) => {
                    return createLocalFileObject({attachmentId: i, name: i})
                  }),
                }
              }
            }
          }
        })

        post_template.postTemplateName = post_template.post_template_name
        post_template._i18n = {...i18nDefaultObj, ...i18n}

        post_template.defaultCategoryIds = post_template.category_ids;

        post_template.isNotify = !!post_template.is_notify;
        post_template.isSharable = !!post_template.is_sharable;
        post_template.isLikable = !!post_template.is_likable;

        post_template.localMediaObjArr = map(post_template.media_attachments, (m) => {
          return createLocalFileObject({attachmentId: m.attachment_id, name: m.filename})
        })

        post_template.localImageObjArr = map(post_template.images, (i) => {
          return createLocalFileObject({attachmentId: i, name: i})
        })

        post_template.audience_new = post_template.audience

        updatePostInfo({
          ...defaultPostTemplateObj,
          ...post_template
        })

        getPostCategory()
        getUserGroupList()
      })
  }
  const updatePostTemplateDetail = (existingPostTemplateObj) => {
    const post_template = existingPostTemplateObj

    let i18n = {};
    each(langTabs, (lang, index) => {
      i18n[lang.key] = {
        title: post_template.title,
        caption: post_template.caption,
        html_content: post_template.html_content,
        html_content_new: post_template.html_content,
        localMediaObjArr: map(post_template.media, (m) => {
          return createLocalFileObject({attachmentId: m.attachment_id, name: m.filename})
        }),
        uploadedMediaSrcArr: [],
        localImageObjArr: map(post_template.images, (i) => {
          return createLocalFileObject({attachmentId: i, name: i})
        }),
        uploadedImageSrcArr: [],
      }
      let curLangObj = post_template._i18n[lang.key];
      if (curLangObj) {
        i18n[lang.key] = {
          ...i18n[lang.key],
          ...curLangObj,
          ...{
            html_content_new: curLangObj.html_content,
          },
        }

        if (curLangObj.media_attachments) {
          i18n[lang.key] = {
            ...i18n[lang.key],
            ...{
              localMediaObjArr: map(curLangObj.media_attachments, (m) => {
                return createLocalFileObject({attachmentId: m.attachment_id, name: m.filename})
              }),
            }
          }
        }

        if (curLangObj.images) {
          i18n[lang.key] = {
            ...i18n[lang.key],
            ...{
              localImageObjArr: map(curLangObj.images, (i) => {
                return createLocalFileObject({attachmentId: i, name: i})
              }),
            }
          }
        }
      }
    })

    post_template.postTemplateName = post_template.post_template_name
    post_template._i18n = {...i18nDefaultObj, ...i18n}

    post_template.defaultCategoryIds = post_template.category_ids;

    post_template.isNotify = !!post_template.is_notify;
    post_template.isSharable = !!post_template.is_sharable;
    post_template.isLikable = !!post_template.is_likable;

    post_template.localMediaObjArr = map(post_template.media_attachments, (m) => {
      return createLocalFileObject({attachmentId: m.attachment_id, name: m.filename})
    })

    post_template.localImageObjArr = map(post_template.images, (i) => {
      return createLocalFileObject({attachmentId: i, name: i})
    })

    post_template.audience_new = post_template.audience

    updatePostInfo({
      ...defaultPostTemplateObj,
      ...post_template
    })

    getPostCategory()
    getUserGroupList()
  }

  const [categoryList, setCategoryList] = useState([])
  const getPostCategory = () => {
    let param = {

    }
    apiPost('/admin/post/category', param)
    .then((resp) => {
      let categories = resp.data;

      categories.sort((a, b) => {
        return a.name.toLowerCase().localeCompare(b.name.toLowerCase())
      })

      setCategoryList(categories);
    })
  }
  const setDefaultCategorySelection = () => {
    let mapping = [];
    each(post_template.defaultCategoryIds, (id) => {
      let target = find(categoryList, {category_id: id})
      if (target) {
        mapping.push(target)
      }
    })
    updatePostInfo({categories: mapping[0]})
  }
  useEffect(() => {
    setDefaultCategorySelection()
  }, [categoryList])

  const [userGroupList, setUserGroupList] = useState([])
  const getUserGroupList = () => {
    let param = {

    }

    apiPost('/admin/manageable_news_channels', param)
      .then((resp) => {
        let ugs = resp.data;

        // TODO: if needed

        setUserGroupList(ugs)
      })
  }
  const setDefaultUserGroupSelection = () => {
    let mapping = [];
    each(post_template.defaultSelectedUserGroupIds, (id) => {
      let target = find(userGroupList, {chat_id: id})
      if (target) {
        mapping.push(target)
      }
    })
    updatePostInfo({defaultSelectedUserGroups: mapping, selectedUserGroups: mapping})
  }
  useEffect(() => {
    setDefaultUserGroupSelection()
  }, [userGroupList])

  const checkInput = () => {
    for (let i = 0; i < langTabs.length; i++) {
      let lang = langTabs[i];
      let curLangObj = post_template._i18n[lang.key]
      if (!curLangObj.title) {
        toast.warn("Please enter title for all languages");
        return false;
      }
      if (!curLangObj.caption) {
        toast.warn("Please enter caption for all languages");
        return false;
      }
      if (!curLangObj.html_content_new) {
        toast.warn("Please enter content for all languages");
        return false;
      }
      if (curLangObj.title.length > postFieldLimit.title) {
        toast.warn("Invalid title (Exceeds length limit)")
        return false;
      }
      if (curLangObj.caption.length > postFieldLimit.caption) {
        toast.warn("Invalid caption (Exceeds length limit)")
        return false;
      }
      if (curLangObj.localImageObjArr.length === 0) {
        toast.warn("Please upload at least one featured image for all languages")
        return false;
      }

    }

    if (post_template.postTemplateName.length === 0) {
        toast.warn("Please enter post template name");
        return false;
    }

    if (post_template.postTemplateName.length > postFieldLimit.title) {
        toast.warn("Invalid post template name (Exceeds length limit)")
        return false;
    }

    if (post_template.audience_new.length === 0) {
      toast.warn("Please select target audiences");
      return false;
    }

    if (!post_template.isNeverExpire  && !checkIfTimesInCorrectOrder(post_template.publishOn[0], post_template.expireOn[0])) {
      toast.warn("Expire date must be after publish date")
      return false;
    }

    if (!post_template.categories) {
      toast.warn("Please select category");
      return false;
    }

    return true;
  }

  const uploadPic = (obj, index, callback) => {    
    uploadLocalFileObjects({
      arr: obj.localImageObjArr,
      index: index,
      uploadedIds: [],
      callbackWithIds: (ids) => {
        obj.uploadedImageSrcArr = ids
        callback()
      }
    })
  }

  const uploadLangPic = (index, callback) => {
    if (index === langTabs.length) {
      callback();
      return
    }
    uploadPic(
        post_template._i18n[langTabs[index].key],
      0,
      () => {
        uploadLangPic(++index, callback)
      }
    )
  }

  const uploadMedia = (obj, index, callback) => {
    uploadLocalFileObjects({
      arr: obj.localMediaObjArr,
      index: index,
      uploadedIds: [],
      callbackWithIds: (ids) => {
        obj.uploadedMediaSrcArr = ids
        callback()
      }
    })

  }

  const uploadLangMedia = (index, callback) => {
    if (index === langTabs.length) {
      callback();
      return
    }
    uploadMedia(
        post_template._i18n[langTabs[index].key],
      0,
      () => {
        uploadLangMedia(++index, callback)
      }
    )
  }

  const savePostTemplate = (isNotify) => {

    let allMedia = [];
    let allImages = [];

    let i18nObj = {}
    each(langTabs, (lang, index) => {
      let curLangObj = post_template._i18n[lang.key]
      i18nObj[lang.key] = {
        title: trim(curLangObj.title),
        caption: trim(curLangObj.caption),
        html_content: trim(curLangObj.html_content_new),
        media: curLangObj.uploadedMediaSrcArr,
        images: curLangObj.uploadedImageSrcArr,
      }
      allMedia = [...allMedia, ...curLangObj.uploadedMediaSrcArr]
      allImages = [...allImages, ...curLangObj.uploadedImageSrcArr]

    })

    let defaultLangContent = i18nObj[defaultLang.key]

    let categoryChatIds = post_template.categories ? [post_template.categories.category_id] : []

    let thePost = {
      post_template_name: post_template.postTemplateName,
      title: defaultLangContent.title,
      caption: defaultLangContent.caption,
      html_content: defaultLangContent.html_content_new,
      _i18n: i18nObj,
      media: allMedia,
      images: allImages,
      category_ids: categoryChatIds,
      is_sharable: post_template.isSharable ? 1 : 0,
      is_notify: isNotify ? 1 : 0,
      is_likable: post_template.isLikable ? 1 : 0,
      audience: post_template.audience_new,
    }

    let url = '/admin/post_template/create'
    if (post_template.post_template_id) {
      thePost.post_template_id = post_template.post_template_id;
      url = '/admin/post_template/update'
    }

    // console.log('Post Object >>>>', thePost)

    apiPost(url, thePost)
      .then((resp) => {
        if (onSaveCallback) {
          onSaveCallback(resp.data.post_id)
          return
        }
        if (resp.data.status === postStatusDraft.enum) {
          postTemplateId = resp.data.post_id;
          getPostTemplateDetail();
          return;
        }
        history.push(`${basePath}/list`)
      }).catch(error => {
        toast.warn("Template ID already exists. Please provide an unique Template ID")
    })
  }

  const saveNow = (isNotify) => {
    // console.log(post)

    if (!checkInput()){
      return
    }

    uploadLangMedia(
      0,
      () => {
        uploadLangPic(
          0,
          () => {
            savePostTemplate(isNotify)
          }
        )
      }
    )

    // uploadMedia(0, () => uploadPic(0, () => savePost(!isDraft, isNotify)))
  }

  useEffect(() => {
    if (postTemplateId) {
      setIsTemplateIdEditable(false)
      getPostTemplateDetail()
    } else if (existingPostTemplateObj) {
        updatePostTemplateDetail(existingPostTemplateObj)
    } else {
      getPostCategory()
    }
  }, [])

  return (
    <div className="item-set-container post-set-container">
      <div className="full-width padding-bottom-30">
        <Card>
          <CardHeader>
            <div className="title">
              {post_template.post_id ? 'PostTemplate Detail' : 'Add New'}
            </div>
            {
                post_template.update_date &&
                <div>
                  Last updated at: {getFullDateDisplayByTimestampSecond(post_template.update_date)}
                </div>
            }
          </CardHeader>
        </Card>
        <div className="padding-bottom-30"></div>
        <Card>
          <CardHeader>
            <div className="title sub-title">
              Save
            </div>
          </CardHeader>
          <CardBody>
            <div className="right-action">
              <button className="button primary" onClick={saveNow}>
                <span>Save</span>
              </button>
            </div>
          </CardBody>
        </Card>

        <div className="padding-bottom-30"></div>

        <Card>
          <CardHeader>
            <div className="title sub-title">
              Template ID
            </div>
          </CardHeader>
          <CardBody>
            <TextAreaInput
                value={post_template.postTemplateName}
                field="title"
                type="textarea"
                rows="1"
                onChange={(val) => updatePostInfo({postTemplateName: val})}
                isHideLabel={true}
                limit={postFieldLimit.title}
                placeholder="Template ID"
                disabled={!isTemplateIdEditable}
            />
          </CardBody>
        </Card>

        <div className="padding-bottom-30"></div>

        <Card>
          <CardHeader>
            <div className="title sub-title">
              Feature Image (Max. 5)
            </div>
          </CardHeader>
          <CardBody>
            <MultiLangInputs
                i18n={post_template._i18n}
                field="localImageObjArr"
                type="feature_image"
                onChange={(i18n) => updatePostInfo({_i18n: i18n})}
                label="Feature Image"
            />
          </CardBody>
        </Card>

        <div className="padding-bottom-30"></div>
        <Card>
          <CardHeader>
            <div className="title sub-title">
              Title
            </div>
          </CardHeader>
          <CardBody>
            <MultiLangInputs
                i18n={post_template._i18n}
                field="title"
                type="textarea"
                rows="2"
                onChange={(i18n) => updatePostInfo({_i18n: i18n})}
                label="Title"
                limit={postFieldLimit.title}
            />
          </CardBody>
        </Card>
        <div className="padding-bottom-30"></div>
        <Card>
          <CardHeader>
            <div className="title sub-title">
              Caption
            </div>
          </CardHeader>
          <CardBody>
            <MultiLangInputs
                i18n={post_template._i18n}
                field="caption"
                type="textarea"
                rows="4"
                onChange={(i18n) => updatePostInfo({_i18n: i18n})}
                label="Caption"
                limit={postFieldLimit.caption}
            />
          </CardBody>
        </Card>
        <div className="padding-bottom-30"></div>
        <Card>
          <CardHeader>
            <div className="title sub-title">
              Content
            </div>
          </CardHeader>
          <CardBody>
            <MultiLangInputs
                i18n={post_template._i18n}
                field="html_content"
                newField="html_content_new"
                type="html"
                onChange={(i18n) => updatePostInfo({_i18n: i18n})}
                label="Content"
            />
          </CardBody>
        </Card>

        <div className="padding-bottom-30"></div>

        <TargetAudienceSelector
            value={post_template.audience}
            onChange={(val) => updatePostInfo({audience_new: val})}
        />
        <div className="padding-bottom-30"></div>
        <Card>
          <CardHeader>
            <div className="title sub-title">
              Interactions
            </div>
          </CardHeader>
          <CardBody>
            <div className="checkbox-config">
              <div className="config-name">
                Allow Like
              </div>
              <div className="checkbox">
                <CheckboxInput value={post_template.isLikable}
                               onChange={(isLikable) => updatePostInfo({isLikable: isLikable})}/>
              </div>
            </div>
            <div className="checkbox-config">
              <div className="config-name">
                Allow Share
              </div>
              <div className="checkbox">
                <CheckboxInput value={post_template.isSharable}
                               onChange={(isSharable) => updatePostInfo({isSharable: isSharable})}/>
              </div>
            </div>
          </CardBody>
        </Card>

        <div className="padding-bottom-30"></div>
        <Card>
          <CardHeader>
            <div className="title sub-title">
              Categories
            </div>
          </CardHeader>
          <CardBody>
            <RadioList
                data={categoryList}
                selected={post_template.categories}
                onChange={(items) => {
                  console.log(items)
                  updatePostInfo({categories: items})
                }}
            />
          </CardBody>
        </Card>
        <div className="padding-bottom-30"></div>

      </div>

    </div>
  )
}

PostTemplateSet.propTypes = {}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(PostTemplateSet)
