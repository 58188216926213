import axios from "axios"
import each from 'lodash/each';
import { TargetHost, TargetDomain } from "./Constants";
import { uploadLoadingState } from "./LoadingHelper";
import { toast } from "react-toastify";

import { saveAs } from 'file-saver'
import { addNewCronjobTrack } from "./CronJobHelper";

let networkErrorId = 'network-error';

export const apiInstance = axios.create({
  baseURL: TargetHost
})

apiInstance.interceptors.request.use((config) => {
  if (config.data && !config.data.isBackground) {
    uploadLoadingState(true);
  }
  return config;
}, (error) => {
  uploadLoadingState(false);
  return Promise.reject(error);
});

apiInstance.interceptors.response.use((response) => {
  uploadLoadingState(false);
  return response;
}, (error) => {
  uploadLoadingState(false);
  if (error.message === 'Network Error') {
    if (toast.isActive(networkErrorId)) {
      return
    }
    toast.error('Network Error. Please check your internet connection.', {toastId: networkErrorId})
  }
  return Promise.reject(error);
})

export const apiGet = async (url, configs) => {
  return await apiInstance.get(url, configs);
}

export const apiPost = async (url, data, config) => {
  return await apiInstance.post(url, data, config);
}

export const apiUploadFile = async (file, param) => {
  let fd = new FormData();
  fd.append('file', file, file.name);
  fd.append('custom_filename', file.name.substr(0, file.name.lastIndexOf(".")));
  each(param, (data, key) => {
    fd.append(key, data);
  });

  return await apiInstance.post('/admin/upload', fd);
  
}

export const apiExportFile = async (url, param) => {
  apiInstance.post(url, param, {responseType: "arraybuffer"})
    .then((resp) => {
      let successCallback = (data, isSuccess) => {
        if (isSuccess) {
          let result = JSON.parse(data.result)
          if (result.attachment_id) {
            apiExportFile(`/static/${result.attachment_id}`, {
              filename: param.filename
            })
          }
        }
      }

      if (resp.status === 202) {
        let decodedString = String.fromCharCode.apply(null, new Uint8Array(resp.data));
        let parsedData = JSON.parse(decodedString);

        let jobStartCallback = () => {
          toast.info('Processing... File will start downloading when it is ready. You can also check the progress in Notification Center.')
        }

        addNewCronjobTrack({
          jobId: parsedData.job_id, 
          isDisplay: param.isDisplay, 
          jobName: param.jobName, 
          successCallback: successCallback,
          jobStartCallback: jobStartCallback,
          fileName: param.filename
        })
        return;
      }

      let filename;
      let disposition = resp.headers['content-disposition'];
      if (disposition && disposition.indexOf('attachment') !== -1) {
          const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
          let matches = filenameRegex.exec(disposition);
          if (matches != null && matches[1]) {
            filename = matches[1].replace(/['"]/g, '');
          }
      }

      if (param && param.filename) {
        if (!filename) {
          return
        }

        let components = filename.split(".");
        let extension = components[components.length - 1];
        filename = [param.filename, extension].join(".");
      }

      let type = resp.headers['content-Type'];

      let blob = new Blob([resp.data], {type: type});

      saveAs(blob, filename);

      // TODO:
    })
}

export const apiGetTextResponse = async (url, param) => {
  return apiInstance.post(url, param, {responseType: "text"})
}

export const getLocalFileByUrl = (url) => {
  return apiInstance.get(url, {responseType: "arraybuffer", baseURL: ''})
}