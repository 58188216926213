const initialState = {
  isDisplay: false,
  title: '',
  message: '',
  closeCallback: null,
  confirmCallback: null,
  isHideCancel: false,
}

export default (state = initialState, { type, payload }) => {
  switch (type) {

  case "setConfirmModalState":
    return { ...state, ...payload }

  default:
    return state
  }
}
