import React from 'react'
import PropTypes from 'prop-types'

import { connect } from 'react-redux'

import { Switch, Route, Redirect } from 'react-router-dom'
import PostList from './list/PostList';
import PostSet from './set/PostSet';
import PostDashboard from './dashboard/PostDashboard';

import "./Post.scss"

function Post(props) {
  const { match } = props;

  return (
    <div className="page-container">
      <Switch>
        <Route path={`${match.url}/dashboard`} 
          render={props => <PostDashboard {...props} basePath={match.url}></PostDashboard>}/>
        <Route path={`${match.url}/list`} 
          render={props => <PostList {...props} basePath={match.url}></PostList>}/>
        <Route path={`${match.url}/add`} 
          render={props => <PostSet {...props} basePath={match.url}></PostSet>}/>
        <Route path={`${match.url}/edit/:id`} 
          render={props => <PostSet {...props} basePath={match.url}></PostSet>}/>
        <Redirect to={`${match.url}/dashboard`}/>
      </Switch>
    </div>
  )
}

Post.propTypes = {

}

const mapStateToProps = (state) => ({
  
})

const mapDispatchToProps = {
  
}

export default connect(mapStateToProps, mapDispatchToProps)(Post)
