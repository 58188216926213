import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import Card from '../../../utilities/card/Card';
import CardHeader from '../../../utilities/card/CardHeader';
import CardBody from '../../../utilities/card/CardBody';
import BasicInput from '../../../utilities/form/BasicInput';
import { toast } from '../../../utilities/Notification/CustomToast';
import { apiPost } from '../../../../helpers/Api';
import CheckboxInput from "../../../utilities/form/CheckboxInput";

function SettingPost(props) {

  const [setting, setSetting] = useState({
    advertisement_conf_interval: 0,
    advertisement_conf_offset: 0,
    whats_new_pin_to_top_enabled: 0,
  })

  const updateSetting = (update) => {
    setSetting({
      ...setting,
      ...update
    })
  }

  const getPostSetting = () => {
    apiPost('/admin/post/conf', {})
      .then(({data}) => {
        if (data) {
          updateSetting({
            ...setting,
            ...{
              advertisement_conf_interval: data.value.advertisement_conf.interval,
              advertisement_conf_offset: data.value.advertisement_conf.offset,
              whats_new_pin_to_top_enabled: data.value.pin_to_top_conf.whats_new_pin_to_top_enabled,
            }
          })
        }
      })
  }

  const checkInput = () => {
    if (!Number.isInteger(parseFloat(setting.advertisement_conf_interval)) || parseFloat(setting.advertisement_conf_interval) < 0) {
      toast.error("Please enter a valid integer for Advertisement Display Interval")
      return false
    }
    if (!Number.isInteger(parseFloat(setting.advertisement_conf_offset)) || parseFloat(setting.advertisement_conf_offset) < 0) {
      toast.error("Please enter a valid integer for Advertisement Display Offset")
      return false
    }
    return true
  }

  const savePostSetting = () => {
    if (!checkInput()) {
      return;
    }
    apiPost('/admin/post/conf/set', {
      value: {
        advertisement_conf: {
          interval: setting.advertisement_conf_interval,
          offset: setting.advertisement_conf_offset
        },
        pin_to_top_conf: {
            whats_new_pin_to_top_enabled: setting.whats_new_pin_to_top_enabled
        }
      }
    })
      .then((resp) => {
        getPostSetting();
      })
  }

  useEffect(() => {
    getPostSetting()
  }, [])

  return (
    <div className="item-set-container">
      <div className="full-width padding-bottom-30">
        <Card>
          <CardHeader>
            <div className="title">
              Post Setting
            </div>
            <div className="actions">
              <button className="button primary" onClick={savePostSetting}>
                <a>Save</a>
              </button>
            </div>
          </CardHeader>
        </Card>
        <div className="padding-bottom-30"></div>
        <Card>
          <CardHeader>
            <div className="title sub-title">
              Advertisement
            </div>
          </CardHeader>
          <CardBody>
            <BasicInput
                type="number"
                value={setting.advertisement_conf_interval}
                label="Display Interval"
                onChange={(val) => updateSetting({advertisement_conf_interval: val})}
            />
            <BasicInput
                type="number"
                value={setting.advertisement_conf_offset}
                label="Display Offset"
                onChange={(val) => updateSetting({advertisement_conf_offset: val})}
            />
          </CardBody>
        </Card>
        <div className="padding-bottom-30"></div>
        <Card>
          <CardHeader>
            <div className="title sub-title">
              Pin To Top
            </div>
          </CardHeader>
          <CardBody>
            <div className="checkbox-config">
              <div className="config-name">
                What's New
              </div>
              <div className="checkbox">
                <CheckboxInput name={"Enable Pin to Top"}
                               value={setting.whats_new_pin_to_top_enabled}
                               onChange={(val) => updateSetting({whats_new_pin_to_top_enabled: val ? 1 : 0})}/>
              </div>
            </div>
          </CardBody>

        </Card>
      </div>
    </div>
  )
}

SettingPost.propTypes = {}

export default SettingPost

