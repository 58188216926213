import React from 'react'
import PropTypes from 'prop-types'

function CardHeader(props) {
  const { children } = props;
  return (
    <div className="card-header">
      {children}
    </div>
  )
}

CardHeader.propTypes = {

}

export default CardHeader
