import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { subMonths, differenceInDays, differenceInWeeks, differenceInMonths, subYears, startOfMonth, endOfMonth, addMonths } from 'date-fns';
import DateRangeInput from '../../utilities/form/DateRangeInput';
import SelectInput from '../../utilities/form/SelectInput';
import { getTimestampSecRangeForApi } from '../../../helpers/DateHelper';
import { toast } from '../../utilities/Notification/CustomToast';
import CardHeader from '../../utilities/card/CardHeader';

export const FilterIntervalOptions = [
  {
    value: 'day',
    label: 'Day'
  },
  // {
  //   value: 'week',
  //   label: 'Week'
  // },
  {
    value: 'month',
    label: 'Month'
  }
]

function RangeIntervalFilter(props) {
  const {
    submitParams,
    exportParams,
    isFullYear,
    isDisableInterval,
    isDisableExport
  } = props;

  let rangeStart = subMonths(new Date(), 1)
  let rangeEnd = new Date()
  let defaultInterval = FilterIntervalOptions[0]

  if (isFullYear) {
    rangeStart = subYears(startOfMonth(addMonths(new Date(), 1)), 1)
    rangeEnd = endOfMonth(new Date())
    defaultInterval = FilterIntervalOptions[FilterIntervalOptions.length - 1]
  }

  const [range, setRange] = useState([rangeStart, rangeEnd])
  const [interval, setInterval] = useState(defaultInterval)

  // useEffect(() => {
  //   updateParams(range, interval)
  // }, [range, interval])

  const checkFilter = () => {
    if (range.length < 1) {
      toast.warn("Please select time range")
      return false
    }
    if (interval.value === 'day') {
      if (Math.abs(differenceInDays(range[0], range[1])) > 45) {
        toast.warn("Maximum time interval is 45 days for option 'Day'")
        return false
      }
    }

    if (interval.value === 'week') {
      if (Math.abs(differenceInWeeks(range[0], range[1])) > 30) {
        toast.warn("Maximum time interval is 30 weeks for option 'Week'")
        return false
      }
    }

    if (interval.value === 'month') {
      if (Math.abs(differenceInMonths(range[0], range[1])) > 36) {
        toast.warn("Maximum time interval is 36 months for option 'Month'")
        return false
      }
    }
    
    return true
  }

  const getFilterParam = () => {
    let secRange = getTimestampSecRangeForApi(range)
    return {
      start_time: secRange[0],
      end_time: secRange[1],
      time_interval: interval.value,
      interval: interval.label
    }
  }

  const getStat = () => {
    if (!checkFilter()) {
      return
    }
    submitParams(getFilterParam())
  }

  const exportStat = () => {
    if (!checkFilter()) {
      return
    }
    exportParams(getFilterParam())
  }


  return (
    <CardHeader>
      <div className="range-interval-filter-container">
        <div className="filter-wrapper">
          <div className="filter-label">
            Time Range
          </div>
          <div className="filter-body">
            <DateRangeInput 
              value={range}
              onChange={setRange}
              className="border"
            />
          </div>
        </div>
        {
          !isDisableInterval &&
          <div className="filter-wrapper">
            <div className="filter-label">
              Interval
            </div>
            <div className="filter-body">
              <SelectInput
                options={FilterIntervalOptions}
                value={interval}
                onChange={setInterval}
              />
            </div>
          </div>
        }
      </div>
      <div className="actions">
        <button className="button primary" onClick={getStat}>
          <a>Get Statistics</a>
        </button>
        {
          !isDisableExport &&
          <button className="button info" onClick={exportStat}>
            <a>Export</a>
          </button>
        }
      </div>
    </CardHeader>
  )
}

RangeIntervalFilter.propTypes = {

}

export default RangeIntervalFilter

