import React from 'react'
import PropTypes from 'prop-types'
import Card from '../../../utilities/card/Card';
import CardHeader from '../../../utilities/card/CardHeader';
import CardBody from '../../../utilities/card/CardBody';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function UserStat(props) {
  const {
    stat,
    handleClose,
  } = props;

  return (
    <Card className="user-stat-modal">
      <CardHeader>
        <div className="title">{stat.name}</div>
        <FontAwesomeIcon className="clickable" icon="times" fixedWidth onClick={handleClose} />
      </CardHeader>
      <CardBody>
        body
      </CardBody>
    </Card>
  )
}

UserStat.propTypes = {

}

export default UserStat

