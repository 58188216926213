import React from 'react'
import PropTypes from 'prop-types'

function CardBody(props) {
  const { children } = props;
  return (
    <div className="card-body">
      {children}
    </div>
  )
}

CardBody.propTypes = {

}

export default CardBody
