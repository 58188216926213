import React, { Component } from 'react'
import PropTypes from 'prop-types'

import ListBody from './ListBody';
import ListHeader from './ListHeader';
import each from 'lodash/each';
import { exportJsonArrToExcel } from '../../../helpers/ExcelExportHelper';

function List(props) {
  const { headerOptions, gridOptions, rowData, isAllowSave, sheetName, fileName, setGridApi } = props;


  const saveTable = () => {
    let cols = gridOptions.columnDefs
    let data = rowData

    let sheetData = []
    each(data, (d) => {
      let row = {}
      each(cols, (c) => {
        row[c.headerName] = d[c.field]
      })
      sheetData.push(row)
    })

    exportJsonArrToExcel(
      {
        [sheetName]: sheetData
      },
      fileName,
      "xlsx"
    )
  }

  return (
    <div className="list-wrapper">
      {
        isAllowSave &&
        <div className="tip text-align-right text-underline">
          <span 
            className="clickable"
            onClick={saveTable}>
            Save Table
          </span>
        </div>
      }
      {
        headerOptions.enable &&
        <ListHeader headerOptions={headerOptions}></ListHeader>
      }
      <ListBody gridOptions={gridOptions} rowData={rowData} setGridApi={setGridApi}></ListBody>
    </div>
  )
}

List.propTypes = {

}

export default List