import find from 'lodash/find'
import { openConfirmModal } from './ConfirmModalHelper';
import store from '../redux/store';
import { updateUser, updateIsLoggedIn } from '../redux/actions';

export const checkIfModuleIsEnabledByKey = (user, key) => {
  // TODO: FOR DEV ONLY
  // return true;

  if (parseInt(user.acc_type) !== 5) {
    return true;
  }

  if (!user) {
    return false;
  }

  if (!key) {
    return false;
  }
  
  let modules = user.module;
  let targetModule = find(modules, {module_key: key});
  return !!targetModule;
}

export const getPortalLandingPage = (user) => {
  if (checkIfModuleIsEnabledByKey(user, 'aca_dashboard')) {
    return '/portal/dashboard'
  }
  return '/portal/post'
}

export const logoutAccount = () => {
  store.dispatch(updateUser({user: {}}));
  store.dispatch(updateIsLoggedIn({isLoggedIn: false}));
}

export const logoutConfirm = () => {
  openConfirmModal({
    title: 'Logout?',
    message: '',
    closeCallback: null,
    confirmCallback: () => {
      logoutAccount();
    }
  })
}