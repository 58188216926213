import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import Card from '../../utilities/card/Card'
import CardHeader from '../../utilities/card/CardHeader'
import { apiPost } from '../../../helpers/Api'
import CardBody from '../../utilities/card/CardBody'
import each from 'lodash/each'
import ChartBar from '../../utilities/chart/ChartBar'
import Tab from '../../utilities/tab/Tab'
import RangeIntervalFilter from './RangeIntervalFilter'
import { getSimpleDateDisplayByTimestampSecond, getChartMonthDateDisplayByTimestampSecond, getExportFileNameDisplay } from '../../../helpers/DateHelper'
import { last, map } from 'lodash'
import ChartLine from '../../utilities/chart/ChartLine'
import { toast } from '../../utilities/Notification/CustomToast';
import { exportJsonArrToExcel } from '../../../helpers/ExcelExportHelper'

const CategorySubTabKey = {
  latest: 'latest',
  cumulative: 'cumulative'
}

const CategorySubTabs = [
  {
    key: CategorySubTabKey.latest,
    name: 'Latest'
  },
  {
    key: CategorySubTabKey.cumulative,
    name: 'Cumulative'
  }
]

function CategorySubTab(props) {

  const [activeTab, setActiveTab] = useState(CategorySubTabs[0])

  const [currentParam, setCurrentParam] = useState(null)
  const [stat, setStat] = useState([])
  const [cumulativeStat, setCumulativeStat] = useState([])
  const [allCatName, setAllCatName] = useState([])

  const getCategorySubStat = (param) => {

    setCurrentParam(param)
    let cumulativeArr = []

    apiPost('/admin/post/category/subscription_stat', param)
      .then(({data}) => {
        each(data, (interval) => {
          let dateDisplay = getSimpleDateDisplayByTimestampSecond(interval.start_time)

          if (param.time_interval === 'month') {
            dateDisplay = getChartMonthDateDisplayByTimestampSecond(interval.start_time)
          }

          let intervalStat = {
            date: dateDisplay,
            ...interval.cumulative_subscription
          }
          cumulativeArr.push(intervalStat)

        })

        setCumulativeStat(cumulativeArr)

        // set latest
        let parsed = []
        each(last(cumulativeArr), (num, name) => {
          if (name !== 'date') {
            parsed.push({
              name: name,
              'Subscribed Users': num
            })
          }
        })
        parsed.sort((a, b) => {
          return a.name.toLowerCase().localeCompare(b.name.toLowerCase())
        })
        setStat(parsed)

        setAllCatName(map(parsed, 'name'))
      })
  }

  const exportCategorySubStat = (param) => {
    if (!currentParam || !cumulativeStat) {
      toast.warn("Please select Time Range and Interval, and click Get Statistics.")
      return;
    }
    let data = []
    each(cumulativeStat, (interval) => {
      let row = {}
      row["Date"] = interval.date;
      each(allCatName, (cat) => {
        row[cat] = interval[cat]
      })
      data.push(row)
    })
    exportJsonArrToExcel(
      {
        "Category Subscription": data
      },
      `stat_categorry_subscription_${getExportFileNameDisplay()}.xlsx`,
      "xlsx"
    )
  }

  return (
    <div>
      <Card>
        <CardHeader>
          <div className="title sub-title">
            Category Subscription
          </div>
          <div className="actions">
            <Tab tabs={CategorySubTabs} activeTab={activeTab} switchActiveTab={setActiveTab} />
          </div>
        </CardHeader>
        <RangeIntervalFilter 
          submitParams={(param) => getCategorySubStat(param)} 
          exportParams={(param) => exportCategorySubStat(param)} 
          isFullYear
        />
        <CardBody>
          {
            currentParam
            ?
            <>
              {
                activeTab.key === CategorySubTabKey.latest &&
                <>
                  {
                    stat.length > 0
                    ?
                    <ChartBar 
                      data={stat}
                      xKey='name'
                      yKey='Subscribed Users'
                      label='Subscribed Users'
                      chartTitle={`Category Subscription ${getSimpleDateDisplayByTimestampSecond(currentParam.start_time)} - ${getSimpleDateDisplayByTimestampSecond(currentParam.end_time)} (${currentParam.interval})`}
                      isShowLegend
                      isAllowSave
                    />
                    :
                    <div className="center-info-msg">
                      No data
                    </div>
                  }
                </>
              }
              {
                activeTab.key === CategorySubTabKey.cumulative &&
                <>
                  {
                    cumulativeStat.length > 0 
                    ?
                    <ChartLine 
                    data={cumulativeStat} 
                    xKey='date' 
                    yKeys={allCatName} 
                    chartTitle={`Category Subscription (Cumulative) ${getSimpleDateDisplayByTimestampSecond(currentParam.start_time)} - ${getSimpleDateDisplayByTimestampSecond(currentParam.end_time)} (${currentParam.interval})`}
                    isShowLegend 
                    isAllowSave />
                    :
                    <div className="center-info-msg">
                      No data
                    </div>
                  }
                </>
              }

            </>
            :
            <div className="center-info-msg">
              Please select Time Range and Interval, and click Get Statistics.
            </div>
          }
        </CardBody>
      </Card>
    </div>
  )
}

CategorySubTab.propTypes = {

}

export default CategorySubTab

