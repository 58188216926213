import PostSampleTransport from '../assets/post/sample_transport.png'
import PostSampleCoupon from '../assets/post/sample_coupon.png'
import PostSampleJetso from '../assets/post/sample_jetso.png'
import PostSampleNewShop from '../assets/post/sample_new_shop.png'


// export const TargetHost = "https://hkgcommunity.com";
// export const TargetHost = "https://aacomdev.teamnoteapp.com";
export const TargetHost = "";
export const TargetDomain = "hkaacom";
export const StaticPath = "/static/";

export const LikeEmojiString = '\ud83d\udc4d';

export const IdleTimePeriodMin = 5;


export const LOGIN_AUTH_TYPE = {
  TEAMNOTE: 'teamnote',
  SAML: 'saml',
  OAUTH: 'oauth'
}


export const MAX_FILE_SIZE = 25;

export const POST_SAMPLE_IMAGES = [
  PostSampleTransport,
  PostSampleCoupon,
  PostSampleJetso,
  PostSampleNewShop
]