import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import { connect } from 'react-redux'
import Card from '../../../utilities/card/Card';
import CardHeader from '../../../utilities/card/CardHeader';
import CardBody from '../../../utilities/card/CardBody';
import BasicInput from '../../../utilities/form/BasicInput';
import ImageInput from '../../../utilities/form/ImageInput';
import { apiPost, apiUploadFile } from '../../../../helpers/Api';
import { TargetHost, StaticPath, TargetDomain } from '../../../../helpers/Constants';
import UserDevice from './UserDevice';
import { toast } from '../../../utilities/Notification/CustomToast';
import { uploadLocalFileObjects, attachmentTypes, createLocalFileObject } from '../../../../helpers/FileHelper';
import FileInput from '../../../utilities/form/FileInput';
import { UserIdentity, getUserIdentityTypeDisplay } from '../list/UserList';
import each from 'lodash/each';
import { checkIfTimesInCorrectOrder } from '../../../../helpers/DateHelper';
import UserVerification from './UserVerification';

function UserSet(props) {
  const { match, basePath, history } = props;

  const userId = match.params.id;

  const title = !userId ? "Add New" : "User Detail";

  const [user, setUser] = useState({
    company_domain: TargetDomain,
    name: '',
    user_name: '',
    store_name: '',
    department_name: '',
    title: '',
    email: '',
    mobile: '',
    userGroups: [],
    status: null,
    acc_type: 5,
    localImageObjArr: [],
    uploadedImageSrcArr: [],
    department_code: 'USER'
  })

  const [devices, setDevices] = useState([])

  const updateUserInfo = (update) => {
    setUser({
      ...user,
      ...update
    })
  }

  const isCheckIdentityType = (i) => {
    // only show the 'Octopus Card No.' for SASV
    if (i.identity_type === UserIdentity.sasv) {
      return i.sasv.octopus_no
    }

    return null
  }

  const isCheckVerifiedDisplay = (expiryDate, identity) => {
    if (expiryDate !== null && checkIfTimesInCorrectOrder(new Date(expiryDate * 1000), new Date())) {
      // expiry < now
      return 'Expired';
    } 

    // return identity?.sasv.status || 'Verified'
    return 'Verified'
  }

  const getUserDetail = () => {
    let param = {
      uid: userId
    }

    apiPost('/admin/user', param)
      .then((resp) => {
        let user = resp.data;
        if (user.pic && user.pic.toLowerCase() !== 'none') {
          user.localImageObjArr = [createLocalFileObject({attachmentId: user.pic, name: user.pic})]
        }

        // identity
        each(user.aca_identity, (i) => {
          i.identityTypeDisplay = getUserIdentityTypeDisplay(i.identity_type)

          i.isVerifiedDisplay = '';
          if (i.is_verified === 1) {
            if ('sasv' in i) {
              i.expiry_date = i.sasv.rejected_at || i.sasv.suspended_at || i.sasv.deleted_at
            }

            i.isVerifiedDisplay = isCheckVerifiedDisplay(i.expiry_date, i)
          } else {
            i.isVerifiedDisplay = 'Waiting for verification';

            if (i.identity_type === UserIdentity.sasv) { 
              switch(i.sasv?.status) {
                case 'PENDING_APPROVAL':
                  i.isVerifiedDisplay = 'Waiting for verification';
                  break;
                case 'REJECTED':
                  i.isVerifiedDisplay = 'Rejected';
                  break;
                case 'SUSPENDED':
                  i.isVerifiedDisplay = 'Suspended';
                  break;
                case 'DELETED':
                  i.isVerifiedDisplay = 'Deleted';
                  break;
                default:
                  i.isVerifiedDisplay = 'Waiting for verification';
                  break;
              }
            }
          }

          i.octopusCardNo = isCheckIdentityType(i)
        })

        updateUserInfo(user);
      })
      .catch((err) => {
        console.error(err);
      })
  }

  const getUserDevice = () => {
    let param = {
      user_id: userId
    }
    apiPost('/admin/device', param)
      .then((resp) => {
        setDevices(resp.data);
      })
      .catch((err) => {
        console.error(err);
      })
  }

  const checkInput = () => {
    if (!user.name) {
      toast.warn("No Username");
      return false;
    }

    if (!user.user_name) {
      toast.warn("No Login No.");
      return false;
    }

    return true;
  }

  const uploadPic = (index, callback) => {
    uploadLocalFileObjects({
      arr: user.localImageObjArr,
      index: index,
      uploadedIds: [],
      callbackWithIds: (ids) => {
        user.uploadedImageSrcArr = ids
        callback()
      }
    })
  }

  const saveUser = () => {
    let theUser = {
      company_domain: user.company_domain,
      name: user.name,
      user_name: user.user_name,
      department_name: user.department_name,
      // store_name: user.store_name,
      title: user.title,
      email: user.email,
      mobile: user.mobile,
      pic: user.uploadedImageSrcArr[0],
      acc_type: user.acc_type,
      department_code: user.department_code
    }

    if (user.user_id) {
      theUser.user_id = user.user_id
    }

    apiPost('/admin/user/set', theUser)
      .then((resp) => {
        history.push(basePath)
      })
      .catch((err) => {
        let errBody = err.response.data;
        if (errBody.indexOf("InvalidOperationError") !== -1) {
          toast.error("User exists already.")
        }
      })
  }

  const saveNow = () => {
    if (!checkInput()) {
      return;
    }
    uploadPic(0, () => saveUser());
  }

  const deleteUserDevice = (device) => {
    let param = {
      device_token: JSON.stringify([device.device_token])
    }
    apiPost('/admin/device/delete', param)
      .then((resp) => {
        history.push(match.url)
      })
      .catch((err) => {
        console.error(err);
      })
  }

  const saveIdentity = (identity) => {
    console.log(identity)
    let param = {
      identity_id: identity.identity_id,
      identity_key: identity.identity_key,
      is_verified: identity.isVerified ? 1 : 0,
      expiry_date: identity.isVerified ? identity.expiryDate[0].getTime() / 1000 : null
    }

    apiPost('/admin/hkaa_community/identity/set', param)
      .then((resp) => {
        history.push(match.url)
      })
      .catch((err) => {
        console.error(err);
      })
  }

  const lockOrUnlockUser = (type) => {
    let param = {
      uid: user.user_id
    }
    apiPost(`/admin/user/${type}`, param)
      .then((resp) => {
        getUserDetail()
      })
  }

  const backNow = () => {
    history.push(`${basePath}/list`)
  }
  
  useEffect(() => {
    if (userId) {
      getUserDetail();
      getUserDevice();
    }
  }, []);

  return (
    <div className="item-set-container">
      <div className="full-width padding-bottom-30">
        <Card>
          <CardHeader>
            <div className="title">
              {title}
            </div>
            <div className="actions">
              {
                (user.user_id && user.locked === 1) &&
                <button className="button success" onClick={()=>lockOrUnlockUser('unlock')}>
                  <span>Unlock</span>
                </button>
              }
              {
                (user.user_id && user.locked === 0) &&
                <button className="button danger" onClick={()=>lockOrUnlockUser('lock')}>
                  <span>Lock</span>
                </button>
              }
              <button className="button default" onClick={backNow}>
                <span>Back</span>
              </button>
              <button className="button primary" onClick={saveNow}>
                <span>Save</span>
              </button>
            </div>
          </CardHeader>
        </Card>
        <div className="padding-bottom-30"></div>
        <Card>
          <CardHeader>
            <div className="title sub-title">
              User Information
            </div>
          </CardHeader>
          <CardBody>
            <BasicInput type="text" value={user.name} label="Name" onChange={(val) => updateUserInfo({name: val})} isDisabled={!!userId}/>
            {/* <BasicInput type="text" value={user.user_name} label="Username(Mobile No./Email)" onChange={(val) => updateUserInfo({user_name: val})} isDisabled={!!userId}/> */}
            {/* <BasicInput type="text" value={user.store_name} label="Company" onChange={(val) => updateUserInfo({store_name: val})} /> */}
            {/* <BasicInput type="text" value={user.department_name} label="Department" onChange={(val) => updateUserInfo({department_name: val})} /> */}
            {/* <BasicInput type="text" value={user.title} label="Title" onChange={(val) => updateUserInfo({title: val})} /> */}
            <BasicInput type="text" value={user.email} label="Email" onChange={(val) => updateUserInfo({email: val})} isDisabled={!!userId}/>
            <BasicInput type="text" value={user.mobile} label="Mobile No." onChange={(val) => updateUserInfo({mobile: val})} isDisabled={!!userId}/>
            {/* <div className="input-container group">
              <span className="input-label">
                Acc Type
              </span>
              <div className="form-input">
                {
                  user.acc_type === 0 &&
                  <div className="radio-container">
                    <label>
                      <input type="radio" value="0" checked={user.acc_type === 0} 
                      onChange={(e) => updateUserInfo({acc_type: 0})} /> 
                      Super Admin
                    </label>
                  </div>
                }
                <div className="radio-container">
                  <label>
                    <input type="radio" value="1" checked={user.acc_type === 1} 
                    onChange={(e) => updateUserInfo({acc_type: 1})} /> 
                    Corporate Admin
                  </label>
                </div>
                <div className="radio-container">
                  <label>
                    <input type="radio" value="5" checked={user.acc_type === 5} 
                    onChange={(e) => updateUserInfo({acc_type: 5})} /> 
                    User Contact
                  </label>
                </div>
              </div>
            </div> */}
            {
              (user.account_tags && user.account_tags.length > 0)
              &&
              <div className="selected-conditions">
                {
                  user.account_tags.map((tag, index) => (
                    <div className="condition" key={index}>
                      {tag.tag}
                    </div>
                  ))
                }
              </div>
            }
          </CardBody>
        </Card>
        {/* <div className="padding-bottom-30"></div> */}
        {/* <Card>
          <CardHeader>
            <div className="title sub-title">
              Profile Image
            </div>
          </CardHeader>
          <CardBody>
            <FileInput 
              localFileObjArr={user.localImageObjArr}
              onLocalFileObjArrChange={(arr) => updateUserInfo({localImageObjArr: arr})}
              maxCount={1}
              acceptTypes={attachmentTypes.imageTypes}
              text="Upload"
              isFileNameOnly={false}
              extraTips="Recommended ratio: 1:1; Recommended dimension: 800x800 px"
            />
          </CardBody>
        </Card> */}
        {/* <Card>
          <CardHeader>
            <div className="title sub-title">
              User Group
            </div>
          </CardHeader>
          <CardBody>
            user group picker
          </CardBody>
        </Card> */}
        {
          user.user_id &&
          <div>
            <div className="padding-bottom-30" />
            <UserVerification aca_identity={user.aca_identity} saveIdentity={saveIdentity} />
          </div>
        }
        {
          user.user_id && 
          <div>
            <div className="padding-bottom-30"></div>
            <UserDevice devices={devices} deleteUserDevice={deleteUserDevice} />
          </div>
        }
      </div>
    </div>
  )
}


UserSet.propTypes = {

}

const mapStateToProps = (state) => ({
  
})

const mapDispatchToProps = {
  
}

export default connect(mapStateToProps, mapDispatchToProps)(UserSet)

