import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import Select from 'react-select'

function SelectInput(props) {
  const {
    options,
    value,
    onChange,
    isDisabled,
  } = props;

  const [selectedOption, setSelectedOption] = useState(value);
  const handleOnChange = (option) => {
    setSelectedOption(option);
    onChange(option);
  }

  useEffect(() => {
    setSelectedOption(value);
  }, [value]);

  return (
    <div className="select-input-container">
      <Select 
        className="select-input"
        classNamePrefix="select-input"
        value={selectedOption} 
        options={options} 
        onChange={handleOnChange} 
        isDisabled={isDisabled}
      />
    </div>
  )
}

SelectInput.propTypes = {

}

export default SelectInput

