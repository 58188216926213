import Dashboard from "./dashboard/Dashboard";
import Post from "./post/Post";
import PostDashboard from "./post/dashboard/PostDashboard";
import PostList from "./post/list/PostList";
import PostSet from "./post/set/PostSet";
import Event from './event/Event';
import PostCategory from './category/PostCategory';
import User from "./user/User";
import Survey from "./survey/Survey";
import Promotion from "./promotion/Promotion";
import Setting from "./setting/Setting";
import SettingCompany from "./setting/company/SettingCompany";
import SettingPhoneBook from "./setting/phoneBook/SettingPhoneBook";
import SettingJargonBuster from "./setting/jargonBuster/SettingJargonBuster";
import SettingAccPage from "./setting/accPage/SettingAccPage";
import UserGroup from "./userGroup/UserGroup";
import Admin from "./admin/Admin";
import SettingPost from "./setting/post/SettingPost";
import PostTemplate from "./postTemplate/PostTemplate";
import PostTemplateList from "./postTemplate/list/PostTemplateList";
import PostTemplateSet from "./postTemplate/set/PostTemplateSet";
import Notification from "./notifications/Notification";

export const SettingRouteList = [
  {
    path: "/portal/setting/post",
    name: "Post",
    nameId: 'setting.post',
    moduleKey: 'setting',
    main: SettingPost
  },
  {
    path: "/portal/setting/acc_page",
    name: "ASC Page",
    nameId: 'setting.accPage',
    moduleKey: "acc_page_item",
    main: SettingAccPage
  },
  {
    path: "/portal/setting/company",
    name: "Company",
    nameId: 'setting.company',
    moduleKey: "aca_company",
    main: SettingCompany
  },
  {
    path: "/portal/setting/phone_book",
    name: "Airport Phone Book",
    nameId: 'setting.phoneBook',
    moduleKey: "aca_phone_book",
    main: SettingPhoneBook
  },
  {
    path: "/portal/setting/jargon_buster",
    name: "Jargon Buster",
    nameId: 'setting.jargonBuster',
    moduleKey: "aca_jargon_buster",
    main: SettingJargonBuster
  },
  {
    path: "/portal/setting/tnc",
    name: "T&C",
    nameId: 'setting.tnc',
    moduleKey: "tnc",
    main: SettingJargonBuster
  }
]

export const Routes = [
  {
    path: "/portal/dashboard", 
    name: "Dashboard",
    nameId: 'dashboard.title',
    main: Dashboard,
    moduleKey: "aca_dashboard",
    iconClass: 'menu-dashboard'
  },
  {
    path: "/portal/post", 
    name: "Post",
    nameId: 'post.title',
    main: Post,
    moduleKey: "post",
    iconClass: 'menu-posts',
    childrens: [
      {
        path: "/portal/post/dashboard",
        name: "Dashboard",
        nameId: 'post.dashboard',
        moduleKey: "post",
        main: PostDashboard
      },
      {
        path: "/portal/post/list",
        name: "All Posts",
        nameId: 'post.allPosts',
        moduleKey: "post",
        main: PostList
      },
      {
        path: "/portal/post/add",
        name: "Add New",
        nameId: 'post.addNew',
        moduleKey: "post",
        main: PostSet
      }
    ]
  },
  {
    path: "/portal/post-template",
    name: "Post Template",
    nameId: 'post_template.title',
    main: PostTemplate,
    moduleKey: "post_template",
    iconClass: 'menu-post-templates',
    childrens: [
      {
        path: "/portal/post-template/list",
        name: "All Templates",
        nameId: 'post_template.allPostTemplates',
        moduleKey: "post_template",
        main: PostTemplateList
      },
      {
        path: "/portal/post-template/add",
        name: "Add New",
        nameId: 'post_template.addNew',
        moduleKey: "post_template",
        main: PostTemplateSet
      }
    ]
  },
  {
    path: "/portal/notification",
    name: "Notifications",
    nameId: 'notification.title',
    main: Notification,
    moduleKey: "notifications",
    iconClass: 'menu-notifications'
  },
  {
    path: "/portal/category",
    name: "Categories",
    nameId: 'category.title',
    main: PostCategory,
    moduleKey: "post_category",
    iconClass: 'menu-categories'
  },
  {
    path: "/portal/event", 
    name: "Event",
    nameId: 'event.title',
    main: Event,
    moduleKey: "event",
    iconClass: 'menu-events',
    childrens: [
      // {
      //   path: "/portal/event/list",
      //   name: "All Events",
      //   nameId: 'event.allEvents',
      //   moduleKey: "event",
      //   main: EventList
      // },
      // {
      //   path: "/portal/event/add",
      //   name: "Add New",
      //   nameId: 'event.addNew',
      //   moduleKey: "event",
      //   main: EventSet
      // }
    ]
  },
  {
    path: "/portal/promotion", 
    name: "Promotion",
    nameId: 'promotion.title',
    main: Promotion,
    moduleKey: "promotion",
    iconClass: 'menu-promotions',
    childrens: [
      // {
      //   path: "/portal/promotion/list",
      //   name: "All Survyes",
      //   nameId: 'promotion.allPromotions',
      //   main: PromotionList
      // },
      // {
      //   path: "/portal/promotion/add",
      //   name: "Add New",
      //   nameId: 'promotion.addNew',
      //   main: PromotionSet
      // }
    ]
  },
  {
    path: "/portal/survey", 
    name: "Survey",
    nameId: 'survey.title',
    main: Survey,
    moduleKey: "questionnaire",
    iconClass: 'menu-surveys',
    childrens: [
      // {
      //   path: "/portal/survey/list",
      //   name: "All Survyes",
      //   nameId: 'survey.allSurveys',
      //   moduleKey: "questionnaire",
      //   main: SurveyList
      // },
      // {
      //   path: "/portal/survey/add",
      //   name: "Add New",
      //   nameId: 'survey.addNew',
      //   moduleKey: "questionnaire",
      //   main: SurveySet
      // }
    ]
  },
  {
    path: '/portal/admin',
    name: 'Admin',
    main: Admin,
    moduleKey: 'user',
    iconClass: 'menu-users'
  },
  {
    path: "/portal/user",
    name: "User",
    nameId: 'user.title',
    main: User,
    moduleKey: "user",
    iconClass: 'menu-users',
    childrens: [
      // {
      //   path: "/portal/user/list",
      //   name: "All Users",
      //   nameId: 'user.allUsers',
      //   main: UserList
      // },
      // {
      //   path: "/portal/user/add",
      //   name: "Add New",
      //   nameId: 'user.addNew',
      //   main: UserSet
      // },
      // {
      //   path: "/portal/user/usergroup/list",
      //   name: "User Group",
      //   nameId: 'user.userGroup',
      //   main: UserGroupList
      // },
      // {
      //   path: "/portal/user/role/list",
      //   name: "Roles",
      //   nameId: 'user.roles',
      //   main: UserRoleList
      // }
    ]
  },
  {
    path: "/portal/usergroup",
    name: "User Group",
    main: UserGroup,
    moduleKey: "user_group_manage",
    iconClass: 'menu-users',
    childrens: [
      // {
      //   path: "/portal/usergroup/list",
      //   name: "All User Groups",
      //   main: UserGroupList
      // },
      // {
      //   path: "/portal/usergroup/add",
      //   name: "Add New",
      //   main: UserGroupSet
      // },
    ]
  },
  {
    path: "/portal/setting", 
    name: "Setting",
    nameId: 'setting.title',
    main: Setting,
    moduleKey: "setting",
    iconClass: 'menu-settings',
    childrens: SettingRouteList
  },
]