import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import Card from '../../../utilities/card/Card';
import CardHeader from '../../../utilities/card/CardHeader';
import CardBody from '../../../utilities/card/CardBody';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DateRenderer from '../../../utilities/list/DateRenderer';
import List from '../../../utilities/list/List';
import { searchFilter } from '../../../../helpers/SearchHelper';

function PromotionStat(props) {
  const {
    promotion,
    stat,
    handleClose
  } = props;

  const [originalData, setOriginalData] = useState(stat);
  const [listKeyword, setListKeyword] = useState('');


  const [headerOptions, setHeaderOptions] = useState({
    enable: true,
    isSearch: true,
    searchableFields: ['account.name', 'account.user_name'],
    filters: [
      // {value: 'all', label: "All Categories"},
    ],
    onHeaderChange: (keyword, filter) => {
      setListKeyword(keyword);
    }
  })


  const columns = [
    { 
      headerName: "Redeem Time", 
      field: "timestamp",
      sort: 'desc',
      cellRenderer: 'dateRenderer'
    },
    { 
      headerName: "Name", 
      field: "account.name"
    },
    { 
      headerName: "Login No.", 
      field: "account.user_name"
    }
  ];

  const [gridOptions, setGridOptions] = useState({
    columnDefs: columns,
    frameworkComponents: {
      dateRenderer: DateRenderer
    }
  });

  const [rowData, setRowData] = useState([...originalData]);

  const search = () => {
    let filtered = [ ...originalData ];

    filtered = searchFilter(filtered, headerOptions.searchableFields, listKeyword);

    setRowData(filtered);
  }

  useEffect(() => {
    search()
  }, [listKeyword])

  return (
    <div>
      <Card>
        <CardHeader>
          <div className="title">{promotion.name}</div>
          <FontAwesomeIcon className="clickable" icon="times" fixedWidth onClick={handleClose} />
        </CardHeader>
        <CardBody>
          <div style={{height: '60vh'}}>
            <List 
              headerOptions={headerOptions}
              gridOptions={gridOptions}
              rowData={rowData}
            />
          </div>
        </CardBody>
      </Card>
    </div>
  )
}

PromotionStat.propTypes = {

}

export default PromotionStat

