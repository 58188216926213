// ===== Login =====
export const updateIsLoggedIn = (payload) => ({
  type: "updateIsLoggedIn",
  payload
})

export const updateUser = (payload) => ({
  type: "updateUser",
  payload
})

export const setIsLoadingApi = (payload) => ({
  type: "setIsLoadingApi",
  payload
})

export const changeLanguage = (payload) => ({
  type: "changeLanguage",
  payload
})

export const updateCronjobList = (payload) => ({
  type: "updateCronjobList",
  payload
})

export const setConfirmModalState = (payload) => ({
  type: "setConfirmModalState",
  payload
})
