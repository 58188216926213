import React, { useState, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.core.css'
import 'react-quill/dist/quill.snow.css'

function HtmlInput(props) {

  const {
    value,
    placeholder,
    onChange,
    label,
    isHideLabel,
    id,
  } = props;

  const [inputValue, setInputValue] = useState(value);
  const onInputChange = (content, delta, source, editor) => {
    if (editor.getLength() === 1) {
      setInputValue('');
    } else {
      setInputValue(content)
    }
  }

  useEffect(() => {
    onChange(inputValue);
  }, [inputValue])

  useEffect(() => {
    setInputValue(value)
  }, [value])

  let options = {
    placeholder: placeholder,
    theme: 'snow',
    modules: {
      toolbar: [
        ['bold', 'italic', 'underline', 'link']
      ]
    },
  };


  return (
    <div className="input-container vertical">
      {
        !isHideLabel &&
        <span className="input-label">
          {label}
        </span>
      }
      <div className="html-input-container">
        <ReactQuill 
          id={id}
          className="editor"
          value={inputValue} 
          onChange={onInputChange} 
          theme={options.theme}
          modules={options.modules}
          placeholder={options.placeholder}
          bounds={`#${id}`}>
        </ReactQuill>
      </div>
    </div>
  )
}

HtmlInput.propTypes = {

}

export default HtmlInput

