import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import Flatpickr from 'react-flatpickr'

function DateRangeInput(props) {
  const {
    value,
    onChange,
    className
  } = props;

  const [date, setDate] = useState(value);

  const handleSelect = (date) => {
    setDate(date);
    if (onChange) {
      onChange(date);
    }
  }

  useEffect(() => {
    setDate(value);
  }, [value]);

  return (
    <div className={`date-range-input-wrapper ${className}`}>
      <Flatpickr
        className="form-input"
        placeholder="Select Range..."
        value={date}
        onChange={handleSelect} 
        options={{
          mode: 'range',
          showMonths: 2
        }}
      />
    </div>
  )
}

DateRangeInput.propTypes = {

}

export default DateRangeInput

