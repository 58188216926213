import { combineReducers } from 'redux';

import account from './reducers/account'
import loading from './reducers/loading'
import language from './reducers/language'
import cronjob from './reducers/cronjob'
import confirm from './reducers/confirm'

export default combineReducers({
  account,
  loading,
  language,
  cronjob,
  confirm,
})