import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Card from '../../utilities/card/Card'
import RangeIntervalFilter from './RangeIntervalFilter'
import { apiPost, apiExportFile } from '../../../helpers/Api'
import { getExportFileNameDateRangeBySec, getSimpleDateDisplayByTimestampSecond, getChartMonthDateDisplayByTimestampSecond } from '../../../helpers/DateHelper'
import Tab from '../../utilities/tab/Tab'
import CardHeader from '../../utilities/card/CardHeader'
import CardBody from '../../utilities/card/CardBody'
import each from 'lodash/each'
import ChartLine from '../../utilities/chart/ChartLine'

const UserStatTabKey = {
  active: 'active',
  install: 'install',
  cumulative: 'cumulative'
}

const UserStatTabs = [
  {
    key: UserStatTabKey.active,
    name: 'Active User'
  },
  {
    key: UserStatTabKey.install,
    name: 'User Installation'
  },
  {
    key: UserStatTabKey.cumulative,
    name: 'User Installation (Cumulative)'
  }
]

function UserStatTab(props) {

  const [activeTab, setActiveTab] = useState(UserStatTabs[0])

  const [currentParam, setCurrentParam] = useState(null)
  const [activeUserData, setActiveUserData] = useState([])
  const [userInstallData, setUserInstallData] = useState([])
  const [cumulativeUserInstallData, setCumulativeUserInstallData] = useState([])

  const getUserStat = (param) => {
    let activeArr = []
    let installArr = []
    let cumulativeArr = []
    setCurrentParam(param)

    apiPost('/admin/hkaa_community/stat/install', param)
      .then(({data}) => {
        each(data, (interval) => {
          let dateDisplay = getSimpleDateDisplayByTimestampSecond(interval.start_time)

          if (param.time_interval === 'month') {
            dateDisplay = getChartMonthDateDisplayByTimestampSecond(interval.start_time)
          }

          activeArr.push({
            date: dateDisplay,
            'Active Users': interval.active_users
          })

          installArr.push({
            date: dateDisplay,
            'iOS': interval.ios,
            'Android': interval.android
          })

          cumulativeArr.push({
            date: dateDisplay,
            'iOS': interval.ios_cumulative,
            'Android': interval.android_cumulative
          })
        })

        setActiveUserData(activeArr)
        setUserInstallData(installArr)
        setCumulativeUserInstallData(cumulativeArr)
      })
  }

  const exportUserStat = (param) => {
    param = {
      ...param,
      ...{
        jobName: 'Export User Statistics',
        isDisplay: true,
        filename: `user_stat_${getExportFileNameDateRangeBySec(param.start_time, param.end_time)}`
      }
    }
    apiExportFile('/admin/hkaa_community/stat/install/export/', param)
  }

  return (
    <div>
      <Card>
        <CardHeader>
          <div className="title sub-title">
            User
          </div>
          <div className="actions">
            <Tab tabs={UserStatTabs} activeTab={activeTab} switchActiveTab={setActiveTab} />
          </div>
        </CardHeader>
        <RangeIntervalFilter 
          submitParams={(param) => getUserStat(param)} 
          exportParams={(param) => exportUserStat(param)} 
          isFullYear
        />
        <CardBody>
          {
            currentParam 
            ?
            <>
              {/* <div className="user-stat-header">
                {getSimpleDateDisplayByTimestampSecond(currentParam.start_time)} - {getSimpleDateDisplayByTimestampSecond(currentParam.end_time)} ({currentParam.interval})
              </div> */}
              {
                activeTab.key === UserStatTabKey.active &&
                <>
                  {
                    activeUserData.length > 0 
                    ?
                    <ChartLine 
                      data={activeUserData} 
                      xKey='date' 
                      yKey='Active Users' 
                      chartTitle={`Active Users ${getSimpleDateDisplayByTimestampSecond(currentParam.start_time)} - ${getSimpleDateDisplayByTimestampSecond(currentParam.end_time)} (${currentParam.interval})`}
                      isShowLegend 
                      isAllowSave />
                    :
                    <div className="center-info-msg">
                      No data
                    </div>
                  }
                </>
              }
              {
                activeTab.key === UserStatTabKey.install &&
                <>
                  {
                    userInstallData.length > 0 
                    ?
                    <ChartLine 
                    data={userInstallData} 
                    xKey='date' 
                    yKeys={['iOS', 'Android']} 
                    chartTitle={`User Installation ${getSimpleDateDisplayByTimestampSecond(currentParam.start_time)} - ${getSimpleDateDisplayByTimestampSecond(currentParam.end_time)} (${currentParam.interval})`}
                    isShowLegend 
                    isAllowSave />
                    :
                    <div className="center-info-msg">
                      No data
                    </div>
                  }
                </>
              }
              {
                activeTab.key === UserStatTabKey.cumulative &&
                <>
                  {
                    cumulativeUserInstallData.length > 0 
                    ?
                    <ChartLine 
                    data={cumulativeUserInstallData} 
                    xKey='date' 
                    yKeys={['iOS', 'Android']} 
                    chartTitle={`User Installation (Cumulative) ${getSimpleDateDisplayByTimestampSecond(currentParam.start_time)} - ${getSimpleDateDisplayByTimestampSecond(currentParam.end_time)} (${currentParam.interval})`}
                    isShowLegend 
                    isAllowSave />
                    :
                    <div className="center-info-msg">
                      No data
                    </div>
                  }
                </>
              }
            </>
            :
            <div className="center-info-msg">
              Please select Time Range and Interval, and click Get Statistics.
            </div>
          }
        </CardBody>
      </Card>
    </div>
  )
}

UserStatTab.propTypes = {

}

export default UserStatTab

