import React from 'react'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DateRenderer from '../list/DateRenderer';

function SimpleTable(props) {
  const {
    headers,
    data,
    keys,
    isFarRight,
    history,
    dateCols,
    isStat,
    hasStatTotal
  } = props;

  let classes = ["simple-table"];
  if (isFarRight) {
    classes.push("right")
  }
  if (isStat) {
    classes.push("stat")
  }
  if (hasStatTotal) {
    classes.push("stat-has-total")
  }

  return (
    <div className={classes.join(' ')}>
      <table>
        <thead>
          <tr>
            {
              headers.map((header, index) => (
                <th key={index}>
                  {header}
                </th>
              ))
            }
          </tr>
        </thead>
        <tbody>
          {
            data.map((item, index) => (
              <tr key={index}>
                {
                  keys.map((key, index) => {
                    if (key === 'action') {
                      if (item.post_id) {
                        return (
                          <td key={index}>
                            <FontAwesomeIcon 
                              className="tool-button clickable hover-opacity" 
                              icon="search-plus" 
                              onClick={() => {
                                history.push(`/portal/post/edit/${item.post_id}`)
                              }} 
                            />
                          </td>
                        )
                      }
                    }

                    if (dateCols && dateCols.indexOf(key) !== -1) {
                      return (
                        <td key={index}>
                          <DateRenderer value={item[key]} />
                        </td>
                      )
                    }

                    return (
                      <td key={index}>
                        {item[key]}
                      </td>
                    )
                  })
                }
              </tr>
            ))
          }
        </tbody>
      </table>
    </div>
  )
}

SimpleTable.propTypes = {

}

export default SimpleTable

