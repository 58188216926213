export const ChartColors = ['#407BFF', '#ff7f0e', '#ffbb78', '#2ca02c', '#98df8a', '#d62728', '#ff9896', '#9467bd', '#c5b0d5', '#8c564b', '#c49c94', '#e377c2', '#f7b6d2', '#7f7f7f', '#c7c7c7', '#bcbd22', '#dbdb8d', '#17becf', '#9edae5']


export const getChartColorByIndex = (index) => {
  return ChartColors[index % ChartColors.length]
}

export const ChartBaseOptions = {
  legend: {
    display: false,
    position: "bottom"
  },
  scales: {
    yAxes: [
      {
        ticks: {
          suggestedMin: 0,
          precision: 0
        }
      }
    ]
  },
  tooltips: {
    mode: 'index',
    intersect: false,
  },
  hover: {
    mode: 'nearest',
    intersect: true
  },
  title: {
    fontSize: 16,
    fontFamily: 'Roboto'
  },
  layout: {
    padding: {
      left: 5,
      right: 5,
      top: 5,
      bottom: 5
    }
  }
}