import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Card from '../../utilities/card/Card'
import CardHeader from '../../utilities/card/CardHeader'
import RangeIntervalFilter from './RangeIntervalFilter'
import CardBody from '../../utilities/card/CardBody'
import { apiPost } from '../../../helpers/Api'
import ChartLine from '../../utilities/chart/ChartLine'
import map from 'lodash/map'
import each from 'lodash/each'
import { getSimpleDateDisplayByTimestampSecond, getChartMonthDateDisplayByTimestampSecond, getDateByFormatAndDateObj, getExportFileNameDisplay } from '../../../helpers/DateHelper'
import { exportJsonArrToExcel } from '../../../helpers/ExcelExportHelper'

function PostSearchTab(props) {
  
  const [currentParam, setCurrentParam] = useState(null)

  const [stat, setStat] = useState([])

  const getPostSearchStat = (param) => {
    setCurrentParam(param)

    apiPost('/admin/hkaa_community/stat/searching', param)
      .then(({data}) => {
        each(data, (d) => {
          d.date = getSimpleDateDisplayByTimestampSecond(d.start_time)
          if (param.time_interval === 'month') {
            d.date = getChartMonthDateDisplayByTimestampSecond(d.start_time)
          }
          d['No. of search'] = d.no_of_search
        })
        setStat(data)
      })
  }

  const exportPostSearchStat = (param) => {
    if (!stat || stat.length === 0) {
      return;
    } 
    let data = []

    each(stat, (interval) => {
      let row = {}
      row["Date"] = interval.date
      row["No. of search"] = interval["No. of search"]
      data.push(row)
    })

    exportJsonArrToExcel(
      {
        "Post Searching": data
      },
      `stat_post_searching_${getExportFileNameDisplay()}.xlsx`,
      "xlsx"
    )
  }



  return (
    <div>
      <Card>
        <CardHeader>
          <div className="title sub-title">
            Post Searching
          </div>
        </CardHeader>
        <RangeIntervalFilter 
          submitParams={(param) => getPostSearchStat(param)}
          exportParams={(param) => exportPostSearchStat(param)}
          isFullYear
        />
        <CardBody>
          {
            currentParam
            ?
            <>
              {
                stat.length > 0
                ?
                <ChartLine 
                  data={stat}
                  xKey='date'
                  yKey='No. of search'
                  chartTitle={`Number of Post searching ${getSimpleDateDisplayByTimestampSecond(currentParam.start_time)} - ${getSimpleDateDisplayByTimestampSecond(currentParam.end_time)} (${currentParam.interval})`}
                  isShowLegend
                  isAllowSave
                />
                :
                <div className="center-info-msg">
                  No data
                </div>
              }
            </>
            :
            <div className="center-info-msg">
              Please select Time Range and Interval, and click Get Statistics.
            </div>
          }
        </CardBody>
      </Card>
    </div>
  )
}

PostSearchTab.propTypes = {

}

export default PostSearchTab

