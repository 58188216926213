import React, { useState } from 'react'
import PropTypes from 'prop-types'
import CardHeader from '../card/CardHeader'
import Card from '../card/Card'
import CardBody from '../card/CardBody'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Dialog, DialogContent } from '@material-ui/core'
import PostSet from '../../portal/post/set/PostSet'
import { toast } from '../../utilities/Notification/CustomToast';

function LinkagePost(props) {
  const {
    targetType,
    targetId,
    createPostCallback,
  } = props;

  const [isNewPostModalOpen, setIsNewPostModalOpen] = useState(false)
  const onSaveCallback = (postId) => {
    setIsNewPostModalOpen(false)
    toast.info("New post is created")
    if (createPostCallback) {
      createPostCallback(postId)
    }
  }

  return (
    <span className="linkage-post">
      <button className="button default" onClick={()=>setIsNewPostModalOpen(true)}>
        <span>Create Post Linkage</span>
      </button>
      {/* <Card>
        <CardHeader>
          <div className="title sub-title">
            Linkage
          </div>
        </CardHeader>
        <CardBody>
          <div className="post-add text-align-right clickable hover-opacity" 
            onClick={()=>setIsNewPostModalOpen(true)}
          >
            Add New Post
            <FontAwesomeIcon icon="plus" fixedWidth />
          </span>
        </CardBody>
      </Card> */}
      <Dialog
        open={isNewPostModalOpen}
        onClose={()=>setIsNewPostModalOpen(false)}
        fullWidth={true}
        maxWidth="lg"
        scroll="paper"
      >
        <CardHeader>
          <div className="title">
            Add New Post
          </div>
          <FontAwesomeIcon className="clickable" icon="times" fixedWidth onClick={()=>setIsNewPostModalOpen(false)} />
        </CardHeader>
        <DialogContent dividers>
          <PostSet 
            preselectedLinkage={
              {
                target_type: targetType,
                target_id: targetId
              }
            }
            onSaveCallback={onSaveCallback}
          />
        </DialogContent>
      </Dialog>
    </span>
  )
}

LinkagePost.propTypes = {

}

export default LinkagePost

