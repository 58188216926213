import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import toast from '../Notification/CustomToast';

function TextAreaInput(props) {
  const {
    value,
    placeholder,
    onChange,
    rows,
    label,
    isHideLabel,
    limit,
    disabled
  } = props;

  let lengthErrorId = 'length-error';

  const [inputValue, setInputValue] = useState(value);
  const onInputChange = (event) => {
    setInputValue(event.target.value);
  }

  useEffect(() => {
    if (inputValue.length > limit) {
      if (!toast.isActive(lengthErrorId)) {
        toast.warn(`Invalid ${placeholder} (Exceeds length limit)`, {toastId: lengthErrorId})
      }
    } else {
      toast.dismiss(lengthErrorId)
    }
    onChange(inputValue);
  }, [inputValue]);


  useEffect(() => {
    setInputValue(value);
  }, [value]);

  return (
    <div>
      <div className="input-container vertical">
        {
          !isHideLabel &&
          <span className="input-label">
            {label}
          </span>
        }
        <textarea className="form-input" value={inputValue} placeholder={placeholder} onChange={onInputChange} rows={rows} disabled={disabled}></textarea>
      </div>
      <div className="border-bottom"></div>
      {
        limit &&
        <div className={`tip full-width text-align-right ${inputValue.length > limit ? 'red-text' : ''}`}>
          ({inputValue.length}/{limit})
        </div>
      }
    </div>
  )
}

TextAreaInput.propTypes = {

}

export default TextAreaInput

