import React from 'react'
import PropTypes from 'prop-types'

import { connect } from 'react-redux'

import { Switch, Route, Redirect } from 'react-router-dom'
import UserList from './list/UserList'
import UserSet from './set/UserSet'
// import UserGroupList from './userGroup/UserGroupList'
// import UserGroupSet from './userGroup/UserGroupSet'
// import UserRoleList from './role/UserRoleList'
// import UserRoleSet from './role/UserRoleSet'

export const UserStatus = {
  all: "All",
  registered: "Registered",
  not_yet_registered: "Not Yet Registered",
  suspended: "Suspended",
  locked: "Locked"
}

function User(props) {
  const { match } = props

  return (
    <div className="page-container">
      <Switch>
        <Route path={`${match.url}/list`} 
          render={props => <UserList {...props} basePath={match.url} />}/>
        <Route path={`${match.url}/add`} 
          render={props => <UserSet {...props} basePath={match.url} />}/>
        <Route path={`${match.url}/edit/:id`} 
          render={props => <UserSet {...props} basePath={match.url} />}/>
{/* 
        <Route path={`${match.url}/usergroup/list`} 
          component={props => <UserGroupList {...props} basePath={match.url} />}/>
        <Route path={`${match.url}/usergroup/add`} 
          component={props => <UserGroupSet {...props} basePath={match.url} />}/>
        <Route path={`${match.url}/usergroup/edit/:id`} 
          component={props => <UserGroupSet {...props} basePath={match.url} />}/>

        <Route path={`${match.url}/role/list`} 
          component={props => <UserRoleList {...props} basePath={match.url} />}/>
        <Route path={`${match.url}/role/add`} 
          component={props => <UserRoleSet {...props} basePath={match.url} />}/>
        <Route path={`${match.url}/role/edit/:id`} 
          component={props => <UserRoleSet {...props} basePath={match.url} />}/> */}


        <Redirect to={`${match.url}/list`}/>
      </Switch>
    </div>
  )
}

User.propTypes = {

}

const mapStateToProps = (state) => ({
  
})

const mapDispatchToProps = {
  
}

export default connect(mapStateToProps, mapDispatchToProps)(User)

