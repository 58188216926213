import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import Card from '../../../utilities/card/Card'
import CardHeader from '../../../utilities/card/CardHeader'
import CardBody from '../../../utilities/card/CardBody';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { langTabs, langCht } from '../../../utilities/form/MultiLangSelector';
import Tab from '../../../utilities/tab/Tab';

import format from 'date-fns/format'
import { TargetHost, StaticPath } from '../../../../helpers/Constants';

import PreviewShare from '../../../../assets/post-preview-share.png'
import PreviewSave from '../../../../assets/post-preview-save.png'
import PreviewLike from '../../../../assets/post-preview-like.png'
import PreviewLikeWhite from '../../../../assets/post-preview-like-white.png'
import PreviewLikeBlack from '../../../../assets/post-preview-like-black.png'
import PreviewDaysLeft from '../../../../assets/post-preview-days-left.png'
import PreviewAttachment from '../../../../assets/post-preview-attachment.png'
import { Dialog, DialogTitle, DialogContent, DialogActions, Chip, Avatar } from '@material-ui/core';
import CheckboxInput from '../../../utilities/form/CheckboxInput';
import { getPostTextColorByBgColor, postStatusDraft } from '../../../../helpers/PostHelper';
import { openConfirmModal } from '../../../../helpers/ConfirmModalHelper';
import { typeEvent, typeSurvey, typePromotion } from '../../../utilities/form/LinkageSelector';
import { getPromotionNumberOfDaysLeft } from '../../../../helpers/DateHelper';
import { apiPost } from '../../../../helpers/Api';

function PostImagePreview(props) {
  const {
    post,
    index,
    langKey
  } = props;

  let src;
  let arr = post._i18n[langKey].localImageObjArr;

  if (arr && arr.length > index) {
    if (arr[0].attachmentId) {
      src = `${TargetHost}${StaticPath}${arr[0].attachmentId}`
    } else {
      src = arr[0].file.imagePreview
    }
  }

  return (
    <div className="image-wrapper">
      <div className="image" style={{
        backgroundImage: `url(${src})`,
        backgroundSize: "cover"
      }} />
    </div>
  )
}

function PostAttachmentPreviews(props) {
  const {
    post,
    langKey
  } = props;


  return (
    <div className="attachment-container">
      {
        post._i18n[langKey].localMediaObjArr.map((mediaObj, index) => (
          <div className="attachment" key={mediaObj.name}>
            {/* <img className="icon" src={PreviewAttachment} /> */}
            <span className="name">{mediaObj.name}</span>
            <FontAwesomeIcon icon="angle-right" />
          </div>
        ))
      }
    </div>
  )
}

function PostImagesPreviews(props) {
  const {
    post,
    langKey
  } = props;


  return (
    <div className="image-preview-container">
      {
        post._i18n[langKey].localImageObjArr.map((imageObj, index) => (
          <div className="image-wrapper" key={imageObj.attachmentId}>
            <div className="image" style={{
              backgroundImage: imageObj.attachmentId ? `url(${TargetHost}${StaticPath}${imageObj.attachmentId})` : `url(${imageObj.file.imagePreview})`,
              backgroundSize: "cover"
            }} />
          </div>
        ))
      }
    </div>
  )
}

function PostPreview(props) {
  const {
    post,
    isPublishing,
    handleClose,
    confirmPostPublish,
  } = props;

  console.log(post)

  const [linkedObject, setLinkedObject] = useState(null)
  const getLinkedObject = () => {
    if (!post.linkage) {
      return;
    }
    let url = '';
    let key = '';
    switch (post.linkage.target_type) {
      case typeEvent.value:
        url = '/admin/events';
        key = 'event_id';
        break;
      case typeSurvey.value:
        url = '/admin/questionnaire/list';
        key = 'questionnaire_id';
        break;
      case typePromotion.value:
        url = '/admin/promotion';
        key = 'promotion_id';
        break;
    }
    if (!url) {
      return
    }
    apiPost(url, {[key]: post.linkage.target_id})
      .then(({data}) => {
        data.name = data.name || data.title
        data.start_time = data.start_time || data.start_date
        data.end_time = data.end_time || data.expiry_date
        setLinkedObject(data)
      })
  }

  const [activeLang, setActiveLang] = useState(langTabs[0])

  const getDateDisplay = (date, lang) => {
    let formatStr = '';
    switch (lang) {
      case langCht.key:
        formatStr = 'yyyy年M月d日'
        break;
      default:
        formatStr = 'd LLL yyyy'
        break;
    }
    return format(date, formatStr);
  }

  const [isNotify, setIsNotify] = useState(post.isDraft);

  const publishNow = () => {
    openConfirmModal({
      isDisplay: true,
      title: 'Confirmation',
      message: 'Are you sure to publish?',
      closeCallback: null,
      confirmCallback: () => {
        confirmPostPublish(isNotify)
      }
    })
  }

  useEffect(() => {
    getLinkedObject()
  }, [])

  return (
    <>
      <CardHeader>
        <div style={{display: "flex", alignItems: "center"}}>
          <div className="title">
            {isPublishing ? 'Publish' : 'Preview'}
          </div>
          <Tab tabs={langTabs} activeTab={activeLang} switchActiveTab={setActiveLang} />
        </div>
        <div style={{display: "flex", alignItems: "center"}}>
          
          <FontAwesomeIcon 
            className="clickable" 
            style={{marginLeft: 20}}
            icon="times" 
            fixedWidth 
            onClick={handleClose} />
        </div>
      </CardHeader>
      <DialogContent dividers className="post-preview-body">
        <h3>Images</h3>
        <hr />
        <PostImagesPreviews post={post} langKey={activeLang.key} />

        <h3>Phone view</h3>
        <hr />
        <div className="phone-preview-container">
          <div className="phone list">
            <div className="home">
              {
                (() => {
                  switch (activeLang.key) {
                    case langCht.key:
                      return 'HKG之友'
                    default:
                      return 'HKG Community'
                  }
                })()
              }
            </div>
            <div className="category-container">
              <span className="all">
                {
                  (() => {
                    switch (activeLang.key) {
                      case langCht.key:
                        return '最新消息'
                      default:
                        return 'What\'s New'
                    }
                  })()
                }
              </span>
            </div>
            <div className="list-cell-container">
              <div className="post-image">
                <PostImagePreview post={post} index="0" langKey={activeLang.key} />
              </div>
              <div className="post-detail">
                <div className="category-row">
                  <div className="category-name">
                    {post.categories && post.categories._i18n[activeLang.key].name}
                  </div>
                  <div className="new-label">
                    {
                      (() => {
                        switch (activeLang.key) {
                          case langCht.key:
                            return (<i>新!</i>)
                          default:
                            return (<i>New!</i>)
                        }
                      })()
                    }
                  </div>
                </div>
                <div className="title">
                  {post._i18n[activeLang.key].title}
                </div>
                <div className="caption">
                  {post._i18n[activeLang.key].caption}
                </div>
                <div className="date">
                  {getDateDisplay(post.publishOn[0] ? post.publishOn[0] : new Date(), activeLang.key)}
                </div>
                {
                  (post.linkage && post.linkage.target_type == typePromotion.value && linkedObject) &&
                  <Chip 
                    className="days-left"
                    size="small" 
                    label={getPromotionNumberOfDaysLeft(linkedObject.end_time, activeLang.key)} 
                    avatar={<Avatar src={PreviewDaysLeft} /> } 
                  />
                }
                <div className="actions">
                  <div className="left">
                    {
                      post.isSharable &&
                      <div className="share">
                        999 <img src={PreviewShare} />
                      </div>
                    }
                    <div className="save">
                      <img src={PreviewSave} />
                    </div>
                  </div>
                  <div className="right">
                    {
                      post.isLikable &&
                      <div className="like">
                        999 <img src={PreviewLike} />
                      </div>
                    }
                  </div>
                </div>
              </div>
            </div>
            {
              post.isAdvertisement &&
              <div className="post-advertisement-container">
                <div className="post-image">
                  <PostImagePreview post={post} index="0" langKey={activeLang.key} />
                </div>
                <div className="post-ad-detail" style={{backgroundColor: post.advertisementBgColor}}>
                  <div className="post-ad-title" style={{color: getPostTextColorByBgColor(post.advertisementBgColor)}}>
                    {post._i18n[activeLang.key].title}
                  </div>
                  <div className="post-ad-action-bar">
                    {
                      post.isLikable &&
                      <div className="post-ad-like" style={{color: getPostTextColorByBgColor(post.advertisementBgColor)}}>
                        999 <img src={getPostTextColorByBgColor(post.advertisementBgColor) === "#FFFFFF" ? PreviewLikeWhite : PreviewLikeBlack} />
                      </div>
                    }
                    <div className="post-ad-view-btn">
                      {
                        (() => {
                          switch (activeLang.key) {
                            case langCht.key:
                              return '查看更多'
                            default:
                              return 'View More'
                          }
                        })()
                      }
                    </div>
                  </div>
                </div>
              </div>
            }
          </div>
          <div className="phone detail">
            <div className="back">
              <FontAwesomeIcon icon="angle-left" size="2x" />
            </div>
            <div className="post-image">
              <PostImagePreview post={post} index="0" langKey={activeLang.key} />
            </div>
            <div className="post-detail">
              <div className="category-row">
                <div className="category-name">
                  {post.categories && post.categories._i18n[activeLang.key].name}
                </div>
              </div>
              <div className="title">
                {post._i18n[activeLang.key].title}
              </div>
              <div className="date">
                {getDateDisplay(post.publishOn[0] ? post.publishOn[0] : new Date(), activeLang.key)}
              </div>
              <div className="content" dangerouslySetInnerHTML={{__html: post._i18n[activeLang.key].html_content_new}}>
              </div>
              <PostAttachmentPreviews post={post} langKey={activeLang.key} />
              {
                post.isShowPostButton &&
                <div className="preview-addon">
                  <div className="preview-addon-button">
                    {post._i18n[activeLang.key].button_content}
                  </div>
                </div>
              }
              {
                (post.linkage && linkedObject) &&
                (() => {
                  switch (post.linkage.target_type) {
                    case typeEvent.value:
                      return (
                        <div className="preview-addon event">
                          <div className="preview-addon-button">
                            {
                              // (() => {
                              //   switch (activeLang.key) {
                              //     case langCht.key:
                              //       return '活動詳情'
                              //     default:
                              //       return 'EVENT DETAIL'
                              //   }
                              // })()
                              activeLang.key === langCht.key ? '活動詳情' : 'EVENT DETAIL'
                            }
                          </div>
                        </div>
                      );
                    case typeSurvey.value:
                      return (
                        <div className="preview-addon survey">
                          {
                            linkedObject.end_time * 1000 < new Date().getTime()
                            ?
                            <div className="preview-addon-button expired">
                              {
                                // (() => {
                                //   switch (activeLang.key) {
                                //     case langCht.key:
                                //       return '已過期'
                                //     default:
                                //       return 'EXPIRED'
                                //   }
                                // })()
                                activeLang.key === langCht.key ? '已過期' : 'EXPIRED'
                              }
                            </div>
                            :
                            <div className="preview-addon-button">
                              {
                                // (() => {
                                //   switch (activeLang.key) {
                                //     case langCht.key:
                                //       return '開始問卷'                                    
                                //     default:
                                //       return 'START SURVEY'
                                //   }
                                // })()
                                activeLang.key === langCht.key ? '開始問卷' : 'START SURVEY'
                              }
                            </div>
                          }
                        </div>
                      );
                    case typePromotion.value:
                      return (
                        <div className="preview-addon promotion list-cell-container">
                          {
                            (linkedObject.images.length > 0) &&
                            <div className="post-image">
                              <div className="image-wrapper">
                                <div className="image" style={{
                                  backgroundImage: `url(${TargetHost}${StaticPath}${linkedObject.images[0]})`,
                                  backgroundSize: "cover"
                                }} />
                              </div>
                            </div>
                          }
                          <div className="post-detail">
                            <div className="title">
                              {linkedObject.name}
                            </div>
                            <div className="caption">
                              {linkedObject.description}
                            </div>
                            <div className="caption">
                              {
                                (() => {
                                  switch (activeLang.key) {
                                    case langCht.key:
                                      return `到期日: ${format(linkedObject.end_time * 1000, 'yyyy年M月d日')}`                                   
                                    default:
                                      return `Expiry Date: ${format(linkedObject.end_time * 1000, 'd LLL yyyy')}`                                
                                    }
                                })()
                              }
                            </div>
                            <Chip 
                              className="days-left"
                              size="small" 
                              label={getPromotionNumberOfDaysLeft(linkedObject.end_time, activeLang.key)} 
                              avatar={<Avatar src={PreviewDaysLeft} /> } 
                            />
                          </div>
                          {
                            linkedObject.end_time * 1000 < new Date().getTime()
                            ?
                            <div className="preview-addon-button expired">
                              {
                                // (() => {
                                //   switch (activeLang.key) {
                                //     case langCht.key:
                                //       return '已過期'                                  
                                //     default:
                                //       return 'EXPIRED'                                
                                //     }
                                // })()
                                activeLang.key === langCht.key ? '已過期' : 'EXPIRED'
                              }
                            </div>
                            :
                            <div className="preview-addon-button">
                              {
                                // (() => {
                                //   switch (activeLang.key) {
                                //     case langCht.key:
                                //       return '換領'                                    
                                //     default:
                                //       return 'REDEEM'
                                //     }
                                // })()
                                activeLang.key === langCht.key ? '換領' : 'REDEEM'
                              }
                            </div>
                          }
                        </div>
                      );
                    default:
                      return <></>;
                  }
                })()
              }
            </div>
            <div className="actions">
              <div className="left">
                {
                  post.isSharable &&
                  <div className="share">
                    999 <img src={PreviewShare} />
                  </div>
                }
                <div className="save">
                  <img src={PreviewSave} />
                </div>
                <div className="font-size">Aa</div>
              </div>
              <div className="right">
                {
                  post.isLikable &&
                  <div className="like">
                    999 <img src={PreviewLike} />
                  </div>
                }
              </div>
            </div>
          </div>
        </div>
      </DialogContent>
      {
        isPublishing &&
        <DialogActions>
          <CheckboxInput 
            value={isNotify} 
            name="Send Notification"
            onChange={setIsNotify} 
          />
          <button className="button primary" onClick={publishNow}>
            <span>Publish</span>
          </button>
        </DialogActions>
      }
    </>
  )
}

PostPreview.propTypes = {

}

export default PostPreview

