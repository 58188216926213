import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import Card from '../../../utilities/card/Card';
import CardHeader from '../../../utilities/card/CardHeader';
import { apiPost, apiUploadFile } from '../../../../helpers/Api';
import CardBody from '../../../utilities/card/CardBody';
import BasicInput from '../../../utilities/form/BasicInput';
import { toast } from '../../../utilities/Notification/CustomToast';
import TextAreaInput from '../../../utilities/form/TextAreaInput';
import ImageInput from '../../../utilities/form/ImageInput';
import { TargetHost, StaticPath, TargetDomain } from '../../../../helpers/Constants';
import each from 'lodash/each'
import union from 'lodash/union'
import trim from 'lodash/trim'
import { langTabs, defaultLang } from '../../../utilities/form/MultiLangSelector';
import MultiLangInputs from '../../../utilities/form/MultiLangInputs';
import FileInput from '../../../utilities/form/FileInput';
import { attachmentTypes } from '../../../../helpers/FileHelper';
import CheckboxInput from '../../../utilities/form/CheckboxInput';

function PostCategorySet(props) {
  const {
    match,
    basePath,
    history
  } = props;

  const categoryId = match.params.id;

  const title = !categoryId ? "Add New" : "Category Detail";

  let i18nDefaultObj = {};
  each(langTabs, (lang) => {
    i18nDefaultObj[lang.key] = {
      name: '',
      description: ''
    }
  })

  const defaultCategoryObj = {
    name: '',
    description: '',
    _i18n: i18nDefaultObj,
    icon_id: '',
    defaultIconIdArr: [],
    iconIdArr: [],
    iconFileArr: [],
    uploadedIconIdArr: [],
    isMandatory: false,
    isDefaultSubscribed: false
  }

  const [category, setCategory] = useState(defaultCategoryObj)

  const updateCategoryInfo = (update) => {
    setCategory({
      ...category,
      ...update
    })
  }

  const getCategoryDetail = () => {
    let param = {
      category_id: categoryId
    }
    apiPost('/admin/post/category', param)
      .then((resp) => {
        let cat = resp.data;

        let i18n = {};
        each(langTabs, (lang, index) => {
          i18n[lang.key] = {
            name: cat.name,
            description: cat.description,
          }
          if (cat._i18n[lang.key]) {
            i18n[lang.key] = {
              ...i18n[lang.key],
              ...cat._i18n[lang.key],
            }
          }
        })
        
        cat._i18n = {...i18nDefaultObj, ...i18n}

        cat.defaultIconIdArr = [cat.icon_id];

        cat.isMandatory = !!cat.is_mandatory;
        cat.isDefaultSubscribed = !!cat.is_default_subscribed;

        updateCategoryInfo(cat)
      })
  }

  const uploadPic = (callback) => {
    if (category.iconFileArr.length > 0) {
      apiUploadFile(category.iconFileArr[0])
        .then((resp) => {
          category.uploadedIconIdArr.push(resp.data)
          callback();
        })
        .catch((err) => {
          console.error(err);
        })
    } else {
      callback();
    }
  }

  const saveCategory = () => {
    let defaultLangContent = category._i18n[defaultLang.key];

    let i18nObj = {}
    each(langTabs, (lang, index) => {
      i18nObj[lang.key] = {
        name: trim(category._i18n[lang.key].name),
        description: trim(category._i18n[lang.key].description),
      }
    })

    let allIconIds = union(category.iconIdArr, category.uploadedIconIdArr);

    let theCategory = {
      name: defaultLangContent.name,
      description: defaultLangContent.description,
      icon_id: allIconIds.length > 0 ? allIconIds[0] : '',
      is_mandatory: category.isMandatory ? 1 : 0,
      is_default_subscribed: category.isDefaultSubscribed ? 1 : 0,
      _i18n: i18nObj
    }
    if (categoryId) {
      theCategory.category_id = categoryId
    }

    apiPost('/admin/post/category/set', theCategory)
      .then((resp) => {
        history.push(basePath)
      })
      .catch((err) => {
        console.error(err);
      })
  }

  const checkInput = () => {
    for (let i = 0; i < langTabs.length; i++) {
      let lang = langTabs[i];
      if (!category._i18n[lang.key].name) {
        toast.warn("Please enter name for all languages")
        return false;
      }
    }
    
    return true;
  }

  const saveNow = () => {
    if (!checkInput()) {
      return;
    }
    uploadPic(saveCategory);
  }

  const backNow = () => {
    history.push(`${basePath}/list`)
  }

  useEffect(() => {
    if (categoryId) {
      getCategoryDetail()
    }
  }, [])

  return (
    <div className="item-set-container">
      <div className="full-width padding-bottom-30">
        <Card>
          <CardHeader>
            <div className="title">
              {title}
            </div>
            <div className="actions">
              <button className="button default" onClick={backNow}>
                <span>Back</span>
              </button>
              <button className="button primary" onClick={saveNow}>
                <span>Save</span>
              </button>
            </div>
          </CardHeader>
        </Card>
        <div className="padding-bottom-30"></div>

        <Card>
          <CardHeader>
            <div className="title sub-title">
              Name
            </div>
          </CardHeader>
          <CardBody>
            <MultiLangInputs
              i18n={category._i18n}
              field="name"
              type="text"
              rows={3}
              onChange={(i18n) => updateCategoryInfo({_i18n: i18n})}
              label="Name"
            />
            {/* <BasicInput 
              type="text" 
              value={category.name} 
              label="Name" 
              onChange={(val) => updateCategoryInfo({name: val})} 
            />
            <BasicInput 
              rows="5" 
              value={category.description} 
              label="Description" 
              placeholder="Description" 
              onChange={(val) => updateCategoryInfo({description: val})} 
            /> */}
          </CardBody>
        </Card>
        <div className="padding-bottom-30"></div>

        <Card>
          <CardHeader>
            <div className="title sub-title">
              Description
            </div>
          </CardHeader>
          <CardBody>
            <MultiLangInputs
              i18n={category._i18n}
              field="description"
              type="textarea"
              rows={5}
              onChange={(i18n) => updateCategoryInfo({_i18n: i18n})}
              label="Description"
            />
            {/* <BasicInput 
              type="text" 
              value={category.name} 
              label="Name" 
              onChange={(val) => updateCategoryInfo({name: val})} 
            />
            <BasicInput 
              rows="5" 
              value={category.description} 
              label="Description" 
              placeholder="Description" 
              onChange={(val) => updateCategoryInfo({description: val})} 
            /> */}
          </CardBody>
        </Card>
{/*         
        <div className="padding-bottom-30"></div>
        <Card>
          <CardHeader>
            <div className="title sub-title">
              Icon
            </div>
          </CardHeader>
          <CardBody>
            <FileInput  
              attachmentArr={category.defaultIconIdArr}
              onAttachmentArrChange={(iconIdArr) => updateCategoryInfo({iconIdArr: iconIdArr})}
              onFileArrChange={(iconFileArr)=>updateCategoryInfo({iconFileArr: iconFileArr})}
              acceptTypes={attachmentTypes.imageTypes}
              maxCount={1}
              text="Upload Icon"
              isFileNameOnly={false}
              extraTips="Recommended Dimension: 500x500 px"
            />
          </CardBody>
        </Card> */}

        <div className="padding-bottom-30"></div>
        <Card>
          <CardHeader>
            <div className="title sub-title">
              Mandatory
            </div>
          </CardHeader>
          <CardBody>
            <div className="checkbox-config">
              <div className="config-name">
                Is Mandatory?
              </div>
              <div className="checkbox">
                <CheckboxInput value={category.isMandatory} onChange={(isMandatory) => updateCategoryInfo({isMandatory: isMandatory})} />
              </div>
            </div>
          </CardBody>
        </Card>

        <div className="padding-bottom-30"></div>
        <Card>
          <CardHeader>
            <div className="title sub-title">
              Subscription
            </div>
          </CardHeader>
          <CardBody>
            <div className="checkbox-config">
              <div className="config-name">
                Is Subscribed by default?
              </div>
              <div className="checkbox">
                <CheckboxInput value={category.isDefaultSubscribed} onChange={(isDefaultSubscribed) => updateCategoryInfo({isDefaultSubscribed: isDefaultSubscribed})} />
              </div>
            </div>
          </CardBody>
        </Card>
      </div>
    </div>
  )
}

PostCategorySet.propTypes = {

}

export default PostCategorySet

