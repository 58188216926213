import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { apiPost } from '../../../../helpers/Api';

import map from 'lodash/map'
import { createLocalFileObject, attachmentTypes, uploadLocalFileObjects } from '../../../../helpers/FileHelper';
import Card from '../../../utilities/card/Card';
import CardHeader from '../../../utilities/card/CardHeader';
import CardBody from '../../../utilities/card/CardBody';
import BasicInput from '../../../utilities/form/BasicInput';
import FileInput from '../../../utilities/form/FileInput';
import { toast } from '../../../utilities/Notification/CustomToast';
import each from 'lodash/each'
import trim from 'lodash/trim'
import { langTabs, defaultLang } from '../../../utilities/form/MultiLangSelector';
import MultiLangInputs from '../../../utilities/form/MultiLangInputs';

function AccPageSet(props) {
  const {
    match,
    basePath,
    history
  } = props;

  const itemId = match.params.id;

  let i18nDefaultObj = {};
  each(langTabs, (lang) => {
    i18nDefaultObj[lang.key] = {
      name: ''
    }
  })

  const defaultAccPageObj = {
    name: '',
    url: '',
    localImageObjArr: [],
    uploadedImageSrcArr: [],
    _i18n: i18nDefaultObj
  }

  const [accPage, setAccPage] = useState(defaultAccPageObj)

  const updateInfo = (update) => {
    setAccPage({
      ...accPage,
      ...update
    })
  }

  const getAccPageDetail = () => {
    let param = {
      item_id: itemId
    }
    apiPost('/admin/hkaa_community/acc_page', param)
      .then((resp) => {
        let accPage = resp.data;


        let i18n = {};
        each(langTabs, (lang, index) => {
          i18n[lang.key] = {
            name: accPage.name,
          }
          if (accPage._i18n[lang.key]) {
            i18n[lang.key] = {
              ...i18n[lang.key],
              ...accPage._i18n[lang.key],
            }
          }
        })
        
        accPage._i18n = {...i18nDefaultObj, ...i18n}

        accPage.localImageObjArr = [createLocalFileObject({attachmentId: accPage.image_id, name: accPage.image_id})]

        updateInfo({
          ...defaultAccPageObj,
          ...accPage
        })
      })
  }

  const checkInput = () => {
    for (let i = 0; i < langTabs.length; i++) {
      let lang = langTabs[i];
      if (!accPage._i18n[lang.key].name) {
        toast.warn("Please enter name for all languages");
        return false;
      }
    }
    if (!accPage.url) {
      toast.warn('Please enter URL')
      return false;
    }
    if (accPage.localImageObjArr.length === 0) {
      toast.warn('Please upload image')
      return false
    }
    return true
  }

  const uploadPic = (index, callback) => {    
    uploadLocalFileObjects({
      arr: accPage.localImageObjArr,
      index: index,
      uploadedIds: [],
      callbackWithIds: (ids) => {
        accPage.uploadedImageSrcArr = ids
        callback()
      }
    })
  }

  const saveAccPage = () => {
    let defaultLangContent = accPage._i18n[defaultLang.key];

    let i18nObj = {}
    each(langTabs, (lang, index) => {
      i18nObj[lang.key] = {
        name: trim(accPage._i18n[lang.key].name),
        description: trim(accPage._i18n[lang.key].description),
      }
    })

    let theAccPage = {
      name: defaultLangContent.name,
      url: trim(accPage.url),
      image_id: accPage.uploadedImageSrcArr[0],
      _i18n: i18nObj,
      order: accPage.order ? accPage.order : null
    }

    if (accPage.item_id) {
      theAccPage.item_id = accPage.item_id
    }

    apiPost('/admin/hkaa_community/acc_page/set', theAccPage)
      .then((resp) => {
        history.push(`${basePath}/list`)
      })
  }

  const saveNow = () => {
    if (!checkInput()) {
      return;
    }
    uploadPic(0, () => saveAccPage())
  }

  const backNow = () => {
    history.push(`${basePath}/list`)
  }

  useEffect(() => {
    if (itemId) {
      getAccPageDetail()
    }
  }, [])

  return (
    <div className="item-set-container">
      <div className="full-width padding-bottom-30">
        <Card>
          <CardHeader>
            <div className="title">
              {accPage.item_id ? 'ASC Page Detail' : 'Add New'}
            </div>
            <div className="actions">
              <button className="button default" onClick={backNow}>
                <span>Back</span>
              </button>
              <button className="button primary" onClick={saveNow}>
                <span>Save</span>
              </button>
            </div>
          </CardHeader>
        </Card>
        <div className="padding-bottom-30" />
        
        <Card>
          <CardHeader>
            <div className="title sub-title">
              Name
            </div>
          </CardHeader>
          <CardBody>
            <MultiLangInputs
              i18n={accPage._i18n}
              field="name"
              type="text"
              rows="2"
              onChange={(i18n) => updateInfo({_i18n: i18n})}
              label="Name"
            />
          </CardBody>
        </Card>
        <div className="padding-bottom-30" />
        
        <Card>
          <CardHeader>
            <div className="title sub-title">
              URL
            </div>
          </CardHeader>
          <CardBody>
            <BasicInput
              type="text"
              value={accPage.url}
              label="URL"
              isHideLabel={true}
              onChange={(val) => updateInfo({url: val})}
            />
          </CardBody>
        </Card>
        <div className="padding-bottom-30" />

        <Card>
          <CardHeader>
            <div className="title sub-title">
              Image (Max. 1)
            </div>
          </CardHeader>
          <CardBody>
            <FileInput 
              localFileObjArr={accPage.localImageObjArr}
              onLocalFileObjArrChange={(arr) => updateInfo({localImageObjArr: arr})}
              maxCount={1}
              acceptTypes={attachmentTypes.imageTypes}
              text="Upload"
              isFileNameOnly={false}
              extraTips="Recommended ratio: 3:1; Recommended dimension: 1200x400 px"
            />
          </CardBody>
        </Card>
      </div>
    </div>
  )
}

AccPageSet.propTypes = {

}

export default AccPageSet

