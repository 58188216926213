import React from 'react'
import PropTypes from 'prop-types'

import { connect } from 'react-redux'

import { Switch, Route, Redirect } from 'react-router-dom'
import PostTemplateList from './list/PostTemplateList';
import PostTemplateSet from './set/PostTemplateSet';

import "./PostTemplate.scss"

function PostTemplate(props) {
  const { match } = props;

  return (
    <div className="page-container">
      <Switch>
        <Route path={`${match.url}/list`} 
          render={props => <PostTemplateList {...props} basePath={match.url}></PostTemplateList>}/>
        <Route path={`${match.url}/add`} 
          render={props => <PostTemplateSet {...props} basePath={match.url}></PostTemplateSet>}/>
        <Route path={`${match.url}/edit/post_template/:id`}
          render={props => <PostTemplateSet {...props} basePath={match.url}></PostTemplateSet>}/>
        <Redirect to={`${match.url}/list`}/>
      </Switch>
    </div>
  )
}

PostTemplate.propTypes = {

}

const mapStateToProps = (state) => ({
  
})

const mapDispatchToProps = {
  
}

export default connect(mapStateToProps, mapDispatchToProps)(PostTemplate)
