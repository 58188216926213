import React, { useState } from 'react'

import { connect } from 'react-redux'
import { withRouter, Redirect } from 'react-router-dom'

import PortalLinks from './PortalLinks'
import PortalRoutes from './PortalRoutes'
import PortalTopNav from './PortalTopNav'

import "./Portal.scss"
import { getPortalLandingPage, logoutAccount } from '../../helpers/AccountHelper'

import IdleTimer from 'react-idle-timer'
import { IdleTimePeriodMin } from '../../helpers/Constants'
import { openConfirmModal } from '../../helpers/ConfirmModalHelper'

function Portal(props) {
  const {
    user,
  } = props;

  const [isSideNavExtended, setIsSideNavExtended] = useState(true);

  const handleSideNavToggle = () => {
    // TODO: improve
    // setIsSideNavExtended(!isSideNavExtended)
  }

  let sideNavClassName = ["sidenav"];
  if (isSideNavExtended) {
    sideNavClassName.push("extend")
  }

  const [idleTimerRef, setIdleTimerRef] = useState(null)
  const onUserIdle = (e) => {
    // console.log('idle...', e)
    logoutAccount()
    openConfirmModal({
      title: 'Session expired',
      message: 'Your session has expired. Please login again.',
      closeCallback: null,
      confirmCallback: null,
      isHideCancel: true
    })
  }
  const onUserActive = (e) => {
    // console.log('active...', e)
    // console.log(idleTimerRef.getRemainingTime())
  }
  const onUserAction = (e) => {
    // console.log('action...', e)
    // console.log(idleTimerRef.getRemainingTime())
  }

  return (
    <div className="portal-container">
      <IdleTimer 
        ref={ref => {setIdleTimerRef(ref)}}
        element={document}
        onAction={onUserAction}
        onActive={onUserActive}
        onIdle={onUserIdle}
        debounce={250}
        timeout={1000 * 60 * IdleTimePeriodMin}
        startOnMount={true}
        stopOnIdle={true}
      />
      <div className="top">
        <div className="topnav">
          <PortalTopNav handleSideNavToggle={handleSideNavToggle}></PortalTopNav>
        </div>
      </div>
      <div className="bottom">
        <div className={sideNavClassName.join(' ')}>
          <PortalLinks isSideNavExtended={isSideNavExtended}></PortalLinks>
        </div>

        <div className="content">
          <PortalRoutes></PortalRoutes>
        </div>
      </div>

      {/* <Redirect to="/portal/dashboard"></Redirect> */}
      <Redirect to={getPortalLandingPage(user)}></Redirect>
    </div>
  )
}

Portal.propTypes = {

}

const mapStateToProps = (state) => ({
  user: state.account.user,
})

const mapDispatchToProps = {
  
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Portal))
