import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import List from '../../../utilities/list/List'
import ToolsRenderer from '../../../utilities/list/ToolsRenderer';
import { apiPost } from '../../../../helpers/Api';
import { searchFilter } from '../../../../helpers/SearchHelper';
import ImageRenderer from '../../../utilities/list/ImageRenderer';

import each from 'lodash/each'
import { toast } from '../../../utilities/Notification/CustomToast';
import Card from '../../../utilities/card/Card';
import CardHeader from '../../../utilities/card/CardHeader';

function PostCategoryList(props) {
  const { 
    match, 
    basePath,
    history
  } = props;
  
  const [originalData, setOriginalData] = useState([]);
  const [listKeyword, setListKeyword] = useState('');
  const [listFilter, setListFilter] = useState(null);

  const [headerOptions, setHeaderOptions] = useState({
    enable: true,
    isSearch: true,
    searchableFields: ['name', 'description'],
    filters: [

    ],
    onHeaderChange: (keyword, filter) => {
      setListKeyword(keyword);
      setListFilter(filter);
    }
  })

  const columns = [
    // {
    //   headerName: "Icon",
    //   field: "icon_id",
    //   width: 80,
    //   minWidth: 80,
    //   maxWidth: 80,
    //   cellClass: 'list-image-cell',
    //   cellRenderer: 'imageRenderer',
    //   cellRendererParams: {
    //     isIcon: true
    //   }
    // },
    {
      headerName: "Name",
      field: "name",
      sort: 'asc',
    },
    {
      headerName: "Description",
      field: "description",
    },
    // {
    //   headerName: "Count",
    //   field: "count",
    // },
    {
      headerName: "Tools",
      field: "category_id",
      pinned: "right",
      width: 100,
      minWidth: 100,
      maxWidth: 100,
      sortable: false,
      cellRenderer: 'toolsRenderer',
      cellRendererParams: {
        onClickCallback: {
          edit: (row) => {
            editCategory(row);
          },
          remove: (row) => {
            deleteCategory(row);
          }
        },
        types: ["edit", "remove"]
      }
    },
  ]

  const [gridOptions, setGridOptions] = useState({
    columnDefs: columns,
    frameworkComponents: {
      toolsRenderer: ToolsRenderer,
      imageRenderer: ImageRenderer
    }
  });

  const [rowData, setRowData] = useState([...originalData]);

  const search = () => {
    let filtered = originalData;


    if (listKeyword) {
      filtered = searchFilter(filtered, headerOptions.searchableFields, listKeyword);
    }

    setRowData(filtered);
  }

  const getCategoryList = () => {
    let param = {

    }
    apiPost('/admin/post/category', param)
    .then((resp) => {
      let categories = resp.data;

      setOriginalData(categories);
    })
  }
  
  const deleteCategory = (item) => {
    let param = {
      category_id: item.category_id
    }
    apiPost('/admin/post/category/delete', param)
      .then((resp) => {
        getCategoryList()
      })
      .catch((err) => {
        toast.warn("Category : " + item.name + " is used in post templates. Please remove it first.");
        console.error(err);
      })
  }

  const editCategory = (category) => {
    history.push(`${basePath}/edit/${category.category_id}`);
  }


  useEffect(() => {
    getCategoryList();
  }, []);

  useEffect(() => {
    search();
  }, [originalData]);

  useEffect(() => {
    search()
  }, [listKeyword, listFilter]);

  return (
    <div className="list-container">
      <div className="header">
        <Card>
          <CardHeader>
            <div className="title">Categories</div>
            <div className="actions">
              <button 
                className="button default"
                onClick={() => history.push(`${basePath}/add`)}
              >
                <a>Add New</a>
              </button>
            </div>
          </CardHeader>
        </Card>
      </div>
      <div className="body">
        <List 
          headerOptions={headerOptions}
          gridOptions={gridOptions}
          rowData={rowData}
        />
      </div>
    </div>
  )
}

PostCategoryList.propTypes = {

}

export default PostCategoryList

