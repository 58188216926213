import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckSquare, faSquare } from '@fortawesome/free-regular-svg-icons';


function CheckboxInput(props) {
  const {
    value,
    name,
    onChange,
    disabled,
    className
  } = props;

  const [isChecked, setIsChecked] = useState(value);
  const onCheckboxClick = (event) => {
    if (disabled) {
      return;
    }
    setIsChecked(!isChecked);
    if (onChange) {
      onChange(!isChecked);
    }
  }

  const iconClass = isChecked ? faCheckSquare : faSquare


  useEffect(() => {
    setIsChecked(value);
  }, [value]);

  return (
    <div className={`checkbox-container clickable ${className}`} onClick={onCheckboxClick}>
      <FontAwesomeIcon icon={iconClass} fixedWidth />
      {
        name &&
        <div className="name">
          {name}
        </div>
      }
    </div>
  )
}

CheckboxInput.propTypes = {

}

export default CheckboxInput

