export const postStatus = {
  all: "All",
  published: "Published",
  scheduled: "Scheduled",
  draft: "Draft",
  expired: "Expired",
  trash: "Trash"
}

export const postStatusEnum = {
  'all': "All",
  9: "Published",
  1: "Scheduled",
  0: "Draft",
  '-1': "Expired",
  '-9': "Trash"
}

export const postStatusAll = {
  key: "all",
  name: "All",
  count: 0,
  enum: 'all'
}

export const postStatusPublished = {
  key: "published",
  name: "Published",
  count: 0,
  enum: 9
}

export const postStatusScheduled = {
  key: "scheduled",
  name: "Scheduled",
  count: 0,
  enum: 1
}

export const postStatusDraft = {
  key: "draft",
  name: "Draft",
  count: 0,
  enum: 0
}

export const postStatusExpired = {
  key: "expired",
  name: "Expired",
  count: 0,
  enum: -1
}

export const postStatusTrash = {
  key: "trash",
  name: "Trash",
  count: 0,
  enum: -9
}

export const postStatusTabs = [
  postStatusAll,
  postStatusPublished,
  postStatusScheduled,
  postStatusDraft,
  postStatusExpired,
  {
    key: 'separator',
  },
  postStatusTrash
]

export const postFieldLimit = {
  title: 50,
  caption: 300
}

export const postStatusOptions = [
  {value: postStatusDraft.enum, label: postStatusDraft.name},
  {value: postStatusScheduled.enum, label: postStatusScheduled.name},
]

export const PostStatItems = [
  {
    key: 'reads',
    value: 'reads',
    name: 'View (List)',
    label: 'View (List)',
    width: 120
  },
  {
    key: 'reads_advertisement',
    value: 'reads_advertisement',
    name: 'View (Advertisement)',
    label: 'View (Advertisement)',
    width: 170
  },
  {
    key: 'reads_notification',
    value: 'reads_notification',
    name: 'Open via Notification',
    label: 'Open via Notification',
    width: 170
  },
  {
    key: 'saves',
    value: 'saves',
    name: 'Bookmark',
    label: 'Bookmark',
    width: 120
  },
  {
    key: 'likes',
    value: 'likes',
    name: 'Like',
    label: 'Like',
    width: 80
  },
  {
    key: 'shares',
    value: 'shares',
    name: 'Share',
    label: 'Share',
    width: 80
  },
  {
    key: 'buttons',
    value: 'buttons',
    name: 'Button Click',
    label: 'Button Click',
    width: 120
  }
]

export const PostStatItemFull = [
  ...[],
  {
    key: 'post',
    value: 'post',
    name: 'New post',
    label: 'New post',
  },
  ... PostStatItems
]

export const PostTypeKeys = {
  ad: 'ad',
  post: 'post'
}

export const PostTypes = [
  {
    value: 'all',
    label: 'All Types'
  },
  {
    value: PostTypeKeys.post,
    label: 'Post'
  },
  {
    value: PostTypeKeys.ad,
    label: 'Advertisement'
  }
]

export const PostAdvertisementColors = [
  "#FFFFFC", 
  "#FFD6A5", 
  "#FDFFB6", 
  "#9BF6FF", 
  "#CAFFBF", 
  "#FFC6FF", 
  "#A0C4FF", 
  "#FFADAD", 
  "#BDB2FF", 
  "#00C8B8", 
  "#A09B68", 
  "#00B3FE", 
  "#FFB600", 
  "#C69842", 
  "#B98C51", 
  "#3CC730", 
  "#FF67B0", 
  "#FF565D", 
  "#FF5A34", 
  "#FF014A", 
  "#6A63FF", 
  "#005EFE", 
  "#A90129", 
  "#90015D", 
  "#014333", 
  "#1C306F", 
  "#4401A0", 
  "#013451"
];

export const PostAdvertisementColorTextWhite = [
  "#00C8B8", 
  "#A09B68", 
  "#00B3FE", 
  "#FFB600", 
  "#C69842", 
  "#B98C51", 
  "#3CC730", 
  "#FF67B0", 
  "#FF565D", 
  "#FF5A34", 
  "#FF014A", 
  "#6A63FF", 
  "#005EFE", 
  "#A90129", 
  "#90015D", 
  "#014333", 
  "#1C306F", 
  "#4401A0", 
  "#013451"
]

export const PostAdvertisementColorTextBlack = [
  "#FFFFFC", 
  "#FFD6A5", 
  "#FDFFB6", 
  "#9BF6FF", 
  "#CAFFBF", 
  "#FFC6FF", 
  "#A0C4FF", 
  "#FFADAD", 
  "#BDB2FF", 
]

export const getPostTextColorByBgColor = (bgColor) => {
  if (PostAdvertisementColorTextWhite.indexOf(bgColor) !== -1) {
    return "#FFFFFF"
  }
  if (PostAdvertisementColorTextBlack.indexOf(bgColor) !== -1) {
    return "#000000"
  }
  return null;
}