import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import Card from '../../../utilities/card/Card';
import CardHeader from '../../../utilities/card/CardHeader';
import each from 'lodash/each';
import { langTabs, langEn, langCht } from '../../../utilities/form/MultiLangSelector';
import { getFullDateDisplayByTimestampSecond } from '../../../../helpers/DateHelper';
import CardBody from '../../../utilities/card/CardBody';
import { attachmentTypes, createLocalFileObject, uploadLocalFileObjects, getHtmlContentByFileId, getHtmlContentByFile } from '../../../../helpers/FileHelper';
import FileInput from '../../../utilities/form/FileInput';
import { apiPost } from '../../../../helpers/Api';
import { Dialog, DialogContent } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TargetDomain } from '../../../../helpers/Constants';

function SettingTnc(props) {

  const defaultTncObj = {
    file_id: '',
    tncObjArrEn: [],
    uploadedTncEnSrcArr: [],
    tncObjArrTc: [],
    uploadedTncTcSrcArr: []
  }

  const [tnc, setTnc] = useState(defaultTncObj)

  const updateTncInfo = (update) => {
    setTnc({
      ...tnc,
      ...update
    })
  }

  const [isTncPreviewModalOpen, setIsTncPreviewModalOpen] = useState(false)
  const [tncPreviewContent, setTncPreviewContent] = useState('');
  const previewTnc = (files) => {
    if (files[0].attachmentId) {
      getHtmlContentByFileId(files[0].attachmentId)
        .then(({data}) => {
          setTncPreviewContent(data)
        })
    } else {
      getHtmlContentByFile(files[0].file, (html) => setTncPreviewContent(html))
    }
    setIsTncPreviewModalOpen(true)
  }
  const closePreview = () => {
    setTncPreviewContent('');
    setIsTncPreviewModalOpen(false)
  }

  const getTnc = () => {
    apiPost('/admin/company_tnc', {
      company_domain: TargetDomain
    })
      .then(({data}) => {
        let tnc = data;

        tnc.tncObjArrEn = [createLocalFileObject({attachmentId: tnc._i18n[langEn.key].html_id, name: tnc._i18n[langEn.key].html_id})]
        tnc.tncObjArrTc = [createLocalFileObject({attachmentId: tnc._i18n[langCht.key].html_id, name: tnc._i18n[langCht.key].html_id})]

        updateTncInfo({
          ...defaultTncObj,
          ...tnc
        })
      })
  }

  const checkInput = () => {

    return true;
  }

  const uploadHtml = (obj, callback) => {
    uploadLocalFileObjects({
      arr: obj,
      index: 0,
      uploadedIds: [],
      callbackWithIds: (ids) => {
        callback(ids)
      }
    })
  }

  const saveTnc = () => {
    let theTnc = {
      company_domain: TargetDomain,
      html_id: tnc.uploadedTncEnSrcArr[0],
      _i18n: {
        [langEn.key]: {
          html_id: tnc.uploadedTncEnSrcArr[0]
        },
        [langCht.key]: {
          html_id: tnc.uploadedTncTcSrcArr[0]
        }
      }
    }

    apiPost('/admin/company_tnc/set', theTnc)
      .then((resp) => {
        getTnc()
      })
  }

  const saveNow = () => {
    if (!checkInput()) {
      return
    }
    uploadHtml(
      tnc.tncObjArrEn,
      (ids) => {
        tnc.uploadedTncEnSrcArr = ids;
        uploadHtml(
          tnc.tncObjArrTc,
          (ids) => {
            tnc.uploadedTncTcSrcArr = ids
            saveTnc()
          }
        )
      }
    )
  }

  useEffect(() => {
    getTnc()
  }, [])
  
  return (
    <div className="item-set-container">
      <div className="full-width padding-bottom-30">
        <Card>
          <CardHeader>
            <div style={{display: "flex", alignItems: "center"}}>
              <div className="title">
                T&C  
              </div>
              {
                tnc.update_date &&
                <div>
                  Last updated at: {getFullDateDisplayByTimestampSecond(tnc.update_date)}
                </div>
              }
            </div>
            <div className="actions">
              <button className="button primary" onClick={saveNow}>
                <span>Save</span>
              </button>
            </div>
          </CardHeader>
        </Card>
        <div className="padding-bottom-30"></div>
        <Card>
          <CardHeader>
            <div className="title sub-title">
              Content ({langEn.name})
            </div>
            {
              tnc.tncObjArrEn.length > 0 
              &&
              <div className="actions">
                <button className="button info" onClick={()=>previewTnc(tnc.tncObjArrEn)}>
                  <span>Preview</span>
                </button>
              </div>
            }
          </CardHeader>
          <CardBody>
            <FileInput
              localFileObjArr={tnc.tncObjArrEn}
              onLocalFileObjArrChange={(arr) => updateTncInfo({tncObjArrEn: arr})}
              maxCount={1}
              acceptTypes={attachmentTypes.htmlTypes}
              text="Upload"
              isFileNameOnly={true}
            />
          </CardBody>
        </Card>
        <div className="padding-bottom-30"></div>
        <Card>
          <CardHeader>
            <div className="title sub-title">
              Content ({langCht.name})
            </div>
            {
              tnc.tncObjArrTc.length > 0 
              &&
              <div className="actions">
                <button className="button info" onClick={()=>previewTnc(tnc.tncObjArrTc)}>
                  <span>Preview</span>
                </button>
              </div>
            }
          </CardHeader>
          <CardBody>
            <FileInput
              localFileObjArr={tnc.tncObjArrTc}
              onLocalFileObjArrChange={(arr) => updateTncInfo({tncObjArrTc: arr})}
              maxCount={1}
              acceptTypes={attachmentTypes.htmlTypes}
              text="Upload"
              isFileNameOnly={true}
            />
          </CardBody>
        </Card>
      </div>
      <Dialog
        open={isTncPreviewModalOpen}
        onClose={closePreview}
        fullWidth={true}
        maxWidth="md"
      >
        <CardHeader>
          <div className="title">
            Preview
          </div>
          <div className="actions">
            <FontAwesomeIcon 
              className="clickable" 
              style={{marginLeft: 20}}
              icon="times" 
              fixedWidth 
              onClick={closePreview} />
          </div>
        </CardHeader>
        <DialogContent dividers>
          <div dangerouslySetInnerHTML={{__html: tncPreviewContent}} />
        </DialogContent>
      </Dialog>
    </div>
  )
}

SettingTnc.propTypes = {

}

export default SettingTnc

