import { apiUploadFile, apiGetTextResponse, getLocalFileByUrl } from "./Api";
import { MAX_FILE_SIZE } from "./Constants";
import last from 'lodash/last'
import { saveAs } from 'file-saver'

export const attachmentTypes = {
  image: 1,
  audio: 2,
  video: 3,
  pdf: 4,
  general: 5,
  html: 6,

  isValid: {
    valid: 0,
    invalidType: 1,
    invalidSize: 2
  },

  imageTypes: ['jpeg', 'jpg', 'png'],
  audioTypes: ['3gp'],
  videoTypes: ['mp4'],
  pdfTypes: ['pdf'],
  docTypes: ['doc', 'docx', 'xls', 'xlsx', 'ppt', 'pptx'],
  sheetTypes: ['csv', 'xls', 'xlsx'],
  htmlTypes: ['html']
}

export const getExtension = (filename) => {
  let split = filename.split('.');
  return split[split.length - 1].toLowerCase();
}

export const getFileType = (filename) => {
  let extension = getExtension(filename)
  if (attachmentTypes.imageTypes.includes(extension)) {
    return attachmentTypes.image;
  }
  if (attachmentTypes.audioTypes.includes(extension)) {
    return attachmentTypes.audio;
  }
  if (attachmentTypes.videoTypes.includes(extension)) {
    return attachmentTypes.video;
  }
  if (attachmentTypes.pdfTypes.includes(extension)) {
    return attachmentTypes.pdf;
  }
  if (attachmentTypes.docTypes.includes(extension)) {
    return attachmentTypes.pdf;
  }
  return attachmentTypes.general;
}

export const getFileSizeString = (size) => {
  let suffix = ['B', 'KB', 'MB', 'GB', 'TB'];
  let suffixIndex = 0;
  while (size >= 1024) {
    suffixIndex++;
    size = size / 1024;
  }
  return (Math.floor(size * 100) / 100).toString() + suffix[suffixIndex];
}

export const createLocalFileObject = ({attachmentId, file, name}) => {
  return {
    attachmentId: attachmentId,
    file: file,
    name: name
  }
}

export const uploadLocalFileObjects = ({arr, index, callbackWithIds, uploadedIds}) => {
  if (index === arr.length) {
    callbackWithIds(uploadedIds);
    return;
  }
  if (arr[index].file) {
    apiUploadFile(arr[index].file)
      .then((resp) => {
        uploadedIds.push(resp.data)
        uploadLocalFileObjects({arr, index: ++index, callbackWithIds, uploadedIds})
      })
  } else {
    uploadedIds.push(arr[index].attachmentId)
    uploadLocalFileObjects({arr, index: ++index, callbackWithIds, uploadedIds})
  }
}

export const checkIfFileIsValidSize = (file) => {
  // console.log(file.size)
  return (file.size / 1024 / 1024) < MAX_FILE_SIZE;
}

export const getHtmlContentByFileId = (fileId, param) => {
  return apiGetTextResponse(`/static/${fileId}`, param);
}

export const getHtmlContentByFile = (file, callback) => {
  let reader  = new FileReader();
  reader.addEventListener("load", function () {
    callback(reader.result)
  }, false);
  if (file) {
    reader.readAsText(file);
  }
}

export const dataUrlToFile = (dataUrl, fileName) => {
  let byteString;
  if (dataUrl.split(',')[0].indexOf('base64') >= 0) {
      byteString = atob(dataUrl.split(',')[1]);
  } else {
      byteString = unescape(dataUrl.split(',')[1]);
  }

  // separate out the mime component
  let mimeString = dataUrl.split(',')[0].split(':')[1].split(';')[0];

  // write the bytes of the string to a typed array
  let ia = new Uint8Array(byteString.length);
  for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
  }

  let blob = new Blob([ia], {type: mimeString});

  // IE & Edge doesn't support File constructor
  // let file = new File([blob], fileName, {type: mimeString});
  // return file;

  blob.lastModifiedDate = new Date();
  blob.name = fileName;
  blob.imagePreview = dataUrl;
  return blob;
}

export const fileToDataUrl = (file, callback) => {
  let reader = new FileReader();
  reader.onload = (e) => {
    callback(e.target.result);
  };
  reader.readAsDataURL(file);
}

export const getBlobFromUrl = ({url, param, callback}) => {
  getLocalFileByUrl(url)
    .then((resp) => {
      let filename;
      let disposition = resp.headers['content-disposition'];
      if (disposition && disposition.indexOf('attachment') !== -1) {
        const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
        let matches = filenameRegex.exec(disposition);
        if (matches != null && matches[1]) {
          filename = matches[1].replace(/['"]/g, '');
        }
      }

      if (param && param.filename) {
        if (filename) {
            let components = filename.split(".");
            let extension = components[components.length - 1];
            filename = [param.filename, extension].join(".");
        }
      }

      let type = resp.headers['content-type'].split(';')[0];

      let blob = new Blob([resp.data], {type: type});

      fileToDataUrl(blob, (dataUrl) => {
        callback(dataUrlToFile(dataUrl, last(url.split('/'))))
      })
    })
}

export const saveFileByDataUrl = (dataUrl, fileName) => {
  let file = dataUrlToFile(dataUrl, fileName)
  saveAs(file, fileName)
}