import React from 'react'
import PropTypes from 'prop-types'
import OnlineUserCard from './cards/OnlineUserCard'
import InstalledUserCard from './cards/InstalledUserCard'

function OverviewTab(props) {

  return (
    <>
      <div className="card-row">
        <OnlineUserCard className="card-row-50" />
        <InstalledUserCard className="card-row-50" />
      </div>
    </>
  )
}

OverviewTab.propTypes = {

}

export default OverviewTab

