import React, {useState, useEffect, useRef} from 'react'
import { connect } from 'react-redux'

import Card from '../../../utilities/card/Card';
import CardHeader from '../../../utilities/card/CardHeader';
import List from '../../../utilities/list/List';
import ToolsRenderer from '../../../utilities/list/ToolsRenderer';
import { searchFilter } from '../../../../helpers/SearchHelper';
import { apiPost } from '../../../../helpers/Api';

import each from 'lodash/each'
import find from 'lodash/find'
import DateRenderer from '../../../utilities/list/DateRenderer';
import {TargetDomain} from '../../../../helpers/Constants';
import { defaultLang } from '../../../utilities/form/MultiLangSelector';


function PostTemplateList(props) {
  const { basePath, history } = props;

  const [originalData, setOriginalData] = useState([]);
  const [listKeyword, setListKeyword] = useState('');
  const lengthRef = useRef(originalData.length);
  const [headerOptions, setHeaderOptions] = useState({
    enable: true,
    isSearch: true,
    searchableFields: ['create_date' ,'title', 'caption', 'post_template_name', 'author.name', 'categoryDisplay', 'author_user_group.name' ],
    filters: [
      // {value: 'all', label: "All Categories"},
    ],
    onHeaderChange: (keyword) => {
      setListKeyword(keyword);
      // setListFilter(filter);
    }
  })

  const [categoryList, setCategoryList] = useState([])
  const getCategoryList = () => {
    let param = {

    }
    apiPost('/admin/post/category', param)
    .then((resp) => {
      setCategoryList(resp.data)
      resp.data.sort((a, b) => {
        return a.name.toLowerCase().localeCompare(b.name.toLowerCase())
      })

      let categories = [{value: 'all', label: "All Categories"}];

      each(resp.data, (cat) => {
        categories.push({
          label: cat.name,
          value: cat.category_id
        })
      })

      // setHeaderOptions({
      //   ...headerOptions,
      //   ...{filters: categories}
      // })

      // setAllCategoryOptions(categories)
      // setCategoryFilter(categories[0])

      getPostTemplateList()
    })
  }
  useEffect(() => {
    search()
  }, [categoryList])

  const columns = [
    {
      headerName: "Create Date",
      field: "create_date",
      sort: "desc",
      cellRenderer: 'dateRenderer'
    },
    {
      headerName: "Template ID",
      field: "post_template_name",
      comparator: (a, b) => {
        const isANumber = /^\d+$/.test(a);
        const isBNumber = /^\d+$/.test(b);

        if (isANumber && !isBNumber) {
          return 1;
        } else if (!isANumber && isBNumber) {
          return -1;
        } else if (!isANumber && !isBNumber) {
          return a.localeCompare(b);
        } else {
          return parseInt(a) - parseInt(b);
        }
      }
    },
    { 
      headerName: "Title", 
      field: "title"
    },
    { 
      headerName: "Caption", 
      field: "caption"
    },
    { 
      headerName: "Author", 
      field: "author.name"
    },
    {
      headerName: "Department",
      field: "author_user_group.name"
    },
    { 
      headerName: "Category", 
      field: "categoryDisplay"
    },
    { 
      headerName: "Tools", 
      field: "tools", 
      pinned: "right",
      width: 130,
      minWidth: 130,
      maxWidth: 130,
      sortable: false,
      cellRenderer: 'toolsRenderer',
      cellRendererParams: {
        onClickCallback: {
          edit: (row) => {
            history.push(`${basePath}/edit/post_template/${row.post_template_id}`);
          },
          remove: (row) => {
            deletePostTemplate(row);
          },
          copy: (row) => {
            copyPostTemplate(row);
          }
        },
        types: ["edit", "remove", "copy"]
      }
    }
  ];

  const [gridOptions, setGridOptions] = useState({
    columnDefs: columns,
    sortingOrder: ["desc", "asc"],
    frameworkComponents: {
      toolsRenderer: ToolsRenderer,
      dateRenderer: DateRenderer
    }
  });

  const [rowData, setRowData] = useState([...originalData]);

  const search = () => {
    let filtered = [ ...originalData ];

    filtered = searchFilter(filtered, headerOptions.searchableFields, listKeyword);

    each(filtered, (p) => {
      if (p.category_ids && p.category_ids.length > 0) {
        let target = find(categoryList, {category_id: p.category_ids[0]})
        if (target) {
          p.categoryDisplay = target.name
        }
      }
    })

    setRowData(filtered);
  }
  
  const getPostTemplateList = () => {
    apiPost('/admin/post_template', )
      .then((resp) => {
        let post_templates = resp.data;
          setOriginalData(post_templates);
      })
  }

  const deletePostTemplate = (item) => {
    let param = {
      post_template_id: item.post_template_id,
    }
    let url = '/admin/post_template/delete';
    
    apiPost(url, param)
      .then((resp) => {
        getPostTemplateList();
      })
      .catch((err) => {
        console.error(err);
      })
  }

  const copyPostTemplate = (item) => {
    let get_post_template_param = {
      post_template_id: item.post_template_id
    }

    let check_post_template_total_param = {
      company_domain: TargetDomain,
      with_deleted: true
    }

    apiPost('/admin/post_template', check_post_template_total_param)
        .then((resp) => {
          apiPost('/admin/post_template', get_post_template_param)
              .then((resp) => {
                let post_template = resp.data;

                each(post_template._i18n, (lang, key) => {
                  lang.title = `Copy of ${lang.title}`
                })

                let defaultLangContent = post_template._i18n[defaultLang.key];

                let thePostTemplate = {
                  ...post_template,
                  post_template_id: null,
                  post_template_name: '',
                  title: defaultLangContent.title,
                  caption: defaultLangContent.caption,
                  html_content: defaultLangContent.html_content,
                  _i18n: post_template._i18n,
                  display_start: new Date().getTime() / 1000,
                }

                history.push({
                  pathname: `${basePath}/add`,
                    state: {
                        post_template: thePostTemplate,
                    }
                })
              })
        })
  }

  useEffect(() => {
    getCategoryList()
  }, [])

  useEffect(() => {
    search()
    lengthRef.current = originalData.length
  }, [originalData])

  useEffect(() => {
    search()
  }, [listKeyword])

  return (
    <div className="list-container">
      <div className="header">
        <Card>
          <CardHeader>
            <div className="title">Templates</div>
            <div className="actions">
              {/*<button */}
              {/*  className="button info"*/}
              {/*  onClick={exportPostStat}*/}
              {/*>*/}
              {/*  <a>Export</a>*/}
              {/*</button>*/}
              <button 
                className="button default"
                onClick={() => history.push(`${basePath}/add`)}
              >
                <a>Add New</a>
              </button>
            </div>
          </CardHeader>
        </Card>
        <br></br>
      </div>
      <div className="body">
        <List 
          headerOptions={headerOptions}
          gridOptions={gridOptions}
          rowData={rowData}
        />
      </div>
    </div>
  )
}

PostTemplateList.propTypes = {

}

const mapStateToProps = (state) => ({
  
})

const mapDispatchToProps = {
  
}

export default connect(mapStateToProps, mapDispatchToProps)(PostTemplateList)
