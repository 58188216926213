import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import Flatpickr from 'react-flatpickr'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendar } from '@fortawesome/free-regular-svg-icons';

function DateInput(props) {
  const {
    value,
    onChange,
    placeholder,
    isShowLabel,
    isAlignRight,
    isHoverBlue,
    minDate,
    isInline,
    isDisabled
  } = props;

  const [date, setDate] = useState(value);

  const handleSelect = (date) => {
    setDate(date);
    if (onChange) {
      onChange(date);
    }
  }

  let pickerClass = ["form-input"];
  if (isAlignRight) {
    pickerClass.push("text-align-right")
  }
  if (isHoverBlue) {
    pickerClass.push("hover-blue-text")
  } else {
    pickerClass.push("hover-opacity")
  }


  useEffect(() => {
    setDate(value)
  }, [value])

  return (
    <div className="input-container" style={{...props.style, ...{justifyContent: isAlignRight ? 'flex-end' : 'flex-start'}}}>
      {
        isShowLabel &&
        <div className="input-label">
          <span>
            {placeholder}
          </span>
          <FontAwesomeIcon icon={faCalendar} fixedWidth />
        </div>
      }
      <div className={isInline ? "form-input" : ''}>
        <Flatpickr
          className={pickerClass.join(' ')}
          placeholder={placeholder}
          value={date}
          onChange={handleSelect} 
          options={{
            enableTime: true,
            minDate: minDate,
            inline: isInline
          }}
          disabled={isDisabled}
        />
      </div>
    </div>
  )
}

DateInput.propTypes = {

}

export default DateInput

