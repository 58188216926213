import React from 'react'
import PropTypes from 'prop-types'

import { Switch, Route, Redirect } from 'react-router-dom'
import PromotionList from './list/PromotionList'
import PromotionSet from './set/PromotionSet'

function Promotion(props) {
  const { match } = props;
  
  return (
    <div className="page-container">
      <Switch>
        <Route path={`${match.url}/list`} 
          render={props => <PromotionList {...props} basePath={match.url} />}/>
        <Route path={`${match.url}/add`} 
          render={props => <PromotionSet {...props} basePath={match.url} />}/>
        <Route path={`${match.url}/edit/:id`} 
          render={props => <PromotionSet {...props} basePath={match.url} />}/>
        <Redirect to={`${match.url}/list`}/>
      </Switch>
    </div>
  )
}

Promotion.propTypes = {

}

export default Promotion

