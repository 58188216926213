import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import Card from '../../../utilities/card/Card';
import CardHeader from '../../../utilities/card/CardHeader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CardBody from '../../../utilities/card/CardBody';
import ChartLine from '../../../utilities/chart/ChartLine';
import SelectInput from '../../../utilities/form/SelectInput';
import Tab from '../../../utilities/tab/Tab';
import each from 'lodash/each';
import sumBy from 'lodash/sumBy';
import { PostStatItems } from '../../../../helpers/PostHelper';
import map from 'lodash/map';
import List from '../../../utilities/list/List';
import union from 'lodash/union';
import { exportJsonArrToExcel } from '../../../../helpers/ExcelExportHelper';
import { getExportFileNameDisplay } from '../../../../helpers/DateHelper';
import { DialogContent, DialogTitle } from '@material-ui/core';

const PostStatTabKey = {
  general: 'general',
  url: 'url'
}

const PostStatTabs = [
  {
    key: PostStatTabKey.general,
    name: "General"
  },
  {
    key: PostStatTabKey.url,
    name: "URL"
  }
]

function PostStat(props) {
  const {
    post,
    stat,
    urlStat,
    urls,
    handleClose
  } = props;

  console.log(urlStat, urls);

  const [activeTab, setActiveTab] = useState(PostStatTabs[0])

  const [summaryStat, setSummaryStat] = useState({})
  const [isDisplayChart, setIsDisplayChart] = useState(true)

  const [curUrl, setCurUrl] = useState(null)

  const exportStat = () => {
    let data = []

    each(stat, (d) => {
      let row = {}
      row["Date"] = d.date
      each(PostStatItems, (item) => {
        row[item.label] = d[item.label]
      })
      data.push(row)
    })
    exportJsonArrToExcel(
      {
        "Post Statistics": data
      },
      `stat_post_${post.title}_${getExportFileNameDisplay()}.xlsx`,
      "xlsx"
    )
  }

  useEffect(() => {
    if (stat.length === 0) {
      return;
    }

    let allStat = {}
    each(PostStatItems, (item) => {
      allStat[item.key] = sumBy(stat, item.key) || 0
    })

    setSummaryStat(allStat)
  }, [stat])


  const [curUrlStat, setCurUrlStat] = useState([])
  const parseCurUrlStat = () => {
    let chart = []
    each(urlStat, (monthStat) => {
      chart.push({
        date: monthStat.date,
        'Click Rate': monthStat[curUrl.label] || 0
      })
    })
    setCurUrlStat(chart)
  }

  const exportUrlStat = (targetUrl) => {
    if (!urlStat || urlStat.length === 0) {
      return;
    } 
    let data = []
    
    if (targetUrl) {
      let row = {}
      row["URL"] = targetUrl.label
      each(urlStat, (interval) => {
        row[interval.date] = interval[targetUrl.label] || 0
      })
      data.push(row)
    } else {
      each(urls, (url) => {
        let row = {}
        row["URL"] = url.label
        each(urlStat, (interval) => {
          row[interval.date] = interval[url.label] || 0
        })
        data.push(row)
      })
    }

    exportJsonArrToExcel(
      {
        "URL Click Rate": data
      },
      `stat_url_clickrate_${post.title}_${getExportFileNameDisplay()}.xlsx`,
      "xlsx"
    )
  }

  useEffect(() => {
    if (!curUrl) {
      return;
    }
    parseCurUrlStat()
  },[curUrl])

  return (
    <>
      <DialogTitle>
        <CardHeader>
          <div className="title">
            {post.title}
          </div>
          <div className="actions">
            <Tab tabs={PostStatTabs} activeTab={activeTab} switchActiveTab={setActiveTab} />
            <FontAwesomeIcon className="clickable" icon="times" fixedWidth onClick={handleClose} />
          </div>
        </CardHeader>
      </DialogTitle>
      <DialogContent>
        {
          activeTab.key === PostStatTabKey.general &&
          <>
            <div className="stat-cell-container">
              {
                PostStatItems.map((item, key) => {
                  return (
                    <div className="stat-cell" key={item.key}>
                      <div className="count">
                        {summaryStat[item.key]}
                      </div>
                      <div className="name">
                        {item.label}
                      </div>
                    </div>
                  )
                })
              }
            </div>
            <CardHeader>
              <div>
              </div>

              <div className="actions">
                <button className={`button ${isDisplayChart ? 'primary' : 'default'}`}
                  onClick={() => setIsDisplayChart(true)}
                >
                  <a>Chart</a>
                </button>
                <button className={`button ${!isDisplayChart ? 'primary' : 'default'}`} 
                  onClick={() => setIsDisplayChart(false)}
                >
                  <a>Table</a>
                </button>
                <button className="button info" onClick={exportStat}>
                  <a>Export</a>
                </button>
              </div>
            </CardHeader>
            <CardBody>
              {
                (stat && stat.length > 0)
                ?
                <>
                  {
                    isDisplayChart
                    ?
                    <ChartLine 
                      data={stat}
                      xKey='date'
                      yKeys={map(PostStatItems, 'label')}
                      chartTitle={`Post Statistics (${post.title})`}
                      isShowLegend
                      isAllowSave
                    />
                    :
                    <>
                      <div className="text-center text-header">
                        {`Post Statistics (${post.title})`}
                      </div>
                      <div style={{height: 500}}>
                        <List 
                          rowData={stat}
                          headerOptions={{enable: false}}
                          gridOptions={{
                            suppressPaginationPanel: true,
                            minColWidth: 10,
                            columnDefs: union(
                              [{headerName: 'Date', field: 'date', pinned: 'left', sortable: false}],
                              map(PostStatItems, (item) => {
                                return {
                                  headerName: item.label,
                                  field: item.label
                                }
                              })
                            )
                          }}
                        />
                      </div>
                    </>
                  }
                </>
                :
                <div className="center-info-msg">
                  No data
                </div>
              }
            </CardBody>
          </>
        }

        {
          activeTab.key === PostStatTabKey.url &&
          <>
            {
              urls.length > 0
              ?
              <>
                <CardHeader>
                  <div className="full-width">
                    <SelectInput 
                      options={urls}
                      value={curUrl}
                      onChange={setCurUrl}
                    />
                  </div>
                  <div className="actions">
                    {
                      curUrl &&
                      <button className="button info" onClick={() => exportUrlStat(curUrl)}>
                        <a>Export</a>
                      </button>
                    }
                    <button className="button info" onClick={() => exportUrlStat(null)}>
                      <a>Export (All)</a>
                    </button>
                  </div>
                </CardHeader>

                {
                  curUrl 
                  ?
                  <ChartLine 
                    data={curUrlStat}
                    xKey='date'
                    yKey='Click Rate'
                    chartTitle={`URL Click Rate (${post.title}) (${curUrl.label})`}
                    isShowLegend
                    isAllowSave
                  />
                  :
                  <div className="center-info-msg">
                    Please select URL
                  </div>
                }

              </>
              :
              <div className="center-info-msg">
                No URL
              </div>
            }
          </>
        }
      </DialogContent>
    </>
  )
}

PostStat.propTypes = {

}

export default PostStat

