import React, { Component, useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import { connect } from 'react-redux'

import "./Dashboard.scss"
import OverviewTab from './OverviewTab'
import Card from '../../utilities/card/Card'
import Tab from '../../utilities/tab/Tab'
import UserStatTab from './UserStatTab'
import PostStatTab from './PostStatTab'
import CategorySubTab from './CategorySubTab'
import PostSearchTab from './PostSearchTab'
import WebviewLogTab from './WebviewLogTab'

export const DashboardTabKey = {
  overview: "overview",
  online_user: "online_user",
  user_install: "user_install",
  post: "post",
  category_subscribe: "category_subscribe",
  user_stat: "user_stat",
  post_search: "post_search",
  webview_log: "webview_log"
}

const tabs = [
  {
    key: DashboardTabKey.overview,
    name: "Overview"
  },
  {
    key: DashboardTabKey.user_stat,
    name: "User"
  },
  {
    key: DashboardTabKey.post,
    name: "Post"
  },
  {
    key: DashboardTabKey.post_search,
    name: "Post Searching"
  },
  {
    key: DashboardTabKey.category_subscribe,
    name: "Category Subscription"
  },
  {
    key: DashboardTabKey.webview_log,
    name: "URL Click Rate"
  }
];

function Dashboard(props) {
  const { history } = props;

  const [activeTab, setActiveTab] = useState(tabs[0]);

  return (
    <div className="dashboard-container padding-bottom-30">
      <Card>
        <Tab tabs={tabs} activeTab={activeTab} switchActiveTab={setActiveTab} />
      </Card>

      <div className="padding-bottom-30"></div>

      {
        activeTab.key === DashboardTabKey.overview &&
        <OverviewTab goToPostDashboard={() => history.push('/portal/post/dashboard')} />
      }

      {
        activeTab.key === DashboardTabKey.user_stat &&
        <UserStatTab />
      }

      {
        activeTab.key === DashboardTabKey.post &&
        <PostStatTab history={history }/>
      }

      {
        activeTab.key === DashboardTabKey.category_subscribe &&
        <CategorySubTab />
      }

      {
        activeTab.key === DashboardTabKey.post_search &&
        <PostSearchTab />
      }

      {
        activeTab.key === DashboardTabKey.webview_log &&
        <WebviewLogTab />
      }

    </div>
  )
}

Dashboard.propTypes = {

}

const mapStateToProps = (state) => ({
  
})

const mapDispatchToProps = {
  
}

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard)

