import React from 'react'
import PropTypes from 'prop-types'

import { Switch, Route, Redirect } from 'react-router-dom'
import AdminList from './list/AdminList'
import AdminSet from './set/AdminSet'

function Admin(props) {
  const {
    match
  } = props
  return (
    <div className="page-container">
      <Switch>
        <Route path={`${match.url}/list`} 
          render={props => <AdminList {...props} basePath={match.url} />}/>
        <Route path={`${match.url}/add`} 
          render={props => <AdminSet {...props} basePath={match.url} />}/>
        <Route path={`${match.url}/edit/:id`} 
          render={props => <AdminSet {...props} basePath={match.url} />}/>
        <Redirect to={`${match.url}/list`}/>
      </Switch>
    </div>
  )
}

Admin.propTypes = {

}

export default Admin

