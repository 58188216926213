import React, {Component, useEffect, useState} from 'react'
import PropTypes from 'prop-types'

import { AgGridReact } from 'ag-grid-react'


function ListBody(props) {
  const { gridOptions, rowData, setGridApi } = props;

    return (
    <div className="ag-theme-material list-body">
      <AgGridReact 
        gridOptions={{
          ...gridOptions, 
          suppressCellSelection: true,
          enableCellTextSelection: true,
          suppressMovableColumns: true,
          enableSorting: true,
          paginationPageSize: 25,
          defaultColDef: {
            resizable: true,
            autoHeight: true,
          },
          minColWidth: gridOptions.minColWidth || 200,
          overlayNoRowsTemplate: 'No Data'
        }}
        onFirstDataRendered={(params) => {
          params.api.sizeColumnsToFit();
        }}
        onGridReady={(params) => {
          params.api.sizeColumnsToFit();
          
          window.addEventListener('resize', (event) => {
            setTimeout(() => {
              params.api.sizeColumnsToFit();
            })
          })

            if (setGridApi) {
                setGridApi(params.api)
            }
        }}
        onRowDataChanged={(params) => {
          params.api.paginationGoToPage(0);
        }}
        rowData={rowData}
        pagination={true}>
      </AgGridReact>
    </div>
  )
}

ListBody.propTypes = {

}

export default ListBody
