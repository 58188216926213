// customToast.js
import { toast as originalToast } from 'react-toastify';

// Wrapper function that sets the default autoClose to a custom value
const duration = 6000;

export const toast = (message, options = {}) => {
    const defaultOptions = { autoClose: duration };
    return originalToast(message, { ...defaultOptions, ...options });
};

// Copy all the methods from the original toast object to our custom toast
Object.keys(originalToast).forEach((key) => {
    if (typeof originalToast[key] === 'function') {
        toast[key] = (...args) => {
            if (args.length > 1) {
                const [message, options] = args;
                const defaultOptions = { autoClose: duration };
                return originalToast[key](message, { ...defaultOptions, ...options });
            }
            return originalToast[key](...args);
        };
    }
});

// Export all other toast methods (e.g., toast.success, toast.error, etc.)
toast.success = (message, options = {}) => {
    const defaultOptions = { autoClose: duration };
    return originalToast.success(message, { ...defaultOptions, ...options });
};

toast.error = (message, options = {}) => {
    const defaultOptions = { autoClose: duration };
    return originalToast.error(message, { ...defaultOptions, ...options });
};

toast.info = (message, options = {}) => {
    const defaultOptions = { autoClose: duration };
    return originalToast.info(message, { ...defaultOptions, ...options });
};

toast.warn = (message, options = {}) => {
    const defaultOptions = { autoClose: duration };
    return originalToast.warn(message, { ...defaultOptions, ...options });
};

// Export other methods if needed
export default toast;