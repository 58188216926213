export const i18nEn = {
  'login.title1': 'Hello',
  'login.title2': 'Sign in to manage your works on HKG Community',
  'login.adfs': 'Login with ADFS',
  'login.login': 'Login',
  'login.username': 'Username',
  'login.password': 'Password',

  'dashboard.title': 'Dashboard',

  'post.title': 'Post',
  'post.dashboard': 'Dashboard',
  'post.allPosts': 'All Posts',
  'post.addNew': 'Add New',

  'post_template.title': 'Post Template',
  'post_template.allPostTemplates': 'All Templates',
  'post_template.addNew': 'Add New',

  'notification.title': 'Notifications',

  'category.title': 'Categories',

  'user.title': 'User',
  'user.allUsers': 'All Users',
  'user.addNew': 'Add New',
  'user.userGroup': 'User Group',
  'user.roles': 'Roles',

  'event.title': 'Event',
  'event.allEvents': 'All Events',
  'event.addNew': 'Add New',

  'survey.title': 'Survey',
  'survey.allSurveys': 'All Surveys',
  'survey.addNew': 'Add New',
  
  'promotion.title': 'Coupon',
  'promotion.allPromotions': 'All Coupon',
  'promotion.addNew': 'Add New',

  'setting.title': 'Setting',
  'setting.general': 'General',
  'setting.accPage': 'ASC Page',
  'setting.company': 'Company',
  'setting.phoneBook': 'Airport Phone Book',
  'setting.jargonBuster': 'Jargon Buster',
  'setting.tnc': 'T&C',
  'setting.post': 'Post'
  
}