import store from "../redux/store"
import { setConfirmModalState } from "../redux/actions"

export const openConfirmModal = ({
  title, 
  message,
  closeCallback,
  confirmCallback,
  isHideCancel
}) => {
  store.dispatch(setConfirmModalState({
    isDisplay: true,
    title: title,
    message: message,
    closeCallback: () => {
      closeConfrimModal()
      if (closeCallback) {
        closeCallback()
      }
    },
    confirmCallback: () => {
      closeConfrimModal()
      if (confirmCallback) {
        confirmCallback()
      }
    },
    isHideCancel: isHideCancel
  }));
}

export const closeConfrimModal = () => {
  store.dispatch(setConfirmModalState({
    isDisplay: false
  }))
}