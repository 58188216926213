import * as XLSX from 'xlsx'
import { saveAs } from 'file-saver'
import each from 'lodash/each';

export const sheetFileToJson = (file, callback) => {

  const getJson = function(wb) {
    let json = this.workbookToJson(wb);
    callback(json);
  }.bind(this);

  let wb = this.sheetFileToWorkbook(file, getJson);
}

export const sheetFileToWorkbook = (file, callback) => {
  if (!FileReader.prototype.readAsBinaryString) {
    console.log('readAsBinaryString definition not found');

    FileReader.prototype.readAsBinaryString = function (fileData) {
      let binary = '';
      let pk = this;
      let reader = new FileReader();

      reader.onload = function (e) {
        let bytes = new Uint8Array(reader.result);
        const length = bytes.byteLength;

        for (let i = 0; i < length; i++) {
          let a = bytes[i];

          let b = String.fromCharCode(a);
          binary += b;
        }

        pk.content = binary;
        //TODO: no jquery here... fix if need to import excel.
        // $(pk).trigger('onload');
      };

      reader.readAsArrayBuffer(fileData);
    };
  }

  let reader = new FileReader();
  
  reader.onload = function (e) {

    if (reader.result) {
      reader.content = reader.result;
    }

    //In IE browser event object is null
    let data = e ? e.target.result : reader.content;
    let baseEncoded = btoa(data);
    let wb = XLSX.read(baseEncoded, {type: 'base64'});

    callback(wb);
  };

  reader.readAsBinaryString(file);
}

export const workbookToJson = (wb) => {
  let result = {};
  wb.SheetNames.forEach(function(sheetName) {
    let roa = XLSX.utils.sheet_to_json(wb.Sheets[sheetName]);
    if(roa.length) {
      result[sheetName] = roa;
    }
  });
  return JSON.stringify(result, 2, 2);
}

/* generate a download */
const s2ab = (s) => {
  let buf = new ArrayBuffer(s.length);
  let view = new Uint8Array(buf);
  for (let i=0; i < s.length; ++i) {
    view[i] = s.charCodeAt(i) & 0xFF;
  }
  return buf;
}

export const exportJsonToCsv = (data, fileName, type) => {
  /* generate a worksheet */
  let ws = XLSX.utils.json_to_sheet(data);

  /* add to workbook */
  let wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws);

  /* write workbook (use type 'binary') */
  let wbout = XLSX.write(wb, {bookType: type ? type : 'csv', type:'binary'});

  saveAs(new Blob([s2ab(wbout)],{type:"application/octet-stream"}), fileName);
}

export const exportJsonArrToExcel = (sheetArr, fileName, type) => {
  let wb = XLSX.utils.book_new();

  each(sheetArr, function (sheetData, sheetName) {
    let ws = XLSX.utils.json_to_sheet(sheetData);
    XLSX.utils.book_append_sheet(wb, ws, sheetName);
  });

  /* write workbook (use type 'binary') */
  let wbout = XLSX.write(wb, {bookType: type ? type : 'csv', type:'binary'});

  saveAs(new Blob([s2ab(wbout)],{type:"application/octet-stream"}), fileName);
}