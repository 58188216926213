import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import Card from '../../../utilities/card/Card';
import CardHeader from '../../../utilities/card/CardHeader';
import CardBody from '../../../utilities/card/CardBody';
import Tab from '../../../utilities/tab/Tab';
import SelectInput from '../../../utilities/form/SelectInput';
import DateRangeInput from '../../../utilities/form/DateRangeInput';
import ChartBar from '../../../utilities/chart/ChartBar';

import subMonths from 'date-fns/subMonths'
import isBefore from 'date-fns/isBefore'
import format from 'date-fns/format'
import addDays from 'date-fns/addDays'
import SimpleTable from '../../../utilities/table/SimpleTable';
import TopPostReachCard from '../../dashboard/cards/TopPostReachCard';
import TopPostLikeCard from '../../dashboard/cards/TopPostLikeCard';
import { apiPost, apiExportFile } from '../../../../helpers/Api';
import List from '../../../utilities/list/List';
import each from 'lodash/each'
import DateRenderer from '../../../utilities/list/DateRenderer';
import { getTimestampSecRangeForApi, getExportFileNameDate, getTimestampSecForApi, getChartMonthDateDisplayByTimestampSecond, getSimpleDateDisplayByTimestampSecond } from '../../../../helpers/DateHelper';
import ToolsRenderer from '../../../utilities/list/ToolsRenderer';
import { searchFilter } from '../../../../helpers/SearchHelper';
import { postStatusEnum, PostStatItems } from '../../../../helpers/PostHelper';
import union from 'lodash/union';
import map from 'lodash/map';
import find from 'lodash/find';
import { Dialog } from '@material-ui/core';
import PostStat from '../stat/PostStat';
import { addWeeks } from 'date-fns';

const tabs = [
  {
    key: "reads",
    name: "Reads"
  },
  {
    key: "likes",
    name: "Likes"
  },
  {
    key: "save",
    name: "Save"
  },
  {
    key: "share",
    name: "Share"
  }
];

function PostDashboard(props) {
  const { history, basePath } = props;
  
  const [range, setRange] = useState([subMonths(new Date(), 6), addWeeks(new Date(), 2)])


  const [categoryFilter, setCategoryFilter] = useState(null);
  const [allCategoryOptions, setAllCategoryOptions] = useState([]);

  const [originalData, setOriginalData] = useState([]);
  const [listKeyword, setListKeyword] = useState('');
  const [listFilter, setListFilter] = useState(null);
  const [headerOptions, setHeaderOptions] = useState({
    enable: true,
    isSearch: true,
    searchableFields: ['title', 'caption', 'html_content', 'author.name'],
    filters: [
      // {value: 'all', label: "All Categories"},
    ],
    onHeaderChange: (keyword, filter) => {
      setListKeyword(keyword);
      setListFilter(filter);
    }
  })

  const [categoryList, setCategoryList] = useState([])
  const getCategoryList = () => {
    let param = {

    }
    apiPost('/admin/post/category', param)
    .then((resp) => {
      setCategoryList(resp.data)
      resp.data.sort((a, b) => {
        return a.name.toLowerCase().localeCompare(b.name.toLowerCase())
      })

      let categories = [{value: 'all', label: "All Categories"}];

      each(resp.data, (cat) => {
        categories.push({
          label: cat.name,
          value: cat.category_id
        })
      })

      // setHeaderOptions({
      //   ...headerOptions,
      //   ...{filters: categories}
      // })

      setAllCategoryOptions(categories)
      setCategoryFilter(categories[0])

      getPostStat()
    })
  }

  const columns = union(
  [
    {
      headerName: "Date",
      field: "display_start",
      sort: 'desc',
      cellRenderer: 'dateRenderer'
    },
    {
      headerName: "Title",
      field: "title"
    },
    {
      headerName: "Category",
      field: "categoryDisplay"
    },
    {
      headerName: "Status",
      field: "statusDisplay"
    }
  ],
  map(PostStatItems, (item) => {
    return {
      headerName: item.name,
      field: item.key,
      // width: item.width,
      // minWidth: item.width,
      // maxWidth: item.width
    }
  }),
  [
    {
      headerName: "Stats",
      field: "post_id",
      pinned: "right",
      width: 80,
      minWidth: 80,
      maxWidth: 80,
      cellRenderer: 'toolsRenderer',
      cellRendererParams: {
        onClickCallback: {
          view: (row) => {
            history.push(`${basePath}/edit/${row.post_id}`);
          },
          stat: (row) => {
            viewPostStat(row)
          }
        },
        types: ['stat']
      },
    }
  ])

  const [gridOptions, setGridOptions] = useState({
    columnDefs: columns,
    frameworkComponents: {
      dateRenderer: DateRenderer,
      toolsRenderer: ToolsRenderer
    }
  });

  const [rowData, setRowData] = useState([]);

  const getPostStatParam = () => {
    let postStatParam = {
      display_start: getTimestampSecRangeForApi(range),
      category_ids: null
    }

    if (listFilter) {
      if (listFilter.value === 'all') {
        postStatParam.category_ids = null
      } else {
        postStatParam.category_ids = [listFilter.value]
      }
    }

    return postStatParam
  }

  const getPostStat = () => {
    let param = getPostStatParam()
    apiPost('/admin/post/stats', param)
      .then(({data}) => {
        each(data, (post) => {
          post.categoryDisplay = post.categories.length > 0 ? post.categories[0].name : ''
          post.statusDisplay = postStatusEnum[post.status]
        })
        setOriginalData(data)
      })
  }

  const exportPostStat = () => {
    let param = getPostStatParam()
    param = {
      ...param, 
      ...{
        jobName: 'Export Post Statistics',
        isDisplay: true,
        filename: `post_stat_${getExportFileNameDate()}`
      }
    }
    apiExportFile('/admin/post/stats/export', param)
  }

  const search = () => {
    let filtered = [...originalData];

    if (categoryFilter && categoryFilter.value !== 'all') {
      filtered = filtered.filter((data) => {
        return data.category_ids && data.category_ids[0] === categoryFilter.value;
      })
    }

    filtered = searchFilter(filtered, headerOptions.searchableFields, listKeyword);

    setRowData(filtered);
  }

  const [isPostStatModalOpen, setIsPostStatModalOpen] = useState(false);
  const [postStat, setPostStat] = useState({})
  const [postStatPost, setPostStatPost] = useState({})
  const [postStatUrl, setPostStatUrl] = useState([])
  const [postStatAllUrl, setPostStatAllUrl] = useState([])
  const viewPostStat = (post) => {
    setPostStatPost(post);
    let param = {
      post_id: post.post_id,
      time_interval: "month",
      start_time: parseFloat(post.display_start),
      end_time: getTimestampSecForApi(new Date())
    }
    apiPost('/admin/post/stats', param)
      .then(({data}) => {
        each(data, (d) => {
          d.date = getChartMonthDateDisplayByTimestampSecond(d.start_time)
          each(PostStatItems, (item) => {
            d[item.label] = d[item.key] || 0
          })
        })

        setPostStat(data);
        getPostUrlStat(post);
      })
  }
  const getPostUrlStat = (post) => {
    apiPost('/admin/post', {post_id: post.post_id})
      .then(({data}) => {

        let param = {
          referrer: data.share_url,
          time_interval: "month",
          start_time: parseFloat(post.display_start),
          end_time: getTimestampSecForApi(new Date())
        }
        apiPost('/admin/hkaa_community/stat/webview_log', param)
          .then(({data}) => {
            let urls = [];


            each(data, (d) => {
              d.date = getSimpleDateDisplayByTimestampSecond(d.start_time)
              if (param.time_interval === 'month') {
                d.date = getChartMonthDateDisplayByTimestampSecond(d.start_time)
              }

              each(d, (value, key) => {
                if (
                  key !== 'start_time' && 
                  key !== 'end_time' && 
                  key !== 'date' &&
                  !find(urls, {label: key})
                ) {
                  urls = union(urls, [{
                    label: key,
                    value: key
                  }]) 
                }
              })

              // d['No. of search'] = d.no_of_search
            })
            
            setPostStatUrl(data)
            setPostStatAllUrl(urls)

            setIsPostStatModalOpen(true);
          })
      })


  }
  const closePostStat = () => {
    setPostStat({});
    setPostStatUrl([]);
    setIsPostStatModalOpen(false);
  }

  useEffect(() => {
    search()
  }, [originalData, listKeyword, categoryFilter])

  useEffect(() => {
    if (range.length !== 2) {
      return;
    }
    getPostStat()
  }, [listFilter, range])

  useEffect(() => {
    getCategoryList()
  }, [])

  return (
    <div className="list-container">
      
      <div className="header">
        <Card>
          <CardHeader>
            <div className="title">
              Post Dashboard
            </div>
            <div className="actions">
              <button className="button info" onClick={exportPostStat}>
                <a>Export</a>
              </button>
            </div>
          </CardHeader>
        </Card>
        <br></br>
        <Card>
          <CardHeader>
            <div className="filter-row full-width">
              <div className="width-50">
                <div>
                  Date
                </div>
                <div>
                  <DateRangeInput 
                    value={range}
                    onChange={setRange}
                    className="text-align-left border"
                  />
                </div>
              </div>
              <div className="width-50">
                <div>
                  Category
                </div>
                <div>
                  <SelectInput 
                    options={allCategoryOptions}
                    value={categoryFilter}
                    onChange={setCategoryFilter}
                  />
                </div>
              </div>
            </div>
          </CardHeader>
        </Card>
      </div>

      <div className="body">
        <List 
          headerOptions={headerOptions}
          gridOptions={gridOptions}
          rowData={rowData}
        />
      </div>


      <Dialog
        open={isPostStatModalOpen}
        onClose={closePostStat}
        fullWidth={true}
        maxWidth="lg"
        scroll="paper"
      >
        <PostStat 
          post={postStatPost} 
          stat={postStat} 
          urlStat={postStatUrl}
          urls={postStatAllUrl}
          handleClose={closePostStat} />
      </Dialog>
    </div>
  )
}

PostDashboard.propTypes = {

}

const mapStateToProps = (state) => ({
  
})

const mapDispatchToProps = {
  
}

export default connect(mapStateToProps, mapDispatchToProps)(PostDashboard)
