import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import { connect } from 'react-redux'
import { apiPost, apiUploadFile } from '../../../../helpers/Api';
import { toast } from '../../../utilities/Notification/CustomToast';
import Card from '../../../utilities/card/Card';
import CardHeader from '../../../utilities/card/CardHeader';
import MultiLangSelector, { langTabs, defaultLang } from '../../../utilities/form/MultiLangSelector';
import CardBody from '../../../utilities/card/CardBody';
import TextAreaInput from '../../../utilities/form/TextAreaInput';
import FileInput from '../../../utilities/form/FileInput';
import BasicInput from '../../../utilities/form/BasicInput';

import map from 'lodash/map';
import union from 'lodash/union';
import each from 'lodash/each';
import filter from 'lodash/filter';
import difference from 'lodash/difference';
import find from 'lodash/find';
import isEmpty from 'lodash/isEmpty';
import trim from 'lodash/trim'
import DateInput from '../../../utilities/form/DateInput';
import HtmlInput from '../../../utilities/form/HtmlInput';
import SelectionList from '../../../utilities/list/SelectionList';
import CheckboxInput from '../../../utilities/form/CheckboxInput';
import CheckboxList from '../../../utilities/form/CheckboxList';
import { attachmentTypes, uploadLocalFileObjects, createLocalFileObject } from '../../../../helpers/FileHelper';
import TargetAudienceSelector from '../../../utilities/form/TargetAudienceSelector';
import MultiLangInputs from '../../../utilities/form/MultiLangInputs';
import LinkageSelector, { typePost, typePromotion } from '../../../utilities/form/LinkageSelector';
import LinkagePost from '../../../utilities/form/LinkagePost';
import { Tabs, Tab } from '@material-ui/core';
import { checkIfTimesInCorrectOrder } from '../../../../helpers/DateHelper';
import LinkedPostList from '../../../utilities/form/LinkedPostList';

function PromotionSet(props) {
  const { match, basePath, history } = props;

  let promotionId = match.params.id;

  const [formTab, setFormTab] = useState(0);

  let i18nDefaultObj = {};
  each(langTabs, (lang) => {
    i18nDefaultObj[lang.key] = {
      name: '', 
      description: '', 
      description_new: '', 
    }
  })

  const defaultPromotionObj = {
    name: '',
    description: '',
    description_new: '',
    _i18n: i18nDefaultObj,
    startTime: [new Date()],
    endTime: [null],
    url: '',
    localMediaObjArr: [],
    uploadedMediaSrcArr: [],
    localImageObjArr: [],
    uploadedImageSrcArr: [],
    linked_posts: []
  }

  const [promotion, setPromotion] = useState(defaultPromotionObj);

  const updatePromotionInfo = (update) => {
    setPromotion({
      ...promotion,
      ...update
    })
  }

  const getPromotionDetail = () => {
    let param = {
      promotion_id: promotionId
    }
    apiPost('/admin/promotion', param)
      .then((resp) => {
        let promotion = resp.data;
        
        let i18n = {};
        each(langTabs, (lang, index) => {
          i18n[lang.key] = {
            name: promotion.name,
            description: promotion.description,
            description_new: promotion.description,
          }
          if (promotion._i18n[lang.key]) {
            i18n[lang.key] = {
              ...i18n[lang.key],
              ...promotion._i18n[lang.key],
              ...{description_new: promotion._i18n[lang.key].description}
            }
          }
        })
        
        promotion._i18n = {...i18nDefaultObj, ...i18n}

        promotion.startTime = [new Date(parseFloat(promotion.start_time) * 1000)]
        promotion.endTime = [new Date(parseFloat(promotion.end_time) * 1000)]

        promotion.localMediaObjArr = map(promotion.media_attachments, (m) => {
          return createLocalFileObject({attachmentId: m.attachment_id, name: m.filename})
        })
        
        promotion.localImageObjArr = map(promotion.images, (i) => {
          return createLocalFileObject({attachmentId: i, name: i})
        })

        updatePromotionInfo({
          ...defaultPromotionObj,
          ...promotion
        })
      })
  }

  const checkInput = () => {
    for (let i = 0; i < langTabs.length; i++) {
      let lang = langTabs[i];
      if (!promotion._i18n[lang.key].name) {
        toast.warn("Please enter promotion name for all languages");
        return false;
      }
      if (!promotion._i18n[lang.key].description) {
        toast.warn("Please enter promotion description for all languages");
        return false;
      }
    }

    if (!promotion.startTime[0]) {
      toast.warn("Please select promotion start time");
      return false;
    }

    if (!promotion.endTime[0]) {
      toast.warn("Please select promotion end time");
      return false;
    }

    if (!checkIfTimesInCorrectOrder(promotion.startTime[0], promotion.endTime[0])) {
      toast.warn("End time must be after start time")
      return false
    }

    return true;
  }

  const uploadPic = (index, callback) => {
    uploadLocalFileObjects({
      arr: promotion.localImageObjArr,
      index: index,
      uploadedIds: [],
      callbackWithIds: (ids) => {
        promotion.uploadedImageSrcArr = ids
        callback()
      }
    })
  }

  const uploadMedia = (index, callback) => {
    uploadLocalFileObjects({
      arr: promotion.localMediaObjArr,
      index: index,
      uploadedIds: [],
      callbackWithIds: (ids) => {
        promotion.uploadedMediaSrcArr = ids
        callback()
      }
    })
  }

  const savePromotion = () => {
    let startTime = promotion.startTime[0].getTime() / 1000;
    let endTime = promotion.endTime[0].getTime() / 1000;

    let defaultLangContent = promotion._i18n[defaultLang.key];

    let i18nObj = {}
    each(langTabs, (lang, index) => {
      i18nObj[lang.key] = {
        name: trim(promotion._i18n[lang.key].name),
        description: trim(promotion._i18n[lang.key].description),
      }
    })

    let allMedia = promotion.uploadedMediaSrcArr;
    let allImages = promotion.uploadedImageSrcArr;

    let thePromotion = {
      name: defaultLangContent.name,
      description: defaultLangContent.description,
      _i18n: i18nObj,
      start_time: startTime,
      end_time: endTime,
      media: allMedia,
      images: allImages,
    }

    let url = '/admin/promotion/create'
    if (promotion.promotion_id) {
      thePromotion.promotion_id = promotion.promotion_id;
      url = '/admin/promotion/update'
    }


    apiPost(url, thePromotion)
      .then((resp) => {
        promotionId = resp.data.promotion_id;
        getPromotionDetail();
      })
  }

  const saveNow = () => {
    if (!checkInput()) {
      return;
    }

    uploadMedia(0, () => uploadPic(0, () => savePromotion()))
  }

  const backNow = () => {
    history.push(`${basePath}/list`)
  }

  const createPostCallback = () => {
    getPromotionDetail();
  }

  useEffect(() => {
    if (promotionId) {
      getPromotionDetail()
    }
  }, [])

  return (
    <div className="item-set-container">
      <div className="full-width padding-bottom-30">
        <Card>
          <CardHeader>
            <div className="title">
              {promotion.promotion_id ? 'Coupon Detail' : 'Add New'}
            </div>
            <div className="actions">
              {
                promotion.promotion_id &&
                <LinkagePost 
                  targetType={typePromotion.value}
                  targetId={promotion.promotion_id}
                  createPostCallback={createPostCallback}
                />
              }
              <button className="button default" onClick={backNow}>
                <span>Back</span>
              </button>
              <button className="button primary" onClick={saveNow}>
                <span>Save</span>
              </button>
            </div>
          </CardHeader>
        </Card>
        <div className="padding-bottom-30"></div>

        <Card>
          <Tabs 
            indicatorColor="primary"
            textColor="primary"
            value={formTab} 
            onChange={(e, newVal) => setFormTab(newVal)}
          >
            <Tab label="Information" />
            {
              promotion.promotion_id &&
              <Tab label="Linkage" />
            }
          </Tabs>
        </Card>
        <div className="padding-bottom-30"></div>

        <div hidden={formTab !== 0}>
          {
            (promotion.author && promotion.author.name) &&
            <>
              <Card>
                <CardBody>
                  <BasicInput type="text" value={promotion.author.name} label="Author" isDisabled noBorder />
                </CardBody>
              </Card>
              <div className="padding-bottom-30"></div>
            </>
          }
          <Card>
            <CardHeader>
              <div className="title sub-title">
                Coupon Name
              </div>
            </CardHeader>
            <CardBody>
              <MultiLangInputs
                i18n={promotion._i18n}
                field="name"
                type="textarea"
                rows="2"
                onChange={(i18n) => updatePromotionInfo({_i18n: i18n})}
                label="Promotion Name"
              />
            </CardBody>
          </Card>
          <div className="padding-bottom-30"></div>
          <Card>
            <CardHeader>
              <div className="title sub-title">
                Description
              </div>
            </CardHeader>
            <CardBody>
              <MultiLangInputs
                i18n={promotion._i18n}
                field="description"
                newField="description_new"
                type="textarea"
                rows="10"
                onChange={(i18n) => updatePromotionInfo({_i18n: i18n})}
                label="Description"
              />
            </CardBody>
          </Card>
          <div className="padding-bottom-30"></div>
          <Card>
            <CardHeader>
              <div className="title sub-title">
                Coupon Poster (Max. 1)
              </div>
            </CardHeader>
            <CardBody>
              <FileInput  
                localFileObjArr={promotion.localImageObjArr}
                onLocalFileObjArrChange={(arr) => updatePromotionInfo({localImageObjArr: arr})}
                maxCount={1}
                acceptTypes={attachmentTypes.imageTypes}
                text="Upload Image"
                isFileNameOnly={false}
                extraTips="Recommended ratio: 3:2; Recommended dimension: 1200x800 px"
              />
            </CardBody>
          </Card>

          <div className="padding-bottom-30"></div>
          <Card>
            <CardHeader>
              <div className="title sub-title">
                Coupon Information
              </div>
            </CardHeader>
            <CardBody>
              <DateInput 
                value={promotion.startTime}
                placeholder="Start Time"
                onChange={(date) => updatePromotionInfo({startTime: date})}
                isShowLabel={true}
              />
              <div className="border-bottom"></div>
              <DateInput 
                value={promotion.endTime}
                placeholder="End Time"
                onChange={(date) => updatePromotionInfo({endTime: date})}
                isShowLabel={true}
              />
              {/* <div className="border-bottom"></div> */}
              {/* <BasicInput type="text" value={promotion.url} label="URL" onChange={(val) => updatePromotionInfo({url: val})} /> */}
            </CardBody>
          </Card>
        </div>

        <div hidden={formTab !== 1}>
          <LinkedPostList 
            posts={promotion.linked_posts || []}
            history={history}
          />
        </div>
      </div>
    </div>
  )
}

PromotionSet.propTypes = {

}

export default PromotionSet

