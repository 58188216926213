import { library } from '@fortawesome/fontawesome-svg-core'
import { 
  faDownload,
  faSearch,
  faArrowDown,
  faUser,
  faTrash,
  faSquare,
  faCheckSquare,
  faTimes,
  faChartLine,
  faEdit,
  faPaperclip,
  faLongArrowAltUp,
  faCalendar,
  faPlus,
  faAngleUp,
  faAngleDown,
  faCircle,
  faAngleLeft,
  faBars,
  faCheck,
  faSearchPlus,
  faAngleRight,
  faCopy,
  faThumbtack,
  faRedo
} from '@fortawesome/free-solid-svg-icons'


library.add(
  faDownload, 
  faSearch,
  faArrowDown,
  faUser,
  faTrash,
  faSquare,
  faCheckSquare,
  faTimes,
  faChartLine,
  faEdit,
  faPaperclip,
  faLongArrowAltUp,
  faCalendar,
  faPlus,
  faAngleUp,
  faAngleDown,
  faCircle,
  faAngleLeft,
  faBars,
  faCheck,
  faSearchPlus,
  faAngleRight,
  faCopy,
  faThumbtack,
  faRedo
)