import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle } from '@fortawesome/free-regular-svg-icons';
import { searchFilter } from '../../../helpers/SearchHelper';
import { getPostTextColorByBgColor } from '../../../helpers/PostHelper';

function RadioList(props) {
  const {
    data,
    selected,
    onChange,
    isDisabled,
    isSearch,
    isColor
  } = props;

  const [allData, setAllData] = useState(data)
  
  const [checkedValue, setCheckedValue] = useState(selected)
  const checkIfSelected = (item) => {
    return item === checkedValue
  }

  const [keyword, setKeyword] = useState('');
  const handleChange = event => {
    let k = event.target.value;
    setKeyword(k);
  };

  const clearKeyword = () => {
    setKeyword('');
  }

  const search = () => {
    let filtered = [...data]
    filtered = searchFilter(filtered, ['name'], keyword)
    setAllData(filtered)
  }
  
  useEffect(() => {
    search()
  }, [keyword, data])

  useEffect(() => {
    onChange(checkedValue)
  }, [checkedValue])

  useEffect(() => {
    setCheckedValue(selected)
  }, [selected])



  return (
    <div>
      {
        isSearch &&
        <div className="search">
          <FontAwesomeIcon fixedWidth icon="search"></FontAwesomeIcon>
          <input className="search-input" type="text" value={keyword} placeholder="Search ..." onChange={handleChange}/>
          {
            keyword.length > 0 &&
            <FontAwesomeIcon className="clickable clear" fixedWidth icon="times" onClick={clearKeyword}></FontAwesomeIcon>
          }
        </div>
      }
      <div className={`radio-list-container ${isColor ? "color-picker" : ""}`}>
        {
          allData.map((item, index) => (
            <div className={`radio-container ${isDisabled ? 'unclickable' : 'clickable hover-opacity'}`} key={index} onClick={() => {
              if (!isDisabled) {
                setCheckedValue(item)
              }
            }}>
              {
                isColor 
                ?
                <>
                  <div className={`color-cell ${checkIfSelected(item) ? "selected" : ""}`} style={{backgroundColor: item, color: getPostTextColorByBgColor(item)}}>
                    {
                      checkIfSelected(item) &&
                      <FontAwesomeIcon icon="check" fixedWidth />
                    }
                    <div>Aa</div>
                  </div>
                </>
                :
                <>
                  <FontAwesomeIcon icon={checkIfSelected(item) ? "circle" : faCircle} fixedWidth />
                  <div className="name">
                    {item.name}
                  </div>
                </>
              }
            </div>
          ))
        }
      </div>
    </div>
  )
}

RadioList.propTypes = {

}

export default RadioList

