import React from 'react'
import PropTypes from 'prop-types'
import CheckboxInput from '../../../../utilities/form/CheckboxInput';

function FreeTextQuestion(props) {
  const {
    question,
    onChange,
  } = props;

  return (
    <div className="free-text-question">
      <div className="input-container">
        <span className="input-label">
          Mandatory
        </span>
        <div className="form-input">
          <CheckboxInput 
            value={question.isMandatory} 
            onChange={(val) => {
              onChange({...question, ...{isMandatory: val}})
            }} 
          />
        </div>
      </div>
    </div>
  )
}

FreeTextQuestion.propTypes = {

}

export default FreeTextQuestion

