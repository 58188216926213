import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import { Link } from 'react-router-dom'
import each from 'lodash/each'
import { apiPost, apiExportFile } from '../../../../helpers/Api'
import { searchFilter } from '../../../../helpers/SearchHelper'
import Card from '../../../utilities/card/Card'
import CardHeader from '../../../utilities/card/CardHeader'
import Tab from '../../../utilities/tab/Tab'
import List from '../../../utilities/list/List'
import ToolsRenderer from '../../../utilities/list/ToolsRenderer'
import DateRenderer from '../../../utilities/list/DateRenderer'
import { postStatusTabs, postStatus } from '../../../../helpers/PostHelper'
import { getExportFileNameDate } from '../../../../helpers/DateHelper'

function SurveyList(props) {
  const { match, basePath, history } = props;

  const [originalData, setOriginalData] = useState([]);
  const [listKeyword, setListKeyword] = useState('');
  const [listFilter, setListFilter] = useState(null);

  const [headerOptions, setHeaderOptions] = useState({
    enable: true,
    isSearch: true,
    searchableFields: ['title', 'author.name'],
    filters: [
      // {value: 'all', label: "All Categories"},
    ],
    onHeaderChange: (keyword, filter) => {
      setListKeyword(keyword);
      setListFilter(filter);
    }
  })


  const columns = [
    { 
      headerName: "Title", 
      field: "title"
    },
    // { 
    //   headerName: "Categories", 
    //   field: "categories"
    // },
    // { 
    //   headerName: "Status", 
    //   field: "status" 
    // },
    { 
      headerName: "From", 
      field: "start_date",
      sort: 'desc',
      cellRenderer: 'dateRenderer'
    },
    { 
      headerName: "To", 
      field: "expiry_date",
      cellRenderer: 'dateRenderer'
    },
    { 
      headerName: "Author", 
      field: "author.name"
    },
    // { 
    //   headerName: "Stats", 
    //   field: "stats",
    //   pinned: "right",
    //   width: 80,
    //   minWidth: 80,
    //   maxWidth: 80,
    //   sortable: false,
    //   cellRenderer: 'toolsRenderer',
    //   cellRendererParams: {
    //     onClickCallback: {
    //       stat: (row) => {
    //         viewPostStat(row)
    //       }
    //     },
    //     types: ["stat"]
    //   }
    // },
    { 
      headerName: "Tools", 
      field: "tools", 
      pinned: "right",
      width: 130,
      minWidth: 130,
      maxWidth: 130,
      sortable: false,
      cellRenderer: 'toolsRenderer',
      cellRendererParams: {
        onClickCallback: {
          edit: (row) => {
            history.push(`${basePath}/edit/${row.questionnaire_id}`);
          },
          remove: (row) => {
            deleteSurvey(row);
          },
          export: (row) => {
            exportSurvey(row);
          }
        },
        types: ["edit", "remove", "export"]
      }
    }
  ];

  const [gridOptions, setGridOptions] = useState({
    columnDefs: columns,
    frameworkComponents: {
      toolsRenderer: ToolsRenderer,
      dateRenderer: DateRenderer
    }
  });

  const [rowData, setRowData] = useState([...originalData]);

  const search = () => {
    let filtered = [ ...originalData ];

    if (listFilter && listFilter.value !== 'all') {
      filtered = filtered.filter((data) => {
        return data.categories === listFilter.label;
      })
    }

    filtered = searchFilter(filtered, headerOptions.searchableFields, listKeyword);

    setRowData(filtered);
  }

  const getSurveyList = () => {
    let param = {

    }
    apiPost('/admin/questionnaire/list', param)
      .then((resp) => {
        let posts = resp.data;

        setOriginalData(posts);
      })
  }

  const deleteSurvey = (item) => {
    let param = {
      questionnaire_id: item.questionnaire_id
    }
    apiPost('/admin/questionnaire/delete', param)
      .then((resp) => {
        history.push(match.url);
      })
      .catch((err) => {
        console.error(err);
      })
  }

  const exportSurvey = (item) => {
    let param = {
      questionnaire_id: item.questionnaire_id, 
      isDisplay: true, 
      filename: `survey_export_${item.title}_${getExportFileNameDate()}`
    }
    apiExportFile('/admin/questionnaire/export', param)
  }

  useEffect(() => {
    getSurveyList()
  }, [])

  useEffect(() => {
    search()
  }, [originalData])

  useEffect(() => {
    search()
  }, [listKeyword, listFilter])


  return (
    <div className="list-container">
      <div className="header">
        <Card>
          <CardHeader>
            <div className="title">Survey</div>
            <div className="actions">
              <button 
                className="button default"
                onClick={() => history.push(`${basePath}/add`)}
              >
                <a>Add New</a>
              </button>
            </div>
            {/* <Tab tabs={postStatusTabs} activeTab={activeTab} switchActiveTab={setActiveTab} /> */}
          </CardHeader>
        </Card>
      </div>
      <div className="body">
        <List 
          headerOptions={headerOptions}
          gridOptions={gridOptions}
          rowData={rowData}
        />
      </div>
    </div>
  )
}

SurveyList.propTypes = {

}

export default SurveyList

