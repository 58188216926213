import React from 'react'
import PropTypes from 'prop-types'
import CheckboxInput from './CheckboxInput';

import remove from 'lodash/remove';

function CheckboxList(props) {
  const {
    data,
    selected,
    onChange
  } = props;

  let allData = data;

  const checkIfSelected = (item) => {
    return selected.indexOf(item) !== -1;
  }

  const parseIsSelected = () => {
    allData = allData.map((item, index) => {
      item.isSelected = selected.indexOf(item) !== -1;
      return item;
    })
  }

  const updateItemIsSelected = (item) => {
    if (checkIfSelected(item)) {
      remove(selected, item);
    } else {
      selected.push(item);
    }
    onChange(selected);
    parseIsSelected();
  }

  parseIsSelected();

  return (
    <div className="checkbox-list-container">
      {
        allData.map((item, index) => (
          <CheckboxInput className="hover-opacity" key={index} value={checkIfSelected(item)} name={item.name} onChange={()=> updateItemIsSelected(item)}></CheckboxInput>
        ))
      }   
    </div>
  )
}

CheckboxList.propTypes = {

}

export default CheckboxList

