import React from 'react'
import PropTypes from 'prop-types'
import { getFullDateDisplayByTimestampSecond } from '../../../helpers/DateHelper';

function DateRenderer(props) {
  const { value } = props;

  let dateDisplay = value;

  let parsed = getFullDateDisplayByTimestampSecond(dateDisplay);
  if (parsed) {
    dateDisplay = parsed;
  }
  
  return (
    <div>
      {dateDisplay}
    </div>
  )
}

DateRenderer.propTypes = {

}

export default DateRenderer

