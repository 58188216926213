import React from 'react'
import PropTypes from 'prop-types'

import union from 'lodash/union'

function Card(props) {
  const { children, className } = props;

  let classes = ["card"];
  if (className) {
    classes = union(classes, className.split(' '))
  }

  return (
    <div className={classes.join(' ')}>
      {children}
    </div>
  )
}

Card.propTypes = {

}

export default Card
