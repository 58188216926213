import React from 'react'
import PropTypes from 'prop-types'
import Tab from '../tab/Tab';

export const langEn = {
  key: "en",
  name: "English"
}

export const langCht = {
  key: "zh-Hant",
  name: "繁體中文"
}

export const langChs = {
  key: "zh-Hans",
  name: "简体中文"
}

export const langTabs = [
  langEn,
  langCht,
  // langChs
]
export const defaultLang = langEn;

function MultiLangSelector(props) {
  const { activeLang, switchLang } = props;

  return (
    <Tab tabs={langTabs} activeTab={activeLang} switchActiveTab={switchLang}>
      
    </Tab>
  )
}

MultiLangSelector.propTypes = {

}

export default MultiLangSelector

