import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import Card from '../../../utilities/card/Card';
import CardHeader from '../../../utilities/card/CardHeader';
import { apiPost } from '../../../../helpers/Api';
import Sortable from 'react-sortablejs/lib/Sortable';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TargetHost, StaticPath } from '../../../../helpers/Constants';
import map from 'lodash/map'
import CardBody from '../../../utilities/card/CardBody';
import { Popover } from '@material-ui/core';

function AccPageList(props) {
  const { 
    match, 
    basePath,
    history
  } = props;

  const [accPages, setAccPages] = useState([])

  const getAccPageList = () => {
    let param = {

    }
    apiPost('/admin/hkaa_community/acc_page', param)
      .then((resp) => {
        setAccPages(resp.data)
      })
  }

  const editAccPage = (page) => {
    history.push(`${basePath}/edit/${page.item_id}`);
  }


  const [confirmOpen, setConfirmOpen] = useState(false)
  const [deletingItem, setDeleteingItem] = useState(null)  
  const [anchorEl, setAnchorEl] = React.useState(null);

  const deleteAccPage = (item, event) => {
    setAnchorEl(event.currentTarget);
    setConfirmOpen(true);
    setDeleteingItem(item)
  }

  const handleClose = () => {
    setAnchorEl(null);
    setConfirmOpen(false)
  };

  const apiDelete = () => {
    let param = {
      item_id: deletingItem.item_id
    }
    apiPost('/admin/hkaa_community/acc_page/delete', param)
      .then((resp) => {
        history.push(match.url);
      })
      .catch((err) => {
        console.error(err);
      })
  }

  const [isAllowOrdering, setIsAllowOrdering] = useState(false)
  const [reorderingAccPages, setReorderingAccPages] = useState(null)
  const updateOrder = () => {
    setIsAllowOrdering(true)
    setReorderingAccPages([...accPages])
  }

  const saveOrder = () => {
    let params = {
      item_ids: map(reorderingAccPages, 'item_id')
    }
    apiPost('/admin/hkaa_community/acc_page/order', params)
      .then((resp) => {
        history.push(match.url);
      })
    cancelOrder();
  }

  const cancelOrder = () => {
    setIsAllowOrdering(false)
    setReorderingAccPages(null)
  }

  const dragReOrder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
  
    return result;
  };

  const onDragEnd = (order, sortable, event) => {
    const arr = dragReOrder(reorderingAccPages, event.oldIndex, event.newIndex);
    setReorderingAccPages([...arr])
  }

  useEffect(() => {
    getAccPageList()
  }, [])

  return (
    <div className="list-container">
      <div className="header">
        <Card>
          <CardHeader>
            <div className="title">ASC Page</div>
            <div className="actions">
              {
                isAllowOrdering 
                ?
                  <>
                    <button className="button default" onClick={cancelOrder}>
                      <a>Cancel Ordering</a>
                    </button>
                    <button className="button primary" onClick={saveOrder}>
                      <a>Save Ordering</a>
                    </button>
                  </>
                :
                <button className="button default" onClick={updateOrder}>
                  <a>Update Order</a>
                </button>
              }
              <button 
                className="button default"
                onClick={() => history.push(`${basePath}/add`)}
              >
                <a>Add New</a>
              </button>
            </div>
          </CardHeader>
        </Card>
      </div>
      <div className="body">
        <Card className="pages-container">
          <CardHeader>
            <div className="page-row header-row">
              {
                isAllowOrdering &&
                <div className="sort-handle"></div>
              }
              <div className="image-col">Image</div>
              <div className="name">Name</div>
              <div className="url">URL</div>
              <div className="action-col"></div>
            </div>
          </CardHeader>
          <CardBody>
            <div className="page-rows">
              <Sortable
                options={{
                  handle: '.sort-handle'
                }}
                onChange={onDragEnd}
              >
                {
                  (reorderingAccPages ? reorderingAccPages : accPages).map((page, index) => (
                    <div className="page-row" key={page.item_id}>
                      {
                        isAllowOrdering &&
                        <div className="sort-handle">
                          <FontAwesomeIcon icon="bars" fixedWidth />
                        </div>
                      }
                      <div className="image-col">
                        <div className="image-wrapper icon">
                          <div className="image" style={{backgroundImage: `url(${TargetHost}${StaticPath}${page.image_id})`}} />
                        </div>
                      </div>
                      <div className="name">
                        {page.name}
                      </div>
                      <div className="url">
                        {page.url}
                      </div>
                      <div className="action-col">
                        <span className="tool-button clickable hover-opacity" onClick={() => editAccPage(page)}>
                          <FontAwesomeIcon icon="edit" fixedWidth />
                        </span>
                        <span className="tool-button clickable hover-opacity" onClick={(event) => deleteAccPage(page, event)}>
                          <FontAwesomeIcon icon="trash" fixedWidth />
                        </span>
                      </div>
                    </div>
                  ))
                }
              </Sortable>
            </div>
          </CardBody>
        </Card>
      </div>
      <Popover 
        id='remove-popover'
        open={confirmOpen}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Card>
          <CardHeader>
            <div className="title sub-title">
              Delete?
            </div>
          </CardHeader>
          <CardBody>
            <div className="actions">
              <button className="button default" onClick={() => setConfirmOpen(false)}>
                <FontAwesomeIcon icon="times" fixedWidth />
              </button>
              <button className="button primary" onClick={apiDelete}>
                <FontAwesomeIcon icon="check" fixedWidth />
              </button>
            </div>
          </CardBody>
        </Card>
      </Popover>
    </div>
  )
}

AccPageList.propTypes = {

}

export default AccPageList

