import React, { useRef, useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import { Line } from 'react-chartjs-2'
import map from 'lodash/map';
import each from 'lodash/each';
import { getChartColorByIndex, ChartBaseOptions } from './ChartUtilities';
import { saveFileByDataUrl } from '../../../helpers/FileHelper';


function ChartLine(props) {
  const {
    data,
    xKey,
    yKey,
    yKeys,
    isShowLegend,
    chartTitle,
    isAllowSave
  } = props;

  let allYKeys = []

  let chartRef = React.createRef()

  const [chartJsData, setChartJsData] = useState({})

  const prepareChartJsData = () => {
    let allLabels = map(data, xKey)

    let datasets = []

    allYKeys = []
    if (yKeys) {
      allYKeys = [
        ...allYKeys,
        ...yKeys
      ]
    }
  
    if (yKey) {
      allYKeys = [
        ...allYKeys,
        ...[yKey]
      ]
    }

    each(allYKeys, (y, index) => {
      datasets.push({
        label: y,
        data: map(data, y),
        fill: false,
        borderColor: getChartColorByIndex(index)
      })
    })

    setChartJsData({
      labels: allLabels,
      datasets: datasets
    })
  }
  useEffect(() => {
    prepareChartJsData()
  }, [data, yKeys, yKey])

  const saveChart = () => {
    saveFileByDataUrl(
      chartRef.current.chartInstance.toBase64Image(),
      'chart.png'
    )
  }

  return (
    <div>
      {
        isAllowSave &&
        <div className="tip text-align-right text-underline">
          <span 
            className="clickable"
            onClick={saveChart}>
            Save Chart
          </span>
        </div>
      }
      <Line 
        ref={chartRef}
        data={chartJsData} 
        options={{
          ...ChartBaseOptions,
          legend: {
            ...ChartBaseOptions.legend,
            display: isShowLegend,
          },
          title: {
            ...ChartBaseOptions.title,
            display: !!chartTitle,
            text: chartTitle
          },
          
        }}
      />
      {
        isShowLegend &&
        <div className="tip text-center">
          Clicking on the data label can toggle its display.
        </div>
      }
    </div>
  )
}

ChartLine.propTypes = {

}

export default ChartLine

