import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import { connect } from 'react-redux'
import { apiPost, apiUploadFile } from '../../../../helpers/Api';
import { toast } from '../../../utilities/Notification/CustomToast';
import Card from '../../../utilities/card/Card';
import CardHeader from '../../../utilities/card/CardHeader';
import { langTabs, defaultLang } from '../../../utilities/form/MultiLangSelector';
import CardBody from '../../../utilities/card/CardBody';
import FileInput from '../../../utilities/form/FileInput';
import BasicInput from '../../../utilities/form/BasicInput';

import map from 'lodash/map';
import union from 'lodash/union';
import each from 'lodash/each';
import filter from 'lodash/filter';
import difference from 'lodash/difference';
import find from 'lodash/find';
import isEmpty from 'lodash/isEmpty';
import trim from 'lodash/trim'
import DateInput from '../../../utilities/form/DateInput';
import { attachmentTypes, uploadLocalFileObjects, createLocalFileObject } from '../../../../helpers/FileHelper';
import TargetAudienceSelector from '../../../utilities/form/TargetAudienceSelector';
import MultiLangInputs from '../../../utilities/form/MultiLangInputs';
import LinkagePost from '../../../utilities/form/LinkagePost';
import { Tabs, Tab } from '@material-ui/core';
import { checkIfTimesInCorrectOrder } from '../../../../helpers/DateHelper';
import { typeEvent } from '../../../utilities/form/LinkageSelector';
import LinkedPostList from '../../../utilities/form/LinkedPostList';

function EventSet(props) {
  const { match, basePath, history } = props;

  let eventId = match.params.id;

  const [formTab, setFormTab] = useState(0);

  let i18nDefaultObj = {};
  each(langTabs, (lang) => {
    i18nDefaultObj[lang.key] = {
      name: '', 
      description: '', 
      description_new: '', 
    }
  })

  const defaultEventObj = {
    name: '',
    description: '',
    description_new: '',
    _i18n: i18nDefaultObj,
    startTime: [new Date()],
    endTime: [null],
    url: '',
    localMediaObjArr: [],
    uploadedMediaSrcArr: [],
    localImageObjArr: [],
    uploadedImageSrcArr: [],
    address: '',
  }

  const [event, setEvent] = useState(defaultEventObj);

  const updateEventInfo = (update) => {
    setEvent({
      ...event,
      ...update
    })
  }

  const getEventDetail = () => {
    let param = {
      event_id: eventId
    }
    apiPost('/admin/events', param)
      .then((resp) => {
        let event = resp.data;
        
        let i18n = {};
        each(langTabs, (lang, index) => {
          i18n[lang.key] = {
            name: event.name,
            description: event.description,
            description_new: event.description,
          }
          if (event._i18n[lang.key]) {
            i18n[lang.key] = {
              ...i18n[lang.key],
              ...event._i18n[lang.key],
              ...{description_new: event._i18n[lang.key].description}
            }
          }
        })
        
        event._i18n = {...i18nDefaultObj, ...i18n}

        event.startTime = [new Date(parseFloat(event.start_time) * 1000)]
        event.endTime = [new Date(parseFloat(event.end_time) * 1000)]

        event.localMediaObjArr = map(event.media_attachments, (m) => {
          return createLocalFileObject({attachmentId: m.attachment_id, name: m.filename})
        })
        
        event.localImageObjArr = map(event.images, (i) => {
          return createLocalFileObject({attachmentId: i, name: i})
        })

        updateEventInfo({
          ...defaultEventObj,
          ...event
        })
      })
  }

  const checkInput = () => {
    for (let i = 0; i < langTabs.length; i++) {
      let lang = langTabs[i];
      if (!event._i18n[lang.key].name) {
        toast.warn("Please enter event name for all languages");
        return false;
      }
      
      if (!event._i18n[lang.key].description_new) {
        toast.warn("Please enter event description for all languages");
        return false;
      }
    }

    if (!event.startTime[0]) {
      toast.warn("Please select event start time");
      return false;
    }

    if (!event.endTime[0]) {
      toast.warn("Please select event end time");
      return false;
    }

    if (!checkIfTimesInCorrectOrder(event.startTime[0], event.endTime[0])) {
      toast.warn("End time must be after start time")
      return false
    }

    return true;
  }

  const uploadPic = (index, callback) => {
    uploadLocalFileObjects({
      arr: event.localImageObjArr,
      index: index,
      uploadedIds: [],
      callbackWithIds: (ids) => {
        event.uploadedImageSrcArr = ids
        callback()
      }
    })
  }

  const uploadMedia = (index, callback) => {
    uploadLocalFileObjects({
      arr: event.localMediaObjArr,
      index: index,
      uploadedIds: [],
      callbackWithIds: (ids) => {
        event.uploadedMediaSrcArr = ids
        callback()
      }
    })
  }

  const saveEvent = () => {
    let startTime = event.startTime[0].getTime() / 1000;
    let endTime = event.endTime[0].getTime() / 1000;

    let defaultLangContent = event._i18n[defaultLang.key];

    let i18nObj = {}
    each(langTabs, (lang, index) => {
      i18nObj[lang.key] = {
        name: trim(event._i18n[lang.key].name),
        description: trim(event._i18n[lang.key].description_new),
      }
    })

    let allMedia = event.uploadedMediaSrcArr;
    let allImages = event.uploadedImageSrcArr;

    let theEvent = {
      name: defaultLangContent.name,
      description: defaultLangContent.description_new,
      _i18n: i18nObj,
      start_time: startTime,
      end_time: endTime,
      media: allMedia,
      images: allImages,
      url: trim(event.url),
      address: trim(event.address)
    }

    let url = '/admin/events/create'
    if (event.event_id) {
      theEvent.event_id = event.event_id;
      url = '/admin/events/update'
    }

    apiPost(url, theEvent)
      .then((resp) => {
        eventId = resp.data.event_id;
        getEventDetail();
      })
  }

  const backNow = () => {
    history.push(`${basePath}/list`)
  }
  
  const saveNow = () => {
    if (!checkInput()) {
      return;
    }
    uploadMedia(0, () => uploadPic(0, () => saveEvent()))
  }

  const createPostCallback = () => {
    getEventDetail()
  }

  useEffect(() => {
    if (eventId) {
      getEventDetail()
    }
  }, [])

  return (
    <div className="item-set-container">
      <div className="padding-bottom-30 full-width">
        <Card>
          <CardHeader>
            <div className="title">
              {event.event_id ? 'Event Detail' : 'Add New'}
            </div>
            <div className="actions">
              {
                event.event_id &&
                <LinkagePost 
                  targetType={typeEvent.value}
                  targetId={event.event_id}
                  createPostCallback={createPostCallback}
                />
              }
              <button className="button default" onClick={backNow}>
                <span>Back</span>
              </button>
              <button className="button primary" onClick={saveNow}>
                <span>Save</span>
              </button>
            </div>
          </CardHeader>
        </Card>
        <div className="padding-bottom-30"></div>


        <Card>
          <Tabs 
            indicatorColor="primary"
            textColor="primary"
            value={formTab} 
            onChange={(e, newVal) => setFormTab(newVal)}
          >
            <Tab label="Information" />
            {
              event.event_id &&
              <Tab label="Linkage" />
            }
          </Tabs>
        </Card>
        <div className="padding-bottom-30"></div>


        <div hidden={formTab !== 0}>
          {
            (event.author && event.author.name) &&
            <>
              <Card>
                <CardBody>
                  <BasicInput type="text" value={event.author.name} label="Author" isDisabled noBorder />
                </CardBody>
              </Card>
              <div className="padding-bottom-30"></div>
            </>
          }
          <Card>
            <CardHeader>
              <div className="title sub-title">
                Event Name
              </div>
            </CardHeader>
            <CardBody>
              <MultiLangInputs
                i18n={event._i18n}
                field="name"
                type="textarea"
                rows="2"
                onChange={(i18n) => updateEventInfo({_i18n: i18n})}
                label="Event Name"
              />
            </CardBody>
          </Card>

          <div className="padding-bottom-30"></div>
          <Card>
            <CardHeader>
              <div className="title sub-title">
                Description
              </div>
            </CardHeader>
            <CardBody>
              <MultiLangInputs
                i18n={event._i18n}
                field="description"
                newField="description_new"
                type="html"
                rows="10"
                onChange={(i18n) => updateEventInfo({_i18n: i18n})}
                label="Description"
              />
            </CardBody>
          </Card>
          <div className="padding-bottom-30"></div>
          {/* <Card>
            <CardHeader>
              <div className="title sub-title">
                Attachments (Max. 5)
              </div>
            </CardHeader>
            <CardBody>
              <FileInput  
                localFileObjArr={event.localMediaObjArr}
                onLocalFileObjArrChange={(arr) => updateEventInfo({localMediaObjArr: arr})}
                maxCount={5}
                acceptTypes={[...attachmentTypes.pdfTypes, ...attachmentTypes.imageTypes]}
                text="Upload"
                isFileNameOnly={true}
              />
            </CardBody>
          </Card>
          <div className="padding-bottom-30"></div> */}
          <Card>
            <CardHeader>
              <div className="title sub-title">
                Event Poster (Max. 1)
              </div>
            </CardHeader>
            <CardBody>
              <FileInput  
                localFileObjArr={event.localImageObjArr}
                onLocalFileObjArrChange={(arr) => updateEventInfo({localImageObjArr: arr})}
                maxCount={1}
                acceptTypes={attachmentTypes.imageTypes}
                text="Upload Image"
                isFileNameOnly={false}
                extraTips="Recommended ratio: 3:2; Recommended dimension: 1200x800 px"
              />
            </CardBody>
          </Card>
          <div className="padding-bottom-30"></div>
          <Card>
            <CardHeader>
              <div className="title sub-title">
                Event Information
              </div>
            </CardHeader>
            <CardBody>
              <DateInput 
                value={event.startTime}
                placeholder="Start Time"
                onChange={(date) => updateEventInfo({startTime: date})}
                isShowLabel={true}

              />
              <div className="border-bottom"></div>
              <DateInput 
                value={event.endTime}
                placeholder="End Time"
                onChange={(date) => updateEventInfo({endTime: date})}
                isShowLabel={true}
              />
              {/* <div className="border-bottom"></div>

              <BasicInput type="text" value={event.url} label="URL" onChange={(val) => updateEventInfo({url: val})} /> */}

              {/* <BasicInput type="text" value={event.address} label="Address" onChange={(val) => updateEventInfo({address: val})} /> */}
            </CardBody>
          </Card>
        </div>

        <div hidden={formTab !== 1}>
          <LinkedPostList 
            posts={event.linked_posts || []}
            history={history}
          />
        </div>

      </div>
    </div>
  )
}

EventSet.propTypes = {

}

const mapStateToProps = (state) => ({
  
})

const mapDispatchToProps = {
  
}

export default connect(mapStateToProps, mapDispatchToProps)(EventSet)
