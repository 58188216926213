
import ReactModal from 'react-modal'

let overlayStyle = {
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: 'rgba(255, 255, 255, 0.75)'
};

let contentStyle = {
  position: 'absolute',
  top: '15vh',
  left: '10vw',
  right: '10vw',
  bottom: '15vh',
  background: '#fff',
  overflow: 'auto',
  WebkitOverflowScrolling: 'touch',
  outline: 'none',
  boxShadow: '0px 3px 6px rgba(0,0,0, 0.16)'
}

ReactModal.defaultStyles.overlay = overlayStyle;
ReactModal.defaultStyles.content = contentStyle;